import i18next from "i18next";

export default {
  formId: "mainForm",
  formField: {
    name: {
      name: "name",
      label: i18next.t("full name"),
      requiredErrorMsg: i18next.t("full name is required"),
    },
    email: {
      name: "email",
      label: i18next.t("email"),
      requiredErrorMsg: i18next.t("email is required"),
    },
    profile_photo: {
      name: "profile_photo",
      label: i18next.t("profile photo"),
    },
    mobile_phone: {
      name: "mobile_phone",
      label: i18next.t("phone number"),
      invalidErrorMsg: i18next.t("phone number is not valid"),
    },
    country: {
      name: "country",
      label: i18next.t("country"),
      requiredErrorMsg: i18next.t("country is required"),
    },
    zip: {
      name: "zip",
      label: i18next.t("zip code"),
      requiredErrorMsg: i18next.t("postcode is required"),
    },
    job_function: {
      name: "job_function",
      label: i18next.t("job function"),
    },
    job_level: {
      name: "job_level",
      label: i18next.t("job level"),
    },
    discipline: {
      name: "discipline",
      label: i18next.t("discipline"),
    },
    industry: {
      name: "industry",
      label: i18next.t("industry"),
    },
    company_name: {
      name: "company_name",
      label: i18next.t("company name"),
    },
    street: {
      name: "street",
      requiredErrorMsg: i18next.t("address is required"),
    },
    city: {
      name: "city",
      requiredErrorMsg: i18next.t("city is required"),
    },
  },
};
