import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { useTranslation } from "react-i18next";
import { autoEffect, view } from "@risingstack/react-easy-state";

import { company } from "stores/company";

import Loader from "components/shared/parts/Loader";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import saSearchIcon from "assets/img/saSearchIcon.svg";
import bestCostCountryIcon from "assets/img/bestCostCountry.png";

import { API_ENDPOINT } from "../../../consts/globals";

import {
  StyledBestCostCountryWrapper,
  StyledButtonDownload,
  StyledCentralWrapperBottom,
  StyledCentralWrapperTop,
  StyledDescWrapper,
  StyledDetailsWrapper,
  StyledImageNotFound,
  StyledIntro,
  StyledIntroWrapper,
  StyledLogoImageWrapper,
  StyledLogoLinkWrapper,
  StyledLogoWrapper,
  StyledNameWrapper,
  StyledNoRatingWrapper,
  StyledPhotosWrapper,
  StyledProductsWrapper,
  StyledRating,
  StyledRatingWrapper,
  StyledResultWrapper,
  StyledSpacer,
} from "./styled";

import { authFetch } from "functions";

const srlOptions = {
  caption: {
    showCaption: false,
  },
  buttons: {
    showAutoplayButton: false,
    showCloseButton: true,
    showDownloadButton: false,
    showFullscreenButton: false,
    showNextButton: true,
    showPrevButton: true,
    showThumbnailsButton: false,
  },
};

const srlCallbacks = {
  onLightboxOpened: () => {
    document.getElementById("helpBorder").style.opacity = 0;
  },
  onLightboxClosed: () => {
    document.getElementById("helpBorder").style.opacity = 1;
  },
};

const abortController = new AbortController();
const signal = abortController.signal;

const CompanyProfile = view(() => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const matchesLg = useMediaQuery(theme.breakpoints.up("lg"));
  // const [quickscanOrderStatus, setQuickscanOrderStatus] = useState(false);
  // const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [s3Urls, setS3Urls] = useState(null);
  const [s3Logo, setS3Logo] = useState(null);
  const [s3Presentation, setS3Presentation] = useState(null);
  const [s3Reference, setS3Reference] = useState(null);

  const getS3Data = async (type, hook) => {
    const response = await authFetch(
      `${API_ENDPOINT}/system/company-bucket?companyId=${company.company.id}&type=${type}`,
      {
        signal: signal,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const responseData = await response.json();
    hook(
      responseData.files.map(
        (file) => `${process.env.REACT_APP_S3_ENDPOINT}/${file}`
      )
    );
  };

  autoEffect(async () => {
    if (!company.company) {
      await company.getCompany();
    }

    if (company.company) {
      await getS3Data("image", setS3Urls);
      await getS3Data("logo", setS3Logo);
      await getS3Data("company-presentation", setS3Presentation);
      await getS3Data("reference-list", setS3Reference);
    }
  });

  const openFilePDF = (path) => {
    const url = path;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  // const handleOrderQuickscan = async (id) => {
  //   setLoading(true);
  //   if (!quickscanOrderStatus) {
  //     await quickscan.orderQuickscan(id);
  //     if (!quickscan.error) {
  //       setQuickscanOrderStatus(true);
  //     }
  //     setLoading(false);
  //   }
  // };

  return (
    <Fragment>
      {!company.company ||
      !s3Urls ||
      !s3Logo ||
      !s3Presentation ||
      !s3Reference ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <StyledIntro>
          <StyledIntroWrapper fixed>
            <Grid
              container
              justify={matches ? "space-between" : "flex-start"}
              alignItems={"flex-start"}
            >
              <Grid item xs={12}>
                <StyledResultWrapper>
                  <Grid container justify="flex-start" alignItems="flex-start">
                    <Grid item xs={12} lg={3}>
                      <StyledLogoWrapper>
                        <StyledLogoImageWrapper>
                          {s3Logo.length < 1 ? (
                            <StyledImageNotFound />
                          ) : (
                            <img src={s3Logo} alt="logo" />
                          )}
                        </StyledLogoImageWrapper>
                        <StyledRatingWrapper>
                          <h6>{t("overall rating")}</h6>
                          {!company.company.assessment ||
                          !company.company.assessment.overall_score ||
                          company.company.assessment.overall_score.original ? (
                            <StyledNoRatingWrapper>
                              <p>{t("not passed self assessment yet")}</p>
                            </StyledNoRatingWrapper>
                          ) : (
                            <Fragment>
                              <h2>
                                {company.company.assessment.overall_score}
                              </h2>
                              <StyledRating
                                name="read-only"
                                value={parseFloat(
                                  company.company.assessment.overall_score
                                )}
                                precision={0.1}
                                readOnly
                              />
                            </Fragment>
                          )}
                        </StyledRatingWrapper>
                        {company.company.country &&
                        company.company.country.best_buy ? (
                          <StyledBestCostCountryWrapper>
                            <img
                              src={bestCostCountryIcon}
                              alt="best cost country"
                            />
                          </StyledBestCostCountryWrapper>
                        ) : (
                          <StyledBestCostCountryWrapper></StyledBestCostCountryWrapper>
                        )}
                        {!company.company.assessment ||
                        !company.company.assessment.overall_score ||
                        company.company.assessment.overall_score.original ? (
                          <Fragment>
                            {/* {!quickscanOrderStatus ? (
                              <StyledLogoLinkWrapper>
                                <img src={basicSearchIcon} alt="favorite" />
                                <span
                                  onClick={() =>
                                    handleOrderQuickscan(company.company.id)
                                  }
                                >
                                  {t("order quickscan")}
                                </span>
                              </StyledLogoLinkWrapper>
                            ) : (
                              <StyledLogoLinkWrapper ordered>
                                <img src={basicSearchIcon} alt="favorite" />
                                <span ordered>{t("quickscan ordered")}</span>
                              </StyledLogoLinkWrapper>
                            )} */}
                          </Fragment>
                        ) : (
                          <StyledLogoLinkWrapper>
                            <img
                              src={saSearchIcon}
                              alt="self assessment search icon"
                            />
                            <Link to={`/reports/${company.company.id}`}>
                              {t("self assessment")}
                            </Link>
                          </StyledLogoLinkWrapper>
                        )}
                        {s3Reference.length > 0 && (
                          <StyledButtonDownload
                            onClick={() => openFilePDF(s3Reference)}
                          >
                            {t("reference list") + " pdf"}
                          </StyledButtonDownload>
                        )}
                        {s3Presentation.length > 0 && (
                          <StyledButtonDownload
                            onClick={() => openFilePDF(s3Presentation)}
                          >
                            {t("company presentation") + " pdf"}
                          </StyledButtonDownload>
                        )}
                      </StyledLogoWrapper>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      style={{
                        borderLeft: matchesLg ? "1px solid #B0B0B0" : "none",
                        borderRight: matchesLg ? "1px solid #B0B0B0" : "none",
                      }}
                    >
                      <StyledCentralWrapperTop>
                        <StyledNameWrapper>
                          <h2>{company.company.name}</h2>
                          {company.company.website ? (
                            <a
                              href={company.company.website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {company.company.website}
                            </a>
                          ) : (
                            <span>{t("no website provided")}</span>
                          )}
                        </StyledNameWrapper>
                        <StyledDescWrapper>
                          <p>
                            {!company.company.short_description
                              ? t("no description")
                              : company.company.short_description.length > 400
                              ? `${company.company.short_description.substring(
                                  0,
                                  400
                                )}...`
                              : company.company.short_description}
                          </p>
                        </StyledDescWrapper>
                        <StyledDetailsWrapper>
                          <p>
                            {t("address")}{" "}
                            <span>
                              {company.company.address
                                ? company.company.address
                                : t("no data")}
                            </span>
                          </p>
                          <p>
                            {t("country")}{" "}
                            <span>
                              {company.company.country &&
                              company.company.country.name
                                ? company.company.country.name
                                : t("no data")}
                            </span>
                          </p>
                          <p>
                            {t("city")}{" "}
                            <span>
                              {company.company.city && company.company.city.name
                                ? company.company.city.name
                                : t("no data")}
                            </span>
                          </p>
                        </StyledDetailsWrapper>
                        <StyledDetailsWrapper>
                          <p>
                            {t("employees")}{" "}
                            <span>
                              {company.company.number_of_employees || 0}
                            </span>
                          </p>
                          <p>
                            {t("turnover")}{" "}
                            <span>$ {company.company.turnover || 0}</span>
                          </p>
                          <p>
                            {t("iso")} 9001:2015{" "}
                            {company.company.certificates &&
                            company.company.certificates.length > 0 &&
                            company.company.certificates.find((certificate) => {
                              return (
                                certificate.name === "iso" &&
                                certificate.number === "9001:2015"
                              );
                            }) ? (
                              <span>{t("yes")}</span>
                            ) : (
                              <span>{t("no")}</span>
                            )}
                          </p>
                        </StyledDetailsWrapper>
                      </StyledCentralWrapperTop>
                      <StyledCentralWrapperBottom>
                        <h3>{t("products")}</h3>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            <StyledProductsWrapper>
                              <ul>
                                {!company.company.categories ||
                                company.company.categories.length < 1 ? (
                                  <li>
                                    <span>{t("no products")}</span>
                                  </li>
                                ) : (
                                  company.company.categories.map(
                                    (category, index) => {
                                      if (
                                        index <
                                        company.company.categories.length / 2
                                      ) {
                                        return (
                                          <li key={category.id}>
                                            <span>
                                              {category.commodity_name}
                                            </span>
                                          </li>
                                        );
                                      }
                                      return null;
                                    }
                                  )
                                )}
                              </ul>
                            </StyledProductsWrapper>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <StyledProductsWrapper>
                              <ul>
                                {!company.company.categories ||
                                company.company.categories.length < 1
                                  ? null
                                  : company.company.categories.map(
                                      (category, index) => {
                                        if (
                                          index >=
                                          company.company.categories.length / 2
                                        ) {
                                          return (
                                            <li key={category.id}>
                                              <span>
                                                {category.commodity_name}
                                              </span>
                                            </li>
                                          );
                                        }
                                        return null;
                                      }
                                    )}
                              </ul>
                            </StyledProductsWrapper>
                          </Grid>
                        </Grid>
                      </StyledCentralWrapperBottom>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      <StyledPhotosWrapper>
                        <h3>{t("company photos")}</h3>
                        {s3Urls && s3Urls.length > 3 ? (
                          <p>{t("open gallery to see all photos")}</p>
                        ) : (
                          <p></p>
                        )}
                        <SimpleReactLightbox>
                          <SRLWrapper
                            options={srlOptions}
                            callbacks={srlCallbacks}
                          >
                            {!s3Urls || s3Urls.length < 1
                              ? null
                              : s3Urls.map((image, index) => {
                                  if (index < 3) {
                                    return (
                                      <Box
                                        key={image}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <img src={image} alt="company" />
                                      </Box>
                                    );
                                  } else {
                                    return (
                                      <Box
                                        key={image}
                                        style={{ display: "none" }}
                                      >
                                        <img src={image} alt="company" />
                                      </Box>
                                    );
                                  }
                                })}
                          </SRLWrapper>
                        </SimpleReactLightbox>
                        {s3Urls && s3Urls.length > 3 ? (
                          <p>
                            + <span>{s3Urls.length - 3}</span>{" "}
                            {s3Urls.length - 3 === 1
                              ? t("more photo")
                              : t("more photos")}
                          </p>
                        ) : null}
                      </StyledPhotosWrapper>
                    </Grid>
                  </Grid>
                </StyledResultWrapper>
              </Grid>
            </Grid>
          </StyledIntroWrapper>
        </StyledIntro>
      )}
    </Fragment>
  );
});

export default CompanyProfile;
