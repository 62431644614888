import { store } from "@risingstack/react-easy-state";

import { auth } from "./auth";

import { API_ENDPOINT } from "../consts/globals";

import { authFetch } from "functions";

const abortController = new AbortController();
const signal = abortController.signal;

export const invite = store({
  loading: false,
  errors: null,
  sent: false,
  async sendInvite(formValues) {
    invite.sent = false;
    invite.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    const values = {
      email: formValues.email,
    };

    try {
      const response = await authFetch(`${API_ENDPOINT}/system/invite-user`, {
        signal: signal,
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        invite.error = true;
        invite.loading = false;
        invite.sent = false;
        throw Error(response.statusText);
      }

      await response.json();

      invite.error = false;
      invite.loading = false;
      invite.sent = true;
    } catch (error) {
      invite.error = true;
      invite.loading = false;
      invite.sent = false;
      console.log(error);
    }
  },
});
