import React from "react";

import "./styles.css";

const LoadingMessageResponse = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#D3F9EF",
          padding: "0px 22px",
          borderRadius: "10px",
          width: "100%",
        }}
      >
        <div className="container">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingMessageResponse;
