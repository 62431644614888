import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Switch, Route, withRouter } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { autoEffect, view } from "@risingstack/react-easy-state";

import { auth } from "stores/auth";
import { gdpr } from "stores/gdpr";
import { selfAssessment } from "stores/selfAssessment";
import { company } from "stores/company";
import { quickscan } from "stores/quickscan";
import { language } from "stores/language";

import ProtectedRoute from "components/shared/parts/ProtectedRoute";
import Loader from "components/shared/parts/Loader";

import Login from "components/shared/Auth";
import AcceptTerms from "components/shared/GDPR/AcceptTerms";
import TermsAndConditions from "components/shared/GDPR/TermsAndConditions";
import Privacy from "components/shared/GDPR/Privacy";
import Impressum from "components/shared/GDPR/Impressum";
import UserProfile from "components/shared/UserProfile";
import Help from "components/shared/Help";
import FAQ from "components/shared/FAQ";
import SpecificReport from "components/shared/SpecificReport";

import SelfAssessment from "./SelfAssessment";
import CompanyDetails from "./CompanyDetails";
import Lean from "./Lean";
import Quickscan from "./Quickscan";
import SupplierProducts from "./SupplierProducts";
import Report from "./Report";
import RFI from "./RFI";
import Dashboard from "./Dashboard";
import CompanyProfile from "./CompanyProfile";
import AdminQuickscanReport from "./AdminQuickscanReport";

import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import logo from "../../assets/img/logo.svg";
import logoBlack from "../../assets/img/logoBlack.svg";
import profileIcon from "../../assets/img/profileIcon.svg";
import logoutIcon from "../../assets/img/logoutIcon.svg";
import infoIcon from "../../assets/img/infoIcon.svg";
import infoIconWhite from "../../assets/img/infoIconWhite.svg";
import chatIcon from "../../assets/img/chatIcon.svg";
import chatIconWhite from "../../assets/img/chatIconWhite.svg";
import closeIcon from "../../assets/img/closeIcon.svg";
import openDrawerIconWhite from "../../assets/img/openDrawerIconWhite.svg";
import openDrawerIcon from "assets/img/openDrawerIcon.svg";

import {
  StyledAppbar,
  StyledBottomButton,
  StyledButtonPrimary,
  StyledCloseButton,
  StyledDrawer,
  StyledExpandButton,
  StyledHelpBorder,
  StyledHelpButtons,
  StyledHelpDrawer,
  StyledLink,
  StyledList,
  StyledMainWrapper,
  StyledNestedList,
  StyledRatingInfoWrapper,
  StyledRatingWrapper,
  StyledSidebarWrapper,
  StyledSpacer,
  StyledToolbar,
  StyledToolbarButton,
  StyledToolbarWrapper,
  StyledTopBar,
  StyledTopBarFooter,
  StyledTopButton,
  useStyles,
  useStylesRating,
} from "./AppbarSupplier.styled";

function ListItemLink(props) {
  const { primary, to, id, pathname } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <Link
          to={to}
          ref={ref}
          {...itemProps}
          type={to === pathname ? "active" : null}
        />
      )),
    [to, pathname]
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        <ListItemText primary={primary} id={id} />
      </ListItem>
    </li>
  );
}

const logout = async () => {
  await auth.logout();
  company.company = null;
  // history.push("/login");
};

const AppbarSupplier = view((props) => {
  const classes = useStyles();
  const classesRating = useStylesRating();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = useState(true);
  const [openSideBar, setOpenSidebar] = useState(false);
  const [expandSidebar, setExpandSidebar] = useState(false);
  const [openTopBar, setOpenTopBar] = useState(false);
  const { pathname } = props.location;
  const [sidebarContent, setSidebarContent] = useState(null);
  const [topBarContent, setTopBarContent] = useState(false);
  const [openMultilevel, setOpenMultilevel] = useState(false);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickLanguage = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseLanguage = () => {
    setAnchorEl(null);
  };

  const handlePickLanguage = (lang) => {
    language.setLanguage(lang);
    setAnchorEl(null);
  };

  autoEffect(() => {
    if (!selfAssessment.selfAssessment) {
      selfAssessment.getSelfAssessment();
    }
  }, [selfAssessment.selfAssessment]);

  useEffect(() => {
    if (pathname === "/self-assessment" || pathname === "/") {
      setOpenTopBar(true);
    } else {
      setOpenTopBar(false);
    }

    if (pathname === "/quickscan" || pathname === "/lean") {
      setSidebarContent("info");
      setOpenSidebar(true);
    } else {
      setOpenSidebar(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (matches) {
      handleDrawerOpen();
    } else {
      handleDrawerClose();
    }
  }, [matches]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleTopBar = () => {
    setOpenTopBar(!openTopBar);
  };

  // const handleDrawerOpenHelp = () => {
  //   setOpenSidebar(true);
  // };

  const handleDrawerCloseHelp = () => {
    setOpenSidebar(false);
    setSidebarContent(null);
  };

  const handleMultilevel = () => {
    setOpenMultilevel(!openMultilevel);
  };

  const handleSidebarContent = (content) => {
    setSidebarContent(content);
    setOpenSidebar(true);
  };

  const handleDrawerExpandHelp = () => {
    setExpandSidebar((prev) => !prev);
  };

  const renderSidebarContent = (path) => {
    switch (path) {
      case "/self-assessment":
        return (
          <StyledSidebarWrapper>
            <div>
              <h3>{t("tutorial")}</h3>
              <p>{t("tutorial-info")}</p>
              <StyledButtonPrimary>
                <PlayCircleFilledIcon />
                {t("video")}
              </StyledButtonPrimary>
            </div>
          </StyledSidebarWrapper>
        );
      case "/lean":
        return (
          <StyledSidebarWrapper>
            <div>
              <Trans i18nKey="lean-guidance">
                <p></p>
                <p></p>
              </Trans>
            </div>
          </StyledSidebarWrapper>
        );
      case "/quickscan":
        switch (quickscan.step) {
          case 1:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-1">
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 2:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-2">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 3:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-3">
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 4:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-4">
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 5:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-5">
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 6:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-6">
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 7:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-7">
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 8:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-8">
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 9:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-9">
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 10:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-10">
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 11:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-11">
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 12:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-12">
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 13:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-13">
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );

          case 14:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-14">
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 15:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-15">
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 16:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-16">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 17:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-17">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 18:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-18">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 19:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-19">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 20:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-20">
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 21:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-21">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 22:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-22">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 23:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-23">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 24:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-24">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 25:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-25">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          default:
            return (
              <StyledSidebarWrapper>
                <div>
                  {/* <p>{t("no data")}</p> */}
                  <img src={logoBlack} alt="yvoo" />
                </div>
              </StyledSidebarWrapper>
            );
        }
      default:
        return (
          <StyledSidebarWrapper>
            {/* {t("no data")} */}
            <img src={logoBlack} alt="yvoo" />
          </StyledSidebarWrapper>
        );
    }
  };

  const renderTopBarContent = () => {
    // const result = await timer();

    return topBarContent ? (
      <Container style={{ height: "100%" }}>
        <Grid container style={{ height: "100%" }} alignItems="center">
          <Grid item xs={12} lg={5}>
            <StyledRatingWrapper>
              <div className={classesRating.root}>
                <CircularProgress
                  variant="determinate"
                  className={classesRating.bottom}
                  size={109}
                  thickness={1}
                  {...props}
                  value={100}
                />
                <CircularProgress
                  variant="static"
                  className={clsx(classesRating.top, {
                    [classesRating.red]:
                      selfAssessment.selfAssessment.overall_completenes < 20,
                    [classesRating.orange]:
                      selfAssessment.selfAssessment.overall_completenes >= 20 &&
                      selfAssessment.selfAssessment.overall_completenes < 40,
                    [classesRating.yellow]:
                      selfAssessment.selfAssessment.overall_completenes >= 40 &&
                      selfAssessment.selfAssessment.overall_completenes < 60,
                    [classesRating.blue]:
                      selfAssessment.selfAssessment.overall_completenes >= 60 &&
                      selfAssessment.selfAssessment.overall_completenes < 80,
                    [classesRating.green]:
                      selfAssessment.selfAssessment.overall_completenes >= 80,
                  })}
                  classes={{
                    circle: classesRating.circle,
                  }}
                  size={117}
                  thickness={4}
                  value={
                    selfAssessment.selfAssessment &&
                    selfAssessment.selfAssessment.overall_completenes
                      ? selfAssessment.selfAssessment.overall_completenes
                      : 0
                  }
                  {...props}
                />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <h3>
                    {selfAssessment.selfAssessment &&
                    selfAssessment.selfAssessment.overall_completenes
                      ? selfAssessment.selfAssessment.overall_completenes
                      : 0}
                    <span> %</span>
                  </h3>
                </Box>
              </div>
              <StyledRatingInfoWrapper>
                <h4>
                  {t("your chance to be found by leads is currently")}{" "}
                  {selfAssessment.selfAssessment &&
                  selfAssessment.selfAssessment.overall_completenes
                    ? selfAssessment.selfAssessment.overall_completenes
                    : 0}
                  %
                </h4>
                {/* <p>{t("try to reach the green level")}</p> */}
              </StyledRatingInfoWrapper>
            </StyledRatingWrapper>
          </Grid>
          <Grid item xs={12} lg={5}>
            <StyledRatingWrapper>
              <div className={classesRating.root}>
                <CircularProgress
                  variant="determinate"
                  className={classesRating.bottom}
                  size={109}
                  thickness={1}
                  {...props}
                  value={100}
                />
                <CircularProgress
                  variant="static"
                  className={clsx(classesRating.top, {
                    [classesRating.red]:
                      selfAssessment.selfAssessment.overall_company_level < 20,
                    [classesRating.orange]:
                      selfAssessment.selfAssessment.overall_company_level >=
                        20 &&
                      selfAssessment.selfAssessment.overall_company_level < 40,
                    [classesRating.yellow]:
                      selfAssessment.selfAssessment.overall_company_level >=
                        40 &&
                      selfAssessment.selfAssessment.overall_company_level < 60,
                    [classesRating.blue]:
                      selfAssessment.selfAssessment.overall_company_level >=
                        60 &&
                      selfAssessment.selfAssessment.overall_company_level < 80,
                    [classesRating.green]:
                      selfAssessment.selfAssessment.overall_company_level >= 80,
                  })}
                  classes={{
                    circle: classesRating.circle,
                  }}
                  size={117}
                  thickness={4}
                  value={
                    selfAssessment.selfAssessment &&
                    selfAssessment.selfAssessment.overall_company_level
                      ? selfAssessment.selfAssessment.overall_company_level
                      : 0
                  }
                  {...props}
                />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <h3>
                    {selfAssessment.selfAssessment &&
                    selfAssessment.selfAssessment.overall_company_level
                      ? selfAssessment.selfAssessment.overall_company_level
                      : 0}
                  </h3>
                </Box>
              </div>
              <StyledRatingInfoWrapper>
                <h4>
                  {t(
                    "your chances to be qualified by international industrial leads"
                  )}
                </h4>
                {/* <p>{t("what would be the chances to be qualified")}</p> */}
              </StyledRatingInfoWrapper>
            </StyledRatingWrapper>
          </Grid>
        </Grid>
      </Container>
    ) : null;
  };

  useEffect(() => {
    if (openTopBar) {
      const timer = setTimeout(() => {
        setTopBarContent(true);
      }, 225);

      return () => clearTimeout(timer);
    } else {
      setTopBarContent(false);
    }
  }, [openTopBar]);

  return (
    <Fragment>
      {!auth.user ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          <div style={{ display: "flex", minHeight: "100vh" }}>
            <StyledAppbar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <StyledToolbar
                className={clsx({ [classes.toolbarClosed]: !open })}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, open && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
                <img src={logo} alt="yvoo logo" />
                <div className={classes.grow} />
                <StyledToolbarWrapper>
                  <StyledLink to="/profile">
                    <StyledToolbarButton>
                      <img src={profileIcon} alt="profile icon" />
                      <span>{auth.user.dbData.name}</span>
                    </StyledToolbarButton>
                  </StyledLink>
                  <StyledToolbarButton
                    aria-controls="language-menu"
                    aria-haspopup="true"
                    onClick={handleClickLanguage}
                  >
                    <span>{language.language.toUpperCase()}</span>
                  </StyledToolbarButton>
                  <Menu
                    id="language-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseLanguage}
                  >
                    {language.languages.map((lang) => {
                      if (lang !== language.language) {
                        return (
                          <MenuItem onClick={() => handlePickLanguage(lang)}>
                            {lang.toUpperCase()}
                          </MenuItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Menu>
                  <StyledToolbarButton onClick={logout}>
                    <img src={logoutIcon} alt="logout icon" />
                    <span>{t("logout")}</span>
                  </StyledToolbarButton>
                </StyledToolbarWrapper>
              </StyledToolbar>
            </StyledAppbar>

            <StyledDrawer
              className={classes.drawer}
              variant="persistent"
              anchor="left"
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                  <MenuIcon />
                </IconButton>
              </div>
              <StyledList>
                <ListItemLink
                  to="/"
                  primary={t("dashboard")}
                  pathname={pathname}
                />
                <span className={classes.dropdownMenu}>
                  <ListItemLink
                    to="/self-assessment"
                    primary={t("yvoo 4 steps method your forecast of success")}
                    pathname={pathname}
                  />
                  {openMultilevel ? (
                    <IconExpandLess
                      onClick={handleMultilevel}
                      className={classes.dropdownIcon}
                    />
                  ) : (
                    <IconExpandMore
                      onClick={handleMultilevel}
                      className={classes.dropdownIcon}
                    />
                  )}
                </span>
                <Collapse in={openMultilevel}>
                  <StyledNestedList>
                    <ListItemLink
                      to="/company-details"
                      primary={t("company information")}
                      pathname={pathname}
                    />
                    <ListItemLink
                      to="/lean"
                      primary={t("lean manufacturing score")}
                      pathname={pathname}
                    />
                    <ListItemLink
                      to="/quickscan"
                      primary={t("self-assessment")}
                      pathname={pathname}
                    />
                    <ListItemLink
                      to="/supplier-products"
                      primary={t("products")}
                      pathname={pathname}
                    />
                  </StyledNestedList>
                </Collapse>
                {/* <ListItemLink
                  to="/self-assessment"
                  primary="Self assessment"
                  pathname={pathname}
                />
                <ListItemLink
                  to="/company-details"
                  primary="Company details"
                  pathname={pathname}
                />
                <ListItemLink to="/lean" primary="Lean" pathname={pathname} />

                <ListItemLink
                  to="/quickscan"
                  primary="Self quickscan"
                  pathname={pathname}
                />
                <ListItemLink
                  to="/supplier-products"
                  primary="Supplier products"
                  pathname={pathname}
                /> */}
                <ListItemLink
                  to="/report"
                  primary={t("yvoo 4 steps method report")}
                  pathname={pathname}
                />
                <ListItemLink
                  to="/faq"
                  primary={t("faq")}
                  pathname={pathname}
                />
                <ListItemLink
                  to="/help"
                  primary={t("help")}
                  pathname={pathname}
                  id="borderless"
                />
              </StyledList>
            </StyledDrawer>
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: open,
              })}
            >
              {selfAssessment.selfAssessment || !selfAssessment.loading ? (
                <Fragment>
                  <StyledTopBar openTopBar={openTopBar} matches={matches}>
                    {renderTopBarContent()}
                  </StyledTopBar>
                  <StyledTopBarFooter
                    openTopBar={openTopBar}
                    onClick={toggleTopBar}
                  >
                    <img src={openDrawerIconWhite} alt="open drawer icon" />
                  </StyledTopBarFooter>
                </Fragment>
              ) : null}
              <StyledMainWrapper pathname={pathname}>
                <Switch>
                  {/* <Route path="/" exact component={Home} props={auth} /> */}
                  <ProtectedRoute path="/" exact auth={auth} gdpr={gdpr}>
                    <Dashboard />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/accept-terms"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <AcceptTerms />
                  </ProtectedRoute>
                  <ProtectedRoute path="/profile" exact auth={auth} gdpr={gdpr}>
                    <UserProfile />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/self-assessment"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <SelfAssessment />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/company-details"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <CompanyDetails />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/company-profile"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <CompanyProfile />
                  </ProtectedRoute>
                  <ProtectedRoute path="/lean" exact auth={auth} gdpr={gdpr}>
                    <Lean />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/quickscan"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <Quickscan />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/supplier-products"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <SupplierProducts />
                  </ProtectedRoute>
                  <ProtectedRoute path="/report" exact auth={auth} gdpr={gdpr}>
                    <Report />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/reports/:id"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <SpecificReport />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/admin-quickscan-report/:id"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <AdminQuickscanReport />
                  </ProtectedRoute>
                  <ProtectedRoute path="/rfq/:id" exact auth={auth} gdpr={gdpr}>
                    <RFI />
                  </ProtectedRoute>
                  <ProtectedRoute path="/faq" exact auth={auth} gdpr={gdpr}>
                    <FAQ />
                  </ProtectedRoute>
                  <ProtectedRoute path="/help" exact auth={auth} gdpr={gdpr}>
                    <Help />
                  </ProtectedRoute>
                  <Route path="/terms-and-conditions" exact>
                    <TermsAndConditions />
                  </Route>
                  <Route path="/privacy" exact>
                    <Privacy />
                  </Route>
                  <Route path="/impressum" exact>
                    <Impressum />
                  </Route>
                  <Route path="/login" exact>
                    <Login />
                  </Route>
                </Switch>
              </StyledMainWrapper>
              <StyledHelpBorder open={openSideBar} id="helpBorder">
                <StyledHelpButtons>
                  <StyledTopButton
                    onClick={() => handleSidebarContent("info")}
                    className={sidebarContent === "info" ? "active" : null}
                    disableRipple={true}
                    disableFocusRipple={true}
                  >
                    {sidebarContent === "info" ? (
                      <img src={infoIconWhite} alt="info icon white" />
                    ) : (
                      <img src={infoIcon} alt="info icon" />
                    )}
                  </StyledTopButton>
                  <StyledBottomButton
                    onClick={() => handleSidebarContent("chat")}
                    className={sidebarContent === "chat" ? "active" : null}
                    disableRipple={true}
                    disableFocusRipple={true}
                  >
                    {sidebarContent === "chat" ? (
                      <img src={chatIconWhite} alt="chat icon white" />
                    ) : (
                      <img src={chatIcon} alt="chat icon" />
                    )}
                  </StyledBottomButton>
                </StyledHelpButtons>
              </StyledHelpBorder>
            </main>
          </div>
          <StyledHelpDrawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={openSideBar}
            expand={expandSidebar}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {sidebarContent === "info" ? (
              renderSidebarContent(pathname)
            ) : (
              <StyledSidebarWrapper>
                {/* {t("chat placeholder")} */}
                <img src={logoBlack} alt="yvoo" />
              </StyledSidebarWrapper>
            )}

            <StyledExpandButton
              onClick={handleDrawerExpandHelp}
              expand={expandSidebar}
            >
              <img src={openDrawerIcon} alt="expand icon" />
            </StyledExpandButton>
            <StyledCloseButton onClick={handleDrawerCloseHelp}>
              <img src={closeIcon} alt="close icon" />
            </StyledCloseButton>
          </StyledHelpDrawer>
        </Fragment>
      )}
    </Fragment>
  );
});

export default withRouter(AppbarSupplier);
