import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Hub } from "aws-amplify";
import "@aws-amplify/ui/dist/style.css";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { autoEffect, view } from "@risingstack/react-easy-state";

import { auth } from "./stores/auth";
import { language } from "./stores/language";

import ScrollToTop from "./components/shared/parts/ScrollToTop";
import Wrapper from "./components/Wrapper";

const App = view(() => {
  const { i18n } = useTranslation();

  autoEffect(() => {
    Hub.listen("auth", () => {
      if (!auth.user) {
        auth.getUser();
      }
    }); // listen for login/signup events
    auth.getUser(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove("auth", auth.getUser); // cleanup
  }, []);

  autoEffect(() => {
    language.getLanguage();

    if (language.language) {
      i18n.changeLanguage(language.language);
      i18next.on("languageChanged", () => {
        auth.user = null;
        window.location.reload();
      });
    }
  });

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      auth.refreshToken();
    }, 3000000);

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://euc-widget.freshworks.com/widgets/201000000082.js";
    script.async = true;
    script.defer = true;

    // Define the FreshworksWidget settings
    window.fwSettings = {
      widget_id: 201000000082,
    };
    if (typeof window.FreshworksWidget !== "function") {
      window.FreshworksWidget = function () {
        window.FreshworksWidget.q.push(arguments);
      };
      window.FreshworksWidget.q = [];

      // Append the script to the head of the document
      document.head.appendChild(script);
    }

    // Define the cleanup function to remove the script
    return () => {};
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Wrapper />
    </Router>
  );
});

export default App;
