import { store } from "@risingstack/react-easy-state";

import { auth } from "./auth";

import { API_ENDPOINT } from "../consts/globals";

import { authFetch } from "functions";

const abortController = new AbortController();
const signal = abortController.signal;

export const contact = store({
  loading: false,
  errors: null,
  sent: false,
  async sendMessage(receiverId, formValues) {
    contact.sent = false;
    contact.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    const values = {
      reciever_company_id: receiverId,
      sender_mail: auth.user.attributes.email,
      message: formValues.message,
    };

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/company-message`,
        {
          signal: signal,
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        contact.error = true;
        contact.loading = false;
        contact.sent = false;
        throw Error(response.statusText);
      }

      await response.json();

      contact.error = false;
      contact.loading = false;
      contact.sent = true;
    } catch (error) {
      contact.error = true;
      contact.loading = false;
      contact.sent = false;
      console.log(error);
    }
  },
});
