import React, { Fragment, useState } from "react";
import { Formik, Form } from "formik";
import { ConnectedFocusError } from "focus-formik-error";

import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { autoEffect, view } from "@risingstack/react-easy-state";

import { company } from "stores/company";

import Loader from "components/shared/parts/Loader";

import MainForm from "./FormPage/Forms/MainForm";
import validationSchema from "./FormPage/FormModel/validationSchema";
import formModel from "./FormPage/FormModel/formModel";
import formInitialValues from "./FormPage/FormModel/formInitialValues";
import useStyles from "./FormPage/styles";

import { CircularProgress } from "@material-ui/core";

import {
  StyledButton,
  StyledContainer,
  StyledFormWrapper,
  StyledSpacer,
} from "./styled";

import { API_ENDPOINT } from "../../../consts/globals";

import { authFetch } from "functions";

const abortController = new AbortController();
const signal = abortController.signal;

const { formId, formField } = formModel;

const CompanyDetails = view((props) => {
  const classes = useStyles();
  const currentValidationSchema = validationSchema[0];
  const [companyId, setCompanyId] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const { t } = useTranslation();
  const [s3Urls, setS3Urls] = useState(null);
  const [s3Logo, setS3Logo] = useState(null);
  const [s3Presentation, setS3Presentation] = useState(null);
  const [s3Reference, setS3Reference] = useState(null);

  const getS3Data = async (type, companyId, hook) => {
    const response = await authFetch(
      `${API_ENDPOINT}/system/company-bucket?companyId=${companyId}&type=${type}`,
      {
        signal: signal,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const responseData = await response.json();
    hook(
      responseData.files.map(
        (file) => `${process.env.REACT_APP_S3_ENDPOINT}/${file}`
      )
    );
  };

  autoEffect(async () => {
    if (!company.company) {
      await company.getCompany();
    }

    if (company.company) {
      const companyId = company.company.id;
      setCompanyId(companyId);
      await Promise.all([
        getS3Data("image", companyId, setS3Urls),
        getS3Data("logo", companyId, setS3Logo),
        getS3Data("company-presentation", companyId, setS3Presentation),
        getS3Data("reference-list", companyId, setS3Reference),
      ]);
      setInitialValues(company.company);
    } else {
      setCompanyId(null);
      setInitialValues(formInitialValues);
    }
  });

  autoEffect(async () => {
    if (!company.allCompanies) {
      await company.getCompanies();
    }
  });

  async function _submitForm(values, actions) {
    let response;
    if (companyId) {
      response = await company.editCompany(values, companyId);
    } else {
      response = await company.addCompany(values);
    }
    actions.setSubmitting(false);
    setInitialValues(null);
    company.company = null;
    if (response.id) {
      props.enqueueSnackbar(t("update successful"), {
        variant: "success",
      });
    } else {
      props.enqueueSnackbar(t("error updating"), {
        variant: "error",
      });
    }
  }

  // const blobToData = (blob) => {
  //   return new Promise((resolve) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(blob);
  //     reader.onloadend = () => resolve(reader.result);
  //   });
  // };

  async function _handleSubmit(values, actions) {
    values.photos = null;
    values.logo = null;
    values.company_presentation = null;
    values.reference_list = null;
    _submitForm(values, actions);
  }

  return !initialValues || !company.allCompanies || company.loading ? (
    <Fragment>
      <StyledSpacer />
      <Loader />
    </Fragment>
  ) : (
    <Fragment>
      <StyledContainer>
        <StyledFormWrapper fixed>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting, setFieldValue, values, errors }) => (
              <Form id={formId}>
                <ConnectedFocusError />
                <MainForm
                  errors={errors}
                  formField={formField}
                  values={values}
                  setFieldValue={setFieldValue}
                  initialValues={initialValues}
                  companies={company.allCompanies}
                  s3Urls={s3Urls}
                  s3Logo={s3Logo}
                  s3Presentation={s3Presentation}
                  s3Reference={s3Reference}
                />
                <div>
                  <StyledButton disabled={isSubmitting} type="submit">
                    {t("save changes")}
                  </StyledButton>
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </StyledFormWrapper>
      </StyledContainer>
    </Fragment>
  );
});

export default withSnackbar(CompanyDetails);
