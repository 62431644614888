import { store } from "@risingstack/react-easy-state";

import { auth } from "./auth";

import { API_ENDPOINT } from "../consts/globals";

import { authFetch } from "functions";

const abortController = new AbortController();
const signal = abortController.signal;

export const rfi = store({
  loading: false,
  errors: null,
  sent: false,
  rfi: null,
  async sendRFI(receiverId, formValues) {
    rfi.errors = false;
    rfi.sent = false;
    rfi.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    const values = {
      ...formValues,
      supplier_id: receiverId,
    };

    try {
      const response = await authFetch(`${API_ENDPOINT}/system/rfi`, {
        signal: signal,
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        rfi.errors = true;
        rfi.loading = false;
        rfi.sent = false;
        throw Error(response.statusText);
      }

      await response.json();

      rfi.errors = false;
      rfi.loading = false;
      rfi.sent = true;
    } catch (error) {
      rfi.errors = true;
      rfi.loading = false;
      rfi.sent = false;
      console.log(error);
    }
  },
  async getRFI(id) {
    rfi.rfi = null;
    rfi.errors = false;
    rfi.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    // if (!company.company) {
    //   await company.getCompany();
    // }

    try {
      const response = await authFetch(`${API_ENDPOINT}/system/rfi/${id}`, {
        signal: signal,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });

      const responseJson = await response.json();

      if (!response.ok) {
        rfi.errors = true;
        rfi.loading = false;
        rfi.rfi = null;
        throw Error(response.statusText);
      }

      rfi.errors = false;
      rfi.loading = false;
      rfi.rfi = responseJson;
      return responseJson;
    } catch (error) {
      rfi.errors = true;
      rfi.loading = false;
      rfi.rfi = null;
      console.log(error);
    }
  },
});
