import React, { Fragment, useEffect } from "react";
import {
  Grid,
  Box,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useField } from "formik";
import styled from "styled-components";

import Loader from "components/shared/parts/Loader";
import { view } from "@risingstack/react-easy-state";
import { quickscan } from "stores/quickscan";

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledWrapper = styled(Box)`
  margin-bottom: 40px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 40px;
    color: #06d7f9;
    align-items: flex-end !important;

    span {
      font-family: "RobotoRegular", "Arial", sans-serif;
      font-size: 18px;
      line-height: 17px;
      color: #000000;
      text-transform: none;
      margin-left: 20px;
      display: block;
      margin-bottom: 5px;
    }
  }
`;

const StyledAnswerOuter = styled(Box)`
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #dddddd;
  border-top: ${(props) => (props.first ? "1px solid #dddddd" : "none")};

  .MuiFormControlLabel-root {
    margin: 0;
    display: block;
    width: 100%;
  }
`;

const StyledAnswerMiddle = styled(Box)`
  // height: 140px;
  min-height: 100px;
  height: auto;
  width: 100%;
  border-right: 2px solid #ffffff;
  // transition: all 0.3s ease-in-out;
  padding-right: 5px;

  &.selected {
    border-right: 2px solid #06d7f9;
    // props.value === "1"
    //   ? "2px solid #FF0000"
    //   : props.value === "2"
    //   ? "2px solid #FF7B51"
    //   : props.value === "3"
    //   ? "2px solid #FFC527"
    //   : props.value === "4"
    //   ? "2px solid #06D7F9"
    //   : props.value === "5"
    //   ? "2px solid #01E7AA"
    //   : "2px solid #ffffff"};
  }

  &:hover {
    border-right: 2px solid #06d7f9;
    // props.first
    //   ? "2px solid #FF0000"
    //   : props.second
    //   ? "2px solid #FF7B51"
    //   : props.third
    //   ? "2px solid #FFC527"
    //   : props.fourth
    //   ? "2px solid #06D7F9"
    //   : props.fifth
    //   ? "2px solid #01E7AA"
    //   : "2px solid #ffffff"};
  }
`;

const StyledAnswerInner = styled(Box)`
  min-height: 100px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 18px 44px;
  // transition: all 0.3s ease-in-out;

  font-family: 'RobotoMedium', 'Arial', sans-serif;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #000000;

  &:hover {
    background: #f3f9fa;
  }

  &.selected {
    // background: #f3f9fa;
    background: #000000;
    color: #ffffff;
    cursor: default;

    span {
      cursor: text;
    }
  }
`;

const StyledRadio = styled(Radio)`
  display: none;
`;

const MainForm = view((props) => {
  const { step, initialvalues } = props;
  const { label, initialvalue, values, id, ...rest } = props;
  const [field] = useField(props);
  const name = id.name;
  const initialValue = initialvalues[name];
  const [value, setValue] = React.useState(initialValue);

  useEffect(() => {
    setValue(values[name]);
    // eslint-disable-next-line
  }, [step]);

  useEffect(() => {
    quickscan.tempValues = values;
  }, [values]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return !initialvalues ? (
    <Fragment>
      <StyledSpacer />
      <Loader />
    </Fragment>
  ) : (
    <StyledWrapper>
      <Grid container>
        <Grid item xs={12}>
          <h2>
            {step}
            <span>{id.label}</span>
          </h2>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Box style={{ width: "100%" }}>
            <FormControl
              {...rest}
              component="fieldset"
              onChange={handleChange}
              style={{ width: "100%" }}
            >
              <RadioGroup {...field} name={id.name} value={value}>
                <StyledAnswerOuter first="true">
                  <FormControlLabel
                    value={1}
                    control={<StyledRadio />}
                    label={
                      <StyledAnswerMiddle
                        value={value}
                        className={value === '1' ? 'selected' : null}
                        first="true"
                      >
                        <StyledAnswerInner
                          value={value}
                          className={value === '1' ? 'selected' : null}
                        >
                          <span>{id.values[1]}</span>
                        </StyledAnswerInner>
                      </StyledAnswerMiddle>
                    }
                  />
                </StyledAnswerOuter>
                <StyledAnswerOuter>
                  <FormControlLabel
                    value={2}
                    control={<StyledRadio />}
                    label={
                      <StyledAnswerMiddle
                        value={value}
                        className={value === '2' ? 'selected' : null}
                        third="true"
                      >
                        <StyledAnswerInner
                          value={value}
                          className={value === '2' ? 'selected' : null}
                        >
                          <span>{id.values[2]}</span>
                        </StyledAnswerInner>
                      </StyledAnswerMiddle>
                    }
                  />
                </StyledAnswerOuter>
                <StyledAnswerOuter>
                  <FormControlLabel
                    value={3}
                    control={<StyledRadio />}
                    label={
                      <StyledAnswerMiddle
                        value={value}
                        className={value === '3' ? 'selected' : null}
                        third="true"
                      >
                        <StyledAnswerInner
                          value={value}
                          className={value === '3' ? 'selected' : null}
                        >
                          <span>{id.values[3]}</span>
                        </StyledAnswerInner>
                      </StyledAnswerMiddle>
                    }
                  />
                </StyledAnswerOuter>
                <StyledAnswerOuter>
                  <FormControlLabel
                    value={4}
                    control={<StyledRadio />}
                    label={
                      <StyledAnswerMiddle
                        value={value}
                        className={value === '4' ? 'selected' : null}
                        third="true"
                      >
                        <StyledAnswerInner
                          value={value}
                          className={value === '4' ? 'selected' : null}
                        >
                          <span>{id.values[4]}</span>
                        </StyledAnswerInner>
                      </StyledAnswerMiddle>
                    }
                  />
                </StyledAnswerOuter>
                <StyledAnswerOuter>
                  <FormControlLabel
                    value={5}
                    control={<StyledRadio />}
                    label={
                      <StyledAnswerMiddle
                        value={value}
                        className={value === '5' ? 'selected' : null}
                        third="true"
                      >
                        <StyledAnswerInner
                          value={value}
                          className={value === '5' ? 'selected' : null}
                        >
                          <span>{id.values[5]}</span>
                        </StyledAnswerInner>
                      </StyledAnswerMiddle>
                    }
                  />
                </StyledAnswerOuter>
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </StyledWrapper>
  );
});

export default MainForm;
