import { store } from "@risingstack/react-easy-state";

import { auth } from "./auth";
import { company } from "./company";

import { API_ENDPOINT } from "../consts/globals";

import { authFetch } from "functions";

const abortController = new AbortController();
const signal = abortController.signal;

export const supplierProducts = store({
  segments: null,
  families: null,
  classes: null,
  commodities: null,
  supplierProducts: null,
  loading: false,
  sideLoading: false,
  async getSegments() {
    supplierProducts.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/categories-segment`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      if (responseJson.errors) {
        supplierProducts.loading = false;
        return;
      } else {
        supplierProducts.segments = responseJson.data;
        supplierProducts.loading = false;
      }
    } catch (error) {
      supplierProducts.loading = false;
      console.log(error);
    }
  },
  async getFamilies(segment) {
    supplierProducts.sideLoading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/categories-segment/${segment}?include=family`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      if (responseJson.errors) {
        supplierProducts.sideLoading = false;
        return;
      } else {
        supplierProducts.families = responseJson.categoryFamily;
        supplierProducts.sideLoading = false;
      }
    } catch (error) {
      supplierProducts.sideLoading = false;
      console.log(error);
    }
  },
  async getClasses(family) {
    supplierProducts.sideLoading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/categories-family/${family}?include=class`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      if (responseJson.errors) {
        supplierProducts.sideLoading = false;
        return;
      } else {
        supplierProducts.classes = responseJson.categoryClass;
        supplierProducts.sideLoading = false;
      }
    } catch (error) {
      supplierProducts.sideLoading = false;
      console.log(error);
    }
  },
  async getCommodities(pclass) {
    supplierProducts.sideLoading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/categories-class/${pclass}?include=commodity`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      if (responseJson.errors) {
        supplierProducts.sideLoading = false;
        return;
      } else {
        supplierProducts.commodities = responseJson.categoryCommodity;
        supplierProducts.sideLoading = false;
      }
    } catch (error) {
      supplierProducts.sideLoading = false;
      console.log(error);
    }
  },
  async searchCommodities(input) {
    supplierProducts.sideLoading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/commodity-search?filter-commodity=${input}`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      if (responseJson.errors) {
        supplierProducts.sideLoading = false;
        return;
      } else {
        supplierProducts.sideLoading = false;
        return responseJson.data;
      }
    } catch (error) {
      supplierProducts.sideLoading = false;
      console.log(error);
    }
  },
  async getSupplierProducts() {
    supplierProducts.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    if (company.company) {
      try {
        const response = await authFetch(
          `${API_ENDPOINT}/system/company-categories/${company.company.id}?include=categories`,
          {
            signal: signal,
            method: "GET",
            headers: {
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        );

        if (!response.ok) {
          throw Error(response.statusText);
        }

        const responseJson = await response.json();

        if (responseJson.errors) {
          supplierProducts.loading = false;
          return;
        } else {
          supplierProducts.supplierProducts = responseJson.data;
          supplierProducts.loading = false;
        }
      } catch (error) {
        supplierProducts.loading = false;
        console.log(error);
      }
    } else {
      supplierProducts.loading = false;
    }
  },
  async addSupplierProducts(values) {
    supplierProducts.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    values.company_id = company.company.id;

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/company-categories`,
        {
          signal: signal,
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await response.json();

      setTimeout(() => {
        supplierProducts.supplierProducts = null;
        supplierProducts.getSupplierProducts();
      }, 1000);
    } catch (error) {
      supplierProducts.supplierProducts = null;
      console.log(error);
    }
  },
  async editSupplierProducts(values, id) {
    supplierProducts.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/company-categories/${id}`,
        {
          signal: signal,
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await response.json();

      supplierProducts.supplierProducts = null;
      await supplierProducts.getSupplierProducts();
    } catch (error) {
      supplierProducts.supplierProducts = null;
      console.log(error);
    }
  },
});
