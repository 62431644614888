import Box from "@material-ui/core/Box";

import Container from "@material-ui/core/Container";

import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import styled from "styled-components";

export const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

export const StyledMain = styled(Box)`
  width: 100%;
  z-index: 2;
  position: relative;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
  }
`;

export const StyledMainWrapper = styled(Container)`
  width: 100%;
  position: relative;

  position: relative;
  z-index: 2;
  margin-bottom: 60px;

  h3 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-weight: normal;
    font-size: 21px;
    line-height: 24px;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
    margin-bottom: 36px;

    span {
      color: #808285;

      &.separator {
        color: #cfcfcf;
      }
    }
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  .MuiTable-root {
    border-collapse: separate;
  }

  .MuiTableCell-root {
    border-bottom: none;
  }

  .MuiTableHead-root {
    background-color: #000000;

    .MuiTableCell-head {
      font-family: "RobotoRegular", "Arial", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      color: #ffffff;
    }
  }

  .MuiTableCell-body {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;

    a {
      text-decoration-line: underline;
      color: #00c9e9;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const StyledSpecialCell = styled.span`
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  text-transform: uppercase;
  color: #00bfe9;
`;

export const StyledTableCell = styled(TableCell)`
  position: relative;
`;

export const StyledTableCellBorder = styled(Box)`
  position: absolute;
  right: 0;
  bottom: 0;
  height: calc(100% - 2px);
  width: 10px;
  background-color: ${(props) =>
    props.status === "open"
      ? "#FFC527"
      : props.status === "complete"
      ? "#01E7AA"
      : "#FFFFFF"};
`;
