import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { useTranslation } from "react-i18next";
import { view } from "@risingstack/react-easy-state";

import { search } from "stores/search";

import searchGraphic from "assets/img/searchGraphic.svg";
import searchIcon from "assets/img/searchIcon.svg";

import {
  customStyles,
  StyledImage,
  StyledSearchButton,
  StyledSearchWrapper,
  StyledWrapper,
} from "./styled";

const SearchInput = view(() => {
  const [value, setValue] = useState("");
  const [selectedResult, setSelectedResult] = useState(null);
  const [tempValues, setTempValues] = useState(null);
  const history = useHistory();
  let allowCall = true;
  let selectRef = useRef(null);
  const { t } = useTranslation();

  let results = (inputValue, data) => {
    // data.filter((key) =>
    //   key.name.toLowerCase().includes(inputValue.toLowerCase())
    return data;
  };

  const handleInputChange = (input, params) => {
    selectRef.current.select.select.getNextFocusedOption = () => false;

    if (params.action === "input-change") {
      setValue({
        inputValue: input,
      });
    }
    // setValue({
    //   inputValue: input,
    // });
    setTempValues(input);
    return { value };
  };

  const handleChange = (selectedOption) => {
    setSelectedResult(selectedOption);
  };

  const handleGetResults = (inputValue) =>
    inputValue.length > 2 &&
    allowCall &&
    search
      .getSuggestions(inputValue)
      .then((response) => {
        return results(inputValue, response);
      })
      .catch((data) => {
        console.log(data);
      });

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(handleGetResults(inputValue));
      }, 1000);
    });

  let counter = setTimeout(() => {
    allowCall = true;
  }, 1000);

  const handleKeyDown = () => {
    allowCall = false;
    clearTimeout(counter);
    return counter;
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (value.inputValue) {
      history.push(
        `/search-results/${value.inputValue.toLowerCase()}/region/all/page/1`
      );
    }
  };

  useEffect(() => {
    if (selectedResult) {
      history.push(
        `/search-results/${selectedResult.commodity_name.toLowerCase()}/region/all/page/1`
      );
    }
    // eslint-disable-next-line
  }, [selectedResult]);

  return (
    <StyledWrapper>
      <form onSubmit={submitForm}>
        <StyledSearchWrapper>
          <AsyncSelect
            ref={selectRef}
            styles={customStyles}
            value={selectedResult}
            loadOptions={promiseOptions}
            defaultOptions
            onInputChange={handleInputChange}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={t("search")}
            loadingMessage={() => `${t("searching")}...`}
            noOptionsMessage={() => t("no suggested products")}
            getOptionValue={(option) => option.commodity_name}
            getOptionLabel={(option) => {
              return option.commodity_name;
            }}
            menuIsOpen={tempValues ? true : false}
          />
          <StyledSearchButton onClick={submitForm}>
            <img src={searchIcon} alt="search icon" />
          </StyledSearchButton>
        </StyledSearchWrapper>
        <StyledImage src={searchGraphic} alt="search graphic" />
      </form>
    </StyledWrapper>
  );
});

export default SearchInput;
