import styled from "styled-components";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

export const StyledGridContainer = styled(Grid)`
  // min-height: calc(100vh - 75px);
  min-height: calc(100vh);
  position: relative;
`;

export const StyledWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

/* ========================================= */

// const StyledAuthWrapper = styled(Box)`
//   background-color: #f3f8f9;
//   z-index: 2;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   input:-webkit-autofill,
//   input:-webkit-autofill:hover,
//   input:-webkit-autofill:focus,
//   input:-webkit-autofill:active {
//     transition: background-color 5000s;
//     -webkit-text-fill-color: #a7acb3 !important;
//   }

//   input:-webkit-autofill::first-line {
//     font-size: 21px;
//   }

//   amplify-authenticator {
//     display: flex !important;
//     flex-direction: column !important;
//     justify-content: center;
//     align-items: center;
//   }

//   amplify-form-section {
//     background-color: transparent !important;
//   }

//   .section {
//     background-color: transparent !important;
//   }

//   div {
//     &[class^="Toast__toast"] {
//       // color: #000000;
//       background-color: #00bfe9;
//       [class^="Toast__toastClose"] {
//         &::before {
//           background-color: #ffffff;
//         }
//         &::after {
//           background-color: #ffffff;
//         }
//       }
//     }

//     &[class^="Form__formSection"] {
//       background-color: transparent;
//       border-radius: 0;
//       box-shadow: none;
//       max-width: 525px;
//       padding-top: 130px;

//       // @media (max-width: 959px) {
//       //   padding-top: 230px;
//       // }

//       [class^="Section__sectionHeaderContent"] {
//         h2 {
//           font-family: "NeueMachinaRegular", "Arial", sans-serif;
//           font-weight: 900;
//           font-size: 36px;
//           line-height: 40px;
//           text-align: center;
//           text-transform: uppercase;
//           color: #000000;

//           // span {
//           //   color: #06d7f9;
//           // }
//         }

//         p {
//           font-family: "RobotoRegular", "Arial", sans-serif;
//           font-size: 14px;
//           text-align: center;
//           color: #000000;
//         }
//       }

//       [class^="Section__sectionHeader"] {
//         margin-bottom: 55px;
//       }

//       [class^="Section__sectionBody"] {
//         margin-bottom: 80px;
//       }

//       [class^="Input__input"] {
//         font-family: "RobotoRegular", "Arial", sans-serif;
//         border-radius: 0;
//         border: 1px solid #cfcfcf;

//         // color: #a7acb3;
//         // background-color: transparent;
//         // font-size: 21px;
//         // border: none;
//         // border-bottom: 1px solid rgba(167, 172, 180, 0.5);
//         // outline: none !important;
//         // box-shadow: none !important;
//         // margin-bottom: 40px;
//         // padding: 12px 16px 20px 16px;

//         // &::placeholder {
//         //   color: #a7acb3;
//         // }
//         // &::-webkit-input-placeholder {
//         //   color: #a7acb3;
//         // }
//         // &::-moz-placeholder {
//         //   color: #a7acb3;
//         // }
//         // &:-ms-input-placeholder {
//         //   color: #a7acb3;
//         // }
//         // &:-moz-placeholder {
//         //   color: #a7acb3;
//         // }
//       }

//       [class^="Input__inputLabel"] {
//         font-family: "RobotoRegular", "Arial", sans-serif;
//         font-size: 14px;
//         color: #000000;
//         border: none;

//         // font-family: "AzoSans Bold", "Arial", sans-serif;
//         // font-size: 14px;
//         // color: #ffffff;
//         // border-radius: 0;
//         // border: none;
//         // border-bottom: none;
//         // margin-bottom: 8px;
//         // padding: 0 0 0 16px;
//       }

//       [class^="Hint__hint"] {
//         color: #000000;
//         font-size: 14px;
//         padding: 0 0 0 16px;
//       }

//       [class^="Anchor__a"] {
//         color: #00bfe9;
//         text-decoration: none;
//       }

//       [class^="Section__sectionFooter"] {
//         flex-wrap: wrap;
//       }

//       [class^="Section__sectionFooterPrimaryContent"] {
//         width: 100%;
//         color: #ffffff;
//         background-color: transparent;
//         margin-bottom: 36px;

//         [class^="Button__button"] {
//           width: 100%;
//           font-family: "RobotoRegular", "Arial", sans-serif;
//           color: #00bfe9;
//           font-size: 18px;
//           line-height: 24px;

//           background-color: transparent;
//           border: 1px solid #00bfe9;
//           padding: 20px;
//         }
//       }

//       [class^="Section__sectionFooterSecondaryContent"] {
//         width: 100%;
//         font-family: "RobotoRegular", "Arial", sans-serif;
//         color: #000000;
//         font-size: 18px;
//         line-height: 24px;
//         text-align: center;
//         background-color: transparent;
//         // border: 1px solid #00bfe9;
//         // padding: 20px;

//         a {
//           color: #00bfe9;
//         }
//       }
//     }
//   }
// `;

export const StyledAuthWrapper = styled(Box)`
  margin-top: 75px;
  background-color: white;
  z-index: 2;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s;
    -webkit-text-fill-color: #a7acb3 !important;
  }

  input:-webkit-autofill::first-line {
    font-size: 21px;
  }

  .toast {
    top: 75px;
    border-radius: 0;
  }
`;

export const StyledWrapperOuter = styled(Box)`
  h2 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: 900;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
  }

  h4 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 30px;
    align-items: center;
    text-transform: uppercase;
    color: #474747;
    margin: 0;
  }

  p {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    text-align: center;
    color: #000000;
  }
`;

export const StyledMainWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledGraphicWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledGraphicInner = styled(Box)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.075);
    z-index: 2;
  }
`;

export const StyledTypeWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 113px;
  width: 160px;

  h3 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 24px;
    align-items: center;
    text-transform: uppercase;
    color: ${(props) => props.border};
    margin: 0;
    margin-bottom: 10px;
  }

  h4 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    align-items: center;
    text-transform: uppercase;
    color: #474747;
    margin: 0;
  }
`;

export const StyledTypeBox = styled(Box)`
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${(props) => props.border};
  cursor: pointer;
  margin-bottom: 23px;

  div {
    height: 20px;
    width: 20px;
    background-color: ${(props) =>
      props.selected ? props.border : "transparent"};
  }
`;

export const StyledToast = styled(Box)`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: absolute;
  top: 75px;
  left: 0;
  width: 100%;
  z-index: 99;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  padding: 16px;
  background-color: #06d6f8;
  font-size: var(--font-size);
  color: #ffffff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
`;

export const StyledToastWrapper = styled(Box)`\
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    margin-right: 14px;
  }
`;
