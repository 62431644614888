import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { autoEffect, view } from "@risingstack/react-easy-state";

import { company } from "stores/company";
import { selfAssessment } from "stores/selfAssessment";

import Loader from "components/shared/parts/Loader";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";

import step1 from "assets/img/step1.svg";
import step2 from "assets/img/step2.svg";
import step3 from "assets/img/step3.svg";
import step4 from "assets/img/step4.svg";
import arrowRight from "assets/img/arrowRight.svg";

import {
  StyledButtonPrimary,
  StyledEndWrapper,
  StyledImage,
  StyledImageWrapper,
  StyledIntro,
  StyledIntroWrapper,
  StyledSpacer,
  StyledStepFirstRow,
  StyledStepFourthRow,
  StyledStepInner,
  StyledStepSecondRow,
  StyledStepsWrapper,
  StyledStepThirdRow,
  StyledStepWrapper,
  StyledStepWrapperBorderless,
} from "./styled";

const SelfAssessment = view(() => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  let history = useHistory();
  const { t } = useTranslation();

  autoEffect(async () => {
    if (!company.company) {
      await company.getCompany();
    }

    if (!selfAssessment.selfAssessment) {
      await selfAssessment.getSelfAssessment();
    }

    if (!company.company) {
      history.push("/company-details");
    }
  });

  return (
    <Fragment>
      {!selfAssessment.selfAssessment || selfAssessment.loading ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"flex-start"}
              >
                <Grid item xs={12} lg={4}>
                  <h2>{t("4 steps for a successful rating")}</h2>
                </Grid>
                <Grid item xs={12} lg={7}>
                  <p>
                    {t(
                      "the accuracy of the results is depending on the amount of information you provide"
                    )}
                  </p>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
            <StyledStepsWrapper fixed>
              <Grid container justify="space-between" alignItems="flex-start">
                <Grid item xs={12} lg={6}>
                  <StyledStepWrapper>
                    <StyledStepInner>
                      <StyledImageWrapper>
                        <img src={step1} alt="step1" />
                      </StyledImageWrapper>
                      <StyledStepFirstRow container>
                        <Grid item xs={12} md={2}>
                          <h1>1</h1>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <h3>{t("rfi supplier self disclosure")}</h3>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <StyledEndWrapper>
                            <StyledImage src={arrowRight} alt="arrow right" />
                          </StyledEndWrapper>
                        </Grid>
                      </StyledStepFirstRow>
                      <StyledStepSecondRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <p>{t("SA info 1")}</p>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                      </StyledStepSecondRow>
                      <StyledStepThirdRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <Link to="/company-details">
                            <StyledButtonPrimary>
                              {t("start")}
                            </StyledButtonPrimary>
                          </Link>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                      </StyledStepThirdRow>
                      <StyledStepFourthRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <LinearProgress
                            variant="determinate"
                            value={selfAssessment.selfAssessment.score_step_one}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <StyledEndWrapper>
                            <p>
                              {selfAssessment.selfAssessment.score_step_one}
                            </p>
                          </StyledEndWrapper>
                        </Grid>
                      </StyledStepFourthRow>
                    </StyledStepInner>
                  </StyledStepWrapper>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <StyledStepWrapperBorderless>
                    <StyledStepInner>
                      <StyledImageWrapper>
                        <img src={step2} alt="step2" />
                      </StyledImageWrapper>
                      <StyledStepFirstRow container>
                        <Grid item xs={12} md={2}>
                          <h1>2</h1>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <h3>{t("how lean is your company?")}</h3>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <StyledEndWrapper>
                            <StyledImage src={arrowRight} alt="arrow right" />
                          </StyledEndWrapper>
                        </Grid>
                      </StyledStepFirstRow>
                      <StyledStepSecondRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <p>{t("SA info 2")}</p>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                      </StyledStepSecondRow>
                      <StyledStepThirdRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <Link to="/lean">
                            <StyledButtonPrimary>
                              {t("start")}
                            </StyledButtonPrimary>
                          </Link>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                      </StyledStepThirdRow>
                      <StyledStepFourthRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <LinearProgress
                            variant="determinate"
                            value={selfAssessment.selfAssessment.score_step_two}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <StyledEndWrapper>
                            <p>
                              {selfAssessment.selfAssessment.score_step_two}
                            </p>
                          </StyledEndWrapper>
                        </Grid>
                      </StyledStepFourthRow>
                    </StyledStepInner>
                  </StyledStepWrapperBorderless>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <StyledStepWrapper>
                    <StyledStepInner>
                      <StyledImageWrapper>
                        <img src={step3} alt="step3" />
                      </StyledImageWrapper>
                      <StyledStepFirstRow container>
                        <Grid item xs={12} md={2}>
                          <h1>3</h1>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <h3>{t("the quick scan")}</h3>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <StyledEndWrapper>
                            <StyledImage src={arrowRight} alt="arrow right" />
                          </StyledEndWrapper>
                        </Grid>
                      </StyledStepFirstRow>
                      <StyledStepSecondRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <p>{t("SA info 3")}</p>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                      </StyledStepSecondRow>
                      <StyledStepThirdRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <Link to="/quickscan">
                            <StyledButtonPrimary>
                              {t("start")}
                            </StyledButtonPrimary>
                          </Link>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                      </StyledStepThirdRow>
                      <StyledStepFourthRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <LinearProgress
                            variant="determinate"
                            value={
                              selfAssessment.selfAssessment.score_step_three
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <StyledEndWrapper>
                            <p>
                              {selfAssessment.selfAssessment.score_step_three}
                            </p>
                          </StyledEndWrapper>
                        </Grid>
                      </StyledStepFourthRow>
                    </StyledStepInner>
                  </StyledStepWrapper>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <StyledStepWrapperBorderless>
                    <StyledStepInner>
                      <StyledImageWrapper>
                        <img src={step4} alt="step4" />
                      </StyledImageWrapper>
                      <StyledStepFirstRow container>
                        <Grid item xs={12} md={2}>
                          <h1>4</h1>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <h3>{t("step supplier products")}</h3>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <StyledEndWrapper>
                            <StyledImage src={arrowRight} alt="arrow right" />
                          </StyledEndWrapper>
                        </Grid>
                      </StyledStepFirstRow>
                      <StyledStepSecondRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <p>{t("SA info 4")}</p>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                      </StyledStepSecondRow>
                      <StyledStepThirdRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <Link to="/supplier-products">
                            <StyledButtonPrimary>
                              {t("start")}
                            </StyledButtonPrimary>
                          </Link>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                      </StyledStepThirdRow>
                      <StyledStepFourthRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <LinearProgress
                            variant="determinate"
                            value={
                              selfAssessment.selfAssessment.score_step_four
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <StyledEndWrapper>
                            <p>
                              {selfAssessment.selfAssessment.score_step_four}
                            </p>
                          </StyledEndWrapper>
                        </Grid>
                      </StyledStepFourthRow>
                    </StyledStepInner>
                  </StyledStepWrapperBorderless>
                </Grid>
              </Grid>
            </StyledStepsWrapper>
          </StyledIntro>
        </Fragment>
      )}
    </Fragment>
  );
});

export default SelfAssessment;
