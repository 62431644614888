import React, { Fragment, useRef, useState } from "react";
import { Grid, Box, InputLabel } from "@material-ui/core";
import { InputField, SelectField } from "../../FormFields";
import styled from "styled-components";
import { DropzoneArea } from "material-ui-dropzone";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";

import fileUploadIcon from "assets/img/fileUpload.svg";

import Loader from "components/shared/parts/Loader";
import { view } from "@risingstack/react-easy-state";

import { API_ENDPOINT } from "consts/globals";

import { authFetch } from "functions";
import { search } from "stores/search";
import { TextErrorSC, customStyles } from "../styles";
import PhoneInput from "react-phone-number-input";
import "../phoneInput.css";
import { auth } from "stores/auth";

const abortController = new AbortController();
const signal = abortController.signal;

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledSectionOuter = styled(Box)`
  border-left: 2px solid #01e7aa;
  padding-left: 11px;
  margin-bottom: 60px;
`;

const StyledSectionInner = styled(Box)`
  background: #f3f9fa;
  padding: 40px;

  .MuiInputLabel-root {
    font-family: "RobotoRegular", "Arial", sans-serif;
    transform: none;
    display: block;
    font-size: 14px;
    line-height: 93.1%;
    color: #000000;
    margin-bottom: 9px;
  }

  .MuiFormLabel-asterisk {
    color: #ff490f;
  }

  .MuiDropzoneArea-root {
    height: 133px;
    border-radius: 0;
    min-height: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 29px;

    p {
      margin: 0;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 120.1%;
      text-align: center;
      color: #b0b0b0;
    }
  }

  .MuiDropzonePreviewList-root {
    display: flex;
    justify-content: center;
  }

  .MuiSnackbar-root {
    right: 24px;
    left: auto;
  }

  .MuiDropzoneArea-text {
    display: none;
  }

  .MuiDropzonePreviewList-image {
    height: 50px;
  }
`;

const UploadFakeLabel = styled.span`
  display: block;
  font-size: 14px;
  line-height: 93.1%;
  color: #000000;
  margin-bottom: 9px;
`;

const PhoneInputWrapper = styled.div`
  .phoneInput {
    input {
      border: 1px solid #cfcfcf;
      border-color: ${(props) => props.error && "#f44336"};
      font-size: 16px;
      outline: none;

      &:focus {
        border: 2px solid #06d7f9;
        color: #06d7f9;
      }

      &:not(:focus) {
        &:hover {
          border: ${(props) =>
            typeof props.error === "undefined" &&
            "1px solid rgba(0, 0, 0, 0.87)"};
        }
      }
    }
  }
`;

const StyledSubscriptionPlanText = styled.p`
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin: 0;
  margin-top: -24px;
  margin-bottom: 24px;
  text-transform: none;
`;

const MainForm = view((props) => {
  let selectRef = useRef(null);
  const [selectedResult, setSelectedResult] = useState(null);
  const [value, setValue] = useState("");
  const [tempValues, setTempValues] = useState(null);
  let allowCall = true;

  let counter = setTimeout(() => {
    allowCall = true;
  }, 1000);

  let results = (inputValue, data) => {
    // data.filter((key) =>
    //   key.name.toLowerCase().includes(inputValue.toLowerCase())
    return [data];
  };

  const handleKeyDown = () => {
    allowCall = false;
    clearTimeout(counter);
    return counter;
  };

  const handleInputChange = (input, params) => {
    selectRef.current.select.select.getNextFocusedOption = () => false;

    if (params.action === "input-change") {
      setValue({
        inputValue: input,
      });
    }
    // setValue({
    //   inputValue: input,
    // });
    setTempValues(input);
    return { value };
  };

  const handleGetResults = (inputValue) =>
    inputValue.length > 2 &&
    allowCall &&
    search
      .getAutocompleteSuggestions(inputValue)
      .then((response) => {
        return results(inputValue, response);
      })
      .catch((data) => {
        console.log(data);
      });

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(handleGetResults(inputValue));
      }, 1000);
    });

  const handleChange = (selectedOption) => {
    setSelectedResult(selectedOption);
    setFieldValue(city.name, selectedOption.city);
    setFieldValue(zip.name, selectedOption.zipCode);
    setFieldValue(country.name, selectedOption.country);
    setFieldValue(street.name, selectedOption.address);
  };

  const {
    formField: {
      name,
      email,
      profile_photo,
      mobile_phone,
      country,
      zip,
      job_function,
      job_level,
      discipline,
      industry,
      company_name,
      street,
      city,
    },
    initialValues,
    values,
    setFieldValue,
    errors,
  } = props;
  const { t } = useTranslation();

  // const preparedCountries = Array.from(countries)
  //   .sort((a, b) => {
  //     return a.name.localeCompare(b.name, undefined, {
  //       numeric: true,
  //       sensitivity: "base",
  //     });
  //   })
  //   .map((country) => {
  //     return {
  //       value: country.name,
  //       label: country.name.charAt(0).toUpperCase() + country.name.slice(1),
  //       iso: country.iso,
  //     };
  //   });
  const industries = [
    {
      value: undefined,
      label: t("none"),
    },
    {
      value: "Advertising",
      label: t("advertising"),
    },
    {
      value: "Agriculture",
      label: t("agriculture"),
    },
    {
      value: "Banking & Financial Services",
      label: t("banking & financial services"),
    },
    {
      value: "Civil Construction",
      label: t("civil construction"),
    },
    {
      value: "Consulting",
      label: t("consulting"),
    },
    {
      value: "Ecommerce",
      label: t("ecommerce"),
    },
    {
      value: "Education",
      label: t("education"),
    },
    {
      value: "Electronics / Hardware",
      label: t("electronics / hardware"),
    },
    {
      value: "Energy",
      label: t("energy"),
    },
    {
      value: "Fast Moving Consumer Goods",
      label: t("fast moving consumer goods"),
    },
    {
      value: "Healthcare",
      label: t("healthcare"),
    },
    {
      value: "Human Resources Service Provider",
      label: t("human resources service provider"),
    },
    {
      value: "Industrial Goods",
      label: t("industrial goods"),
    },
    {
      value: "IT Products",
      label: t("IT products"),
    },
    {
      value: "IT Services",
      label: t("IT services"),
    },
    {
      value: "Media",
      label: t("media"),
    },
    {
      value: "Real Estate",
      label: t("real estate"),
    },
    {
      value: "Retail",
      label: t("retail"),
    },
    {
      value: "Telecommunication",
      label: t("telecommunication"),
    },
    {
      value: "Tourism / Hotel / Restaurant",
      label: t("tourism / hotel / restaurant"),
    },
    {
      value: "Transport - Logistic",
      label: t("transport - logistic"),
    },
    {
      value: "Other",
      label: t("other"),
    },
  ];

  const job_functions = [
    {
      value: undefined,
      label: t("none"),
    },
    {
      value: "Accounting and Control",
      label: t("accounting and control"),
    },
    {
      value: "Consulting",
      label: t("consulting"),
    },
    {
      value: "Customer Support / Service",
      label: t("customer support / service"),
    },
    {
      value: "Engineering",
      label: t("engineering"),
    },
    {
      value: "Finance",
      label: t("finance"),
    },
    {
      value: "General Management",
      label: t("general management"),
    },
    {
      value: "Human Resources Management",
      label: t("human resources management"),
    },
    {
      value: "Information Technology",
      label: t("information technology"),
    },
    {
      value: "Learning and Development",
      label: t("learning and development"),
    },
    {
      value: "Legal",
      label: t("legal"),
    },
    {
      value: "Logistics / Purchasing",
      label: t("logistics / purchasing"),
    },
    {
      value: "Manufacturing and Operations",
      label: t("manufacturing and operations"),
    },
    {
      value: "Marketing / Communications / PR",
      label: t("marketing / communications / PR"),
    },
    {
      value: "Medical",
      label: t("medical"),
    },
    {
      value: "Project Management",
      label: t("project management"),
    },
    {
      value: "Research and Development",
      label: t("research and development"),
    },
    {
      value: "Sales and Business Development",
      label: t("sales and business development"),
    },
    {
      value: "Strategy / Corporate Planning",
      label: t("strategy / corporate planning"),
    },
    {
      value: "Other",
      label: t("other"),
    },
  ];

  const job_levels = [
    {
      value: undefined,
      label: t("none"),
    },
    {
      value: "Assistant / Entry-Level",
      label: t("assistant / entry-Level"),
    },
    {
      value: "Chairman",
      label: t("chairman"),
    },
    {
      value: "Director",
      label: t("director"),
    },
    {
      value: "Manager",
      label: t("manager"),
    },
    {
      value: "Specialist",
      label: t("specialist"),
    },
    {
      value: "Team Lead",
      label: t("team lead"),
    },
    {
      value: "Other",
      label: t("other"),
    },
  ];

  const disciplines = [
    {
      value: undefined,
      label: t("other"),
    },
    {
      value: "Engineering",
      label: t("engineering"),
    },
    {
      value: "Finance",
      label: t("finance"),
    },
    {
      value: "IT",
      label: t("IT"),
    },
    {
      value: "Management",
      label: t("management"),
    },
    {
      value: "Material Management",
      label: t("material management"),
    },
    {
      value: "Production",
      label: t("production"),
    },
    {
      value: "Quality Management",
      label: t("quality management"),
    },
    {
      value: "R&D",
      label: t("R&D"),
    },
    {
      value: "Sales",
      label: t("sales"),
    },
    {
      value: "Other",
      label: t("other"),
    },
  ];

  return !initialValues ? (
    <Fragment>
      <StyledSpacer />
      <Loader />
    </Fragment>
  ) : (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <h2>{t("user profile")}</h2>
          <StyledSubscriptionPlanText>
            {t("subscription-plan")}:{" "}
            {auth.user?.dbData?.paid
              ? t("subscription-plan-pro")
              : t("subscription-plan-free")}
          </StyledSubscriptionPlanText>
        </Grid>
      </Grid>
      <StyledSectionOuter>
        <StyledSectionInner>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={4}>
              <InputLabel
                htmlFor={name.name}
                shrink={true}
                variant="outlined"
                required
              >
                {name.label}
              </InputLabel>
              <InputField
                id={name.name}
                name={name.name}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel htmlFor={email.name} shrink={true} variant="outlined">
                {email.label}
              </InputLabel>
              <InputField
                id={email.name}
                name={email.name}
                fullWidth
                variant="outlined"
                size="small"
                disabled
              />
              <UploadFakeLabel>{profile_photo.label}</UploadFakeLabel>
              <DropzoneArea
                id={profile_photo.name}
                name={profile_photo.name}
                filesLimit={1}
                dropzoneText=""
                showPreviewsInDropzone
                maxFileSize={524288000}
                previewGridProps={{ container: { spacing: 0 } }}
                acceptedFiles={[".png", ".jpg", ".jpeg"]}
                initialFiles={
                  initialValues["profile_photo"]
                    ? [initialValues["profile_photo"]]
                    : []
                }
                Icon={() => {
                  return (
                    <Fragment>
                      <img
                        src={fileUploadIcon}
                        alt="file upload icon"
                        style={{ marginBottom: "15px" }}
                      />
                      {!values["profile_photo"] ||
                      values["profile_photo"].length === 0 ? (
                        <p>{t("drag and drop your file here")}</p>
                      ) : null}
                    </Fragment>
                  );
                }}
                onDelete={async () => {
                  try {
                    await authFetch(`${API_ENDPOINT}/system/user-bucket`, {
                      signal: signal,
                      method: "DELETE",
                      headers: {
                        "Content-type": "application/json",
                        Accept: "application/json",
                      },
                      body: JSON.stringify({
                        type: "pfp",
                      }),
                    });
                    setFieldValue(profile_photo.name, "");
                  } catch (err) {
                    console.log(err);
                  }
                }}
                onDrop={async (acceptedFiles) => {
                  // do nothing if no files
                  if (acceptedFiles.length === 0) {
                    return;
                  }

                  const file = acceptedFiles[0];

                  const body = new FormData();
                  body.append("uploadFile", file, "file");
                  body.append("type", "pfp");
                  const fileUpload = await authFetch(
                    `${API_ENDPOINT}/system/user-bucket`,
                    {
                      signal: signal,
                      method: "POST",
                      headers: {
                        Accept: "application/json",
                      },
                      body: body,
                    }
                  );
                  const fileUploadData = await fileUpload.json();
                  setFieldValue(profile_photo.name, fileUploadData.url);
                }}
              />
              <InputLabel
                htmlFor={mobile_phone.name}
                shrink={true}
                variant="outlined"
              >
                {mobile_phone.label}
              </InputLabel>
              <PhoneInputWrapper error={errors.mobile_phone}>
                <PhoneInput
                  className="phoneInput"
                  international
                  placeholder="Enter phone number"
                  onChange={(e) => {
                    setFieldValue(mobile_phone.name, e);
                  }}
                  value={initialValues.mobile_phone}
                />
                <TextErrorSC>{errors.mobile_phone}</TextErrorSC>
              </PhoneInputWrapper>
              {/* <InputField
                id={mobile_phone.name}
                name={mobile_phone.name}
                fullWidth
                variant="outlined"
                size="small"
                placeholder="+1 23 456 7890"
              /> */}
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputLabel shrink={true} variant="outlined">
                Full address
              </InputLabel>
              <AsyncSelect
                id={city.location}
                name={city.location}
                ref={selectRef}
                styles={customStyles}
                value={selectedResult}
                loadOptions={promiseOptions}
                defaultOptions
                onInputChange={handleInputChange}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder={t("search")}
                loadingMessage={() => `${t("searching")}...`}
                noOptionsMessage={() => t("no suggested products")}
                getOptionValue={(option) => {
                  return option.fullAddress;
                }}
                getOptionLabel={(option) => {
                  return option.fullAddress;
                }}
                defaultInputValue={initialValues.fullAddress}
                menuIsOpen={tempValues ? true : false}
              />
              <InputLabel
                htmlFor={street.name}
                shrink={true}
                variant="outlined"
              >
                {street.label}
              </InputLabel>
              <InputField
                id={street.name}
                name={street.name}
                fullWidth
                variant="outlined"
                size="small"
                inputProps={{
                  readOnly: true,
                  style: { color: "#757575" },
                }}
              />
              <InputLabel htmlFor={zip.name} shrink={true} variant="outlined">
                {zip.label}
              </InputLabel>
              <InputField
                id={zip.name}
                name={zip.name}
                fullWidth
                variant="outlined"
                size="small"
                inputProps={{
                  readOnly: true,
                  style: { color: "#757575" },
                }}
              />
              <InputLabel htmlFor={city.name} shrink={true} variant="outlined">
                {city.label}
              </InputLabel>
              <InputField
                id={city.name}
                name={city.name}
                fullWidth
                variant="outlined"
                size="small"
                inputProps={{
                  readOnly: true,
                  style: { color: "#757575" },
                }}
              />
              <InputLabel
                htmlFor={country.name}
                shrink={true}
                variant="outlined"
              >
                {country.label}
              </InputLabel>
              <InputField
                id={country.name}
                name={country.name}
                fullWidth
                variant="outlined"
                size="small"
                inputProps={{
                  readOnly: true,
                  style: { color: "#757575" },
                }}
              />
              <InputLabel
                htmlFor={job_function.name}
                shrink={true}
                variant="outlined"
              >
                {job_function.label}
              </InputLabel>
              <SelectField
                id={job_function.name}
                name={job_function.name}
                data={job_functions}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={job_level.name}
                shrink={true}
                variant="outlined"
              >
                {job_level.label}
              </InputLabel>
              <SelectField
                id={job_level.name}
                name={job_level.name}
                data={job_levels}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputLabel
                htmlFor={company_name.name}
                shrink={true}
                variant="outlined"
              >
                {company_name.label}
              </InputLabel>
              <InputField
                id={company_name.name}
                name={company_name.name}
                fullWidth
                variant="outlined"
                size="small"
              />

              <InputLabel
                htmlFor={discipline.name}
                shrink={true}
                variant="outlined"
              >
                {discipline.label}
              </InputLabel>
              <SelectField
                id={discipline.name}
                name={discipline.name}
                data={disciplines}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={industry.name}
                shrink={true}
                variant="outlined"
              >
                {industry.label}
              </InputLabel>
              <SelectField
                id={industry.name}
                name={industry.name}
                data={industries}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Grid>
          </Grid>
        </StyledSectionInner>
      </StyledSectionOuter>
    </Fragment>
  );
});

export default MainForm;
