import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { useTranslation } from "react-i18next";
import { view } from "@risingstack/react-easy-state";

import { auth } from "stores/auth";
import { gdpr } from "stores/gdpr";

import AppbarSupplier from "./supplier/AppbarSupplier";
import AppbarBuyer from "./buyer/AppbarBuyer";

import Footer from "./shared/parts/Footer";
import Login from "./shared/Auth";
import AcceptTerms from "./shared/GDPR/AcceptTerms";
import TermsAndConditions from "./shared/GDPR/TermsAndConditions";
import Privacy from "./shared/GDPR/Privacy";
import Impressum from "./shared/GDPR/Impressum";

import Loader from "./shared/parts/Loader";
import ProtectedRoute from "./shared/parts/ProtectedRoute";
import Header from "./shared/parts/Header";

import "@aws-amplify/ui/dist/style.css";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { darkTheme, GlobalStyle, StyledSpacer, useStyles } from "./styles";
import UserIsAuditor from "./auditor/UserIsAuditor";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

const Wrapper = view(() => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Fragment>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <GlobalStyle />
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          classes={{
            root: classes.root,
          }}
        >
          <Switch>
            <ProtectedRoute path="/accept-terms" exact auth={auth} gdpr={gdpr}>
              <AcceptTerms />
            </ProtectedRoute>
            <Route path="/terms-and-conditions" exact>
              <Header />
              <TermsAndConditions />
            </Route>
            <Route path="/privacy" exact>
              <Header />
              <Privacy />
            </Route>
            <Route path="/impressum" exact>
              <Header />
              <Impressum />
            </Route>
            <Route path="/login/:slot?/:type?" exact>
              <Header />
              <Login />
            </Route>
            <ProtectedRoute path="/" auth={auth} gdpr={gdpr}>
              {!auth.user ? (
                <Fragment>
                  <StyledSpacer />
                  <Loader />
                </Fragment>
              ) : auth.user.signInUserSession.idToken.payload[
                  "cognito:groups"
                ].find((group) => group === "yvoo-supplier") ? (
                <AppbarSupplier />
              ) : auth.user.signInUserSession.idToken.payload[
                  "cognito:groups"
                ].find((group) => group === "yvoo-buyer") ? (
                <AppbarBuyer />
              ) : auth.user.signInUserSession.idToken.payload[
                  "cognito:groups"
                ].find((group) => group === "yvoo-auditor") ? (
                <UserIsAuditor />
              ) : (
                <StyledSpacer>{t("not available")}</StyledSpacer>
              )}

              <Footer />
            </ProtectedRoute>
          </Switch>
        </SnackbarProvider>
      </ThemeProvider>
    </Fragment>
  );
});

export default Wrapper;
