import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { autoEffect, view } from "@risingstack/react-easy-state";

import { auth } from "stores/auth";
import { favorites } from "stores/favorites";

import Loader from "components/shared/parts/Loader";

import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import {
  StyleCostingData,
  StyledMain,
  StyledMainWrapper,
  StyledModalContent,
  StyledSpacer,
  StyledSpecialCell,
  StyledTableContainer,
} from "./styled";
import MainModal from "components/shared/parts/MainModal";
import MessageResponse from "../Chat/MessageResponse";

const AISuppliers = view(() => {
  const [favoritesRows, setFavoritesRows] = useState([]);
  const [costingRows, setCostingRows] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
  const [specificationModalOpen, setSpecificationModalOpen] = useState(false);
  const [rowSpec, setRowSpec] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    favorites.aiFavorites = null;
    favorites.getUserAIFavorites(
      auth.user.signInUserSession.idToken.jwtToken,
      auth.user.attributes.email
    );
  }, []);
  console.log(favorites.aiFavorites);
  const createFavoritesData = (
    id,
    product,
    suppliers,
    productSpecification
  ) => {
    return { id, product, suppliers, productSpecification };
  };

  const createCostingData = (id, product, aiResponse) => {
    return { id, product, aiResponse };
  };

  const handleSpecificationModalOpen = (specification) => {
    setRowSpec(specification);
    setSpecificationModalOpen(true);
  };

  const handleSpecificationModalClose = () => {
    setSpecificationModalOpen(false);
    setRowSpec("");
  };

  autoEffect(() => {
    if (favorites.aiFavorites) {
      setFavoritesRows(
        favorites.aiFavorites
          .filter(
            (favorite) =>
              favorite.suppliers &&
              favorite.suppliers.length > 0 &&
              (!favorite.type || favorite.type === "supplier")
          )
          .map((favorite) => {
            return createFavoritesData(
              favorite.id ? favorite.id : null,
              favorite.product,
              favorite.suppliers,
              favorite.productSpecification
            );
          })
      );
      setCostingRows(
        favorites.aiFavorites
          .filter(
            (favorite) => !favorite.suppliers || favorite.suppliers.length === 0
          )
          .map((favorite) => {
            return createCostingData(
              favorite.id ? favorite.id : null,
              favorite.product,
              favorite.aiResponse
            );
          })
      );
      setIsFetched(true);
    }
  });

  console.log(costingRows);
  return (
    <Fragment>
      {!auth.user ||
      !favorites.aiFavorites ||
      favorites.loading ||
      !favoritesRows ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          <StyledMain>
            <StyledMainWrapper>
              <div
              //  style={{ flex: 1 }}
              >
                <h3>
                  {t("AI Search history")}{" "}
                  <span className={"separator"}>|</span>{" "}
                  <span>{favoritesRows.length}</span>
                </h3>
                <StyledTableContainer>
                  <Table aria-label="favorites table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("Product")}</TableCell>
                        <TableCell>{t("Suppliers")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {favoritesRows.length === 0 && isFetched && (
                        <p>{t("no ai search")}</p>
                      )}
                      {favoritesRows.map((row, index) => (
                        <TableRow
                          key={row.id}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <StyledSpecialCell
                              onClick={() =>
                                handleSpecificationModalOpen(
                                  row.productSpecification
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {row.product}
                            </StyledSpecialCell>
                          </TableCell>
                          <TableCell>
                            {row?.suppliers?.map((supplier) => {
                              return <div>{supplier}</div>;
                            })}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              </div>

              {/* <div style={{ flex: 1 }}>
                <h3>
                  {t("AI Costing history")}{" "}
                  <span className={"separator"}>|</span>{" "}
                  <span>{costingRows.length}</span>
                </h3>
                <StyleCostingData>
                  {costingRows.length === 0 && isFetched && (
                    <p>{t("no ai search")}</p>
                  )}
                  {costingRows.map((row, index) => (
                    <div className="row">
                      <h1>{row.product}</h1>
                      <MessageResponse message={row.aiResponse.split("\n")} />
                    </div>
                  ))}
                </StyleCostingData>
              </div> */}
            </StyledMainWrapper>
          </StyledMain>
        </Fragment>
      )}
      <MainModal
        modalOpen={specificationModalOpen}
        handleModalClose={handleSpecificationModalClose}
      >
        <StyledModalContent>
          <div
            dangerouslySetInnerHTML={{ __html: rowSpec.replace(/\n/g, "<br>") }}
          />
        </StyledModalContent>
      </MainModal>
    </Fragment>
  );
});

export default AISuppliers;
