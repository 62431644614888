import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { view } from "@risingstack/react-easy-state";

import { auth } from "stores/auth";

import Loader from "components/shared/parts/Loader";

import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  StyledAccordion,
  StyledIntro,
  StyledIntroWrapper,
  StyledMain,
  StyledMainWrapper,
  StyledSectionInner,
  StyledSectionOuter,
  StyledSpacer,
} from "./styled";

const FAQ = view(() => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();

  return (
    <Fragment>
      {!auth.user ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"center"}
              >
                <Grid item xs={12}>
                  <h2>{t("yvoo frequently asked questions")}</h2>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
          </StyledIntro>
          <StyledMain>
            <StyledMainWrapper>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"center"}
              >
                <Grid item xs={12}>
                  <StyledSectionOuter>
                    <StyledSectionInner>
                      <h2>{t("benefits of yvoo to suppliers")}</h2>
                      <StyledAccordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <h3>{t("faq question 1")}</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p>{t("faq answer 1")}</p>
                        </AccordionDetails>
                      </StyledAccordion>
                      <StyledAccordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <h3>{t("faq question 2")}</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p>{t("faq answer 2")}</p>
                        </AccordionDetails>
                      </StyledAccordion>
                      <StyledAccordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <h3>{t("faq question 3")}</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p>{t("faq answer 3")}</p>
                        </AccordionDetails>
                      </StyledAccordion>
                    </StyledSectionInner>
                  </StyledSectionOuter>
                </Grid>
              </Grid>
            </StyledMainWrapper>
          </StyledMain>
          <StyledMain>
            <StyledMainWrapper>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"center"}
              >
                <Grid item xs={12}>
                  <StyledSectionOuter>
                    <StyledSectionInner>
                      <h2>{t("how the yvoo self assessment feature works")}</h2>
                      <StyledAccordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1b-content"
                          id="panel1b-header"
                        >
                          <h3>{t("faq question 4")}</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p>{t("faq answer 4")}</p>
                        </AccordionDetails>
                      </StyledAccordion>
                      <StyledAccordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2b-content"
                          id="panel2b-header"
                        >
                          <h3>{t("faq question 5")}</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p>{t("faq answer 5")}</p>
                        </AccordionDetails>
                      </StyledAccordion>
                      <StyledAccordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3b-content"
                          id="panel3b-header"
                        >
                          <h3>{t("faq question 6")}</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p>{t("faq answer 6")}</p>
                        </AccordionDetails>
                      </StyledAccordion>
                    </StyledSectionInner>
                  </StyledSectionOuter>
                </Grid>
              </Grid>
            </StyledMainWrapper>
          </StyledMain>
          <StyledMain>
            <StyledMainWrapper>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"center"}
              >
                <Grid item xs={12}>
                  <StyledSectionOuter>
                    <StyledSectionInner>
                      <h2>{t("data storage and access to yvoo")}</h2>
                      <StyledAccordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1c-content"
                          id="panel1c-header"
                        >
                          <h3>{t("faq question 7")}</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p>{t("faq answer 7")}</p>
                        </AccordionDetails>
                      </StyledAccordion>
                      <StyledAccordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2c-content"
                          id="panel2c-header"
                        >
                          <h3>{t("faq question 8")}</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p>{t("faq answer 8")}</p>
                        </AccordionDetails>
                      </StyledAccordion>
                      <StyledAccordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3c-content"
                          id="panel3c-header"
                        >
                          <h3>{t("faq question 9")}</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p>{t("faq answer 9")}</p>
                        </AccordionDetails>
                      </StyledAccordion>
                    </StyledSectionInner>
                  </StyledSectionOuter>
                </Grid>
              </Grid>
            </StyledMainWrapper>
          </StyledMain>
        </Fragment>
      )}
    </Fragment>
  );
});

export default FAQ;
