import { Container, Box, Button } from "@material-ui/core";

import styled from "styled-components";

export const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;
  padding: 10px 40px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

export const StyledButtonWithIcon = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

export const StyledPlusSign = styled(Box)`
  height: 100%;
  width: 46px;
  background: #00bfe9;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #ffffff;
  }
`;

export const StyledRemoveWrapper = styled(Box)`
  height: 100%;
  width: 100%;
`;

export const StyledMinusSign = styled(Box)`
  height: 40px;
  width: 40px;
  background: #00bfe9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    color: #ffffff;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const StyledButtonText = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
`;

export const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;
  padding-left: 24px;
  padding-right: 24px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 24px;
  }

  h4 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-weight: 500;
    font-size: 21px;
    line-height: 27px;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
  }
`;

export const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  // margin-bottom: 60px;
`;

export const StyledMain = styled(Box)`
  width: 100%;
  position: relative;
  z-index: 2;
  padding-left: 24px;
  padding-right: 24px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
  }

  h4 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-weight: 500;
    font-size: 21px;
    line-height: 27px;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
    margin-bottom: 20px;
  }
`;

export const StyledMainWrapper = styled(Container)`
  width: 100%;
  position: relative;
  z-index: 2;
  padding-bottom: 60px;
  padding-top: 60px;

  h3 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-weight: normal;
    font-size: 21px;
    line-height: 24px;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
    margin-bottom: 36px;

    span {
      color: #808285;

      &.separator {
        color: #cfcfcf;
      }
    }
  }
`;

export const StyledFormSection = styled(Box)`
  background: #f3f9fa;
  padding: 40px;
  margin-bottom: 44px;

  .MuiInputLabel-root {
    font-family: "RobotoRegular", "Arial", sans-serif;
    transform: none;
    display: block;
    font-size: 14px;
    line-height: 93.1%;
    color: #000000;
    margin-bottom: 9px;
  }

  .MuiFormLabel-asterisk {
    color: #ff490f;
  }

  .MuiDropzoneArea-root {
    height: 133px;
    border-radius: 0;
    min-height: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 29px;

    p {
      margin: 0;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 120.1%;
      text-align: center;
      color: #b0b0b0;
    }
  }

  .MuiDropzonePreviewList-root {
    display: flex;
    justify-content: center;
  }

  .MuiSnackbar-root {
    right: 24px;
    left: auto;
  }

  .MuiDropzoneArea-text {
    display: none;
  }

  .MuiDropzonePreviewList-image {
    height: 50px;
  }

  h4 {
    margin-bottom: 10px;
  }

  p {
    color: #00c9e9;
    margin: 0;
    margin-bottom: 10px;
  }
`;

export const StyledNotPaidBox = styled.div`
  padding: 16px 0 32px;
  p  {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0;
    margin-bottom: 16px;
    text-transform: none;
  }

  a {
    color: #1890ff;
  }
`;
