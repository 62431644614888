import { store } from "@risingstack/react-easy-state";

import { auth } from "./auth";

import { API_ENDPOINT } from "../consts/globals";

import { authFetch } from "functions";

const abortController = new AbortController();
const signal = abortController.signal;

export const selfAssessment = store({
  selfAssessment: null,
  loading: false,
  async getSelfAssessment() {
    selfAssessment.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/steps-assessment`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      selfAssessment.selfAssessment = responseJson;
      selfAssessment.loading = false;
    } catch (error) {
      selfAssessment.loading = false;
      console.log(error);
    }
  },
});
