import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { view } from "@risingstack/react-easy-state";

import { auth } from "stores/auth";
import { customSearch } from "stores/customSearch";

import Loader from "components/shared/parts/Loader";
import CustomSearchResult from "./CustomSearchResult";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";

import {
  StyledIntro,
  StyledIntroWrapper,
  StyledResultsWrapper,
  StyledSpacer,
} from "./styled";

const CustomSearchResults = view(() => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  let params = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (params.id) {
      customSearch.getCustomSearchResults(params.id);
    }
  }, [params.id]);

  return (
    <Fragment>
      {!auth.user || !customSearch.result || customSearch.loading ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : !auth.user ||
        !customSearch.result ||
        !customSearch.result[0] ||
        !customSearch.result[0].customSearch ||
        (customSearch.result[0] &&
          customSearch.result[0].customSearch.user_uuid !==
            auth.user.attributes.sub) ? (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"flex-start"}
              >
                <Grid item xs={12} lg={6}>
                  <h3>{t("not available")}</h3>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
          </StyledIntro>
        </Fragment>
      ) : (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"flex-start"}
              >
                <Grid item xs={12} lg={6}>
                  <h2>{t("custom search results")}</h2>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
            {!customSearch.result || customSearch.result.length < 1 ? null : (
              <StyledResultsWrapper>
                <Grid container justify="flex-start" alignItems={"flex-start"}>
                  {customSearch.result.map((result) => {
                    return (
                      <CustomSearchResult
                        result={result.company}
                        term={params.input}
                        key={result.company.id}
                      />
                    );
                  })}
                </Grid>
              </StyledResultsWrapper>
            )}
          </StyledIntro>
        </Fragment>
      )}
    </Fragment>
  );
});

export default CustomSearchResults;
