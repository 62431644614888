import React from "react";
import { format } from "date-fns";
import "./styles.css";

const Response = ({ message }) => {
  const date = new Date();
  return (
    <div
      style={{
        backgroundColor: "#F5F5F5",
        padding: "0px 20px",
        borderRadius: "10px",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "16px 0",
          position: "relative",
        }}
      >
        {message &&
          message.map((line, index) => {
            return (
              <React.Fragment key={index}>
                <p
                  style={{
                    color: "#20303C",
                    fontSize: "13px",
                    margin: 0,
                    whiteSpace: "preline",
                    wordBreak: "break-word",
                  }}
                >
                  {line}
                </p>
              </React.Fragment>
            );
          })}

        <p
          style={{
            color: "#ABB7B4",
            fontSize: "14px",
            position: "absolute",
            bottom: 0,
            right: 0,
            margin: 0,
            paddingBottom: "4px",
          }}
        >
          {date && format(date, "HH:mm")}
        </p>
      </div>
    </div>
  );
};

export default Response;
