import { store } from "@risingstack/react-easy-state";

import { auth } from "./auth";
import { company } from "./company";

import { API_ENDPOINT } from "../consts/globals";

import { authFetch } from "functions";

const abortController = new AbortController();
const signal = abortController.signal;

export const customSearch = store({
  response: null,
  loading: false,
  result: null,
  async postCustomSearch(data) {
    customSearch.loading = true;
    if (!company.company) {
      await company.getCompany();
    }

    try {
      const response = await authFetch(`${API_ENDPOINT}/system/custom-search`, {
        signal: signal,
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });
      const responseJson = await response.json();

      customSearch.response = responseJson;
      customSearch.loading = false;
    } catch (error) {
      customSearch.loading = false;
      console.log(error);
    }
  },
  async getCustomSearches() {
    customSearch.loading = true;

    // if (!company.company) {
    //   await company.getCompany();
    // }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/user-custom-searches`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const responseJson = await response.json();

      customSearch.list = responseJson;
      customSearch.loading = false;
    } catch (error) {
      customSearch.loading = false;
      console.log(error);
    }
  },
  async getCustomSearchResults(id) {
    customSearch.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/custom-search-result/${id}?include=company,customSearch`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      customSearch.result = responseJson.data;
      customSearch.loading = false;
      return responseJson.data;
    } catch (error) {
      console.log(error);
      customSearch.loading = false;
    }
  },
});
