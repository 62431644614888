import styled from "styled-components";

import { Field } from "formik";
import { Container, Box, Button } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

export const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

export const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 40px;
  }

  h3 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin: 0;

    span {
      font-weight: 700;
    }
  }

  p {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    margin-bottom: 40px;
  }
`;

export const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  padding-bottom: 40px;
`;

export const StyledMain = styled(Box)`
  position: relative;
  z-index: 2;
`;

export const StyledMainWrapper = styled(Container)`
  position: relative;
  z-index: 2;
`;

export const StyledSectionOuter = styled(Box)`
  border-left: 2px solid #01e7aa;
  padding-left: 11px;
  margin-bottom: 60px;
`;

export const StyledSectionInner = styled(Box)`
  background: #f3f9fa;
  padding: 40px;

  .MuiInputLabel-root {
    font-family: "RobotoRegular", "Arial", sans-serif;
    transform: none;
    display: block;
    font-size: 14px;
    line-height: 93.1%;
    color: #000000;
    margin-bottom: 9px;
    position: relative;
  }

  .MuiFormLabel-asterisk {
    color: #ff490f;
  }

  .MuiDropzoneArea-root {
    height: 133px;
    border-radius: 0;
    min-height: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 29px;

    p {
      margin: 0;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 120.1%;
      text-align: center;
      color: #b0b0b0;
    }
  }

  .MuiDropzonePreviewList-root {
    display: flex;
    justify-content: center;
  }

  .MuiSnackbar-root {
    right: 24px;
    left: auto;
  }

  .MuiDropzoneArea-text {
    display: none;
  }

  .MuiDropzonePreviewList-image {
    height: 50px;
  }
`;

export const StyledField = styled(Field)`
  background: #ffffff;
  margin-bottom: 40px;

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
  }

  .MuiInputBase-input {
    background: #ffffff;
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin: 0;
    bottom: -24px;
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  font-family: "RobotoRegular", "Arial", sans-serif;
  transform: none !important;
  display: block;
  font-size: 14px;
  line-height: 93.1%;
  color: #000000;
  margin-bottom: 20px;
`;

export const StyledSelect = styled(FormControl)`
  margin-bottom: 30px;

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
  }

  .MuiInputBase-input {
    background: #ffffff;
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin: 0;
    bottom: -24px;
  }
`;

export const StyledButtonPrimary = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;
  padding: 10px 40px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

export const menuItemStyle = {
  paddingLeft: "25px",
};
