import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Switch, Route, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { view } from "@risingstack/react-easy-state";

import { auth } from "stores/auth";
import { gdpr } from "stores/gdpr";
import { company } from "stores/company";
import { searchFilters } from "stores/searchFilters";
import { language } from "stores/language";

import SearchInputWithFilter from "./parts/SearchInputWithFilter";

import ProtectedRoute from "components/shared/parts/ProtectedRoute";
import Loader from "components/shared/parts/Loader";

import Login from "components/shared/Auth";
import AcceptTerms from "components/shared/GDPR/AcceptTerms";
import TermsAndConditions from "components/shared/GDPR/TermsAndConditions";
import Privacy from "components/shared/GDPR/Privacy";
import Impressum from "components/shared/GDPR/Impressum";
import Company from "components/shared/Company";
import SpecificReport from "components/shared/SpecificReport";
import UserProfile from "components/shared/UserProfile";
import Help from "components/shared/Help";
import FAQ from "components/shared/FAQ";

import MyQuickscans from "./MyQuickscans";
import MySuppliers from "./MySuppliers";
import AdminQuickscanReport from "./AdminQuickscanReport";
import Dashboard from "./Dashboard";
import RFI from "./RFI";
import CustomSearchResults from "./CustomSearchResults";
import Search from "./Search";
import SearchResults from "./SearchResults";
import CustomSearch from "./CustomSearch";

import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import plusSign from "assets/img/plusIcon.svg";
import minusSign from "assets/img/minusIcon.svg";
import logo from "assets/img/logo.svg";
import logoBlack from "assets/img/logoBlack.svg";
import profileIcon from "assets/img/profileIcon.svg";
import logoutIcon from "assets/img/logoutIcon.svg";
import infoIcon from "assets/img/infoIcon.svg";
import infoIconWhite from "assets/img/infoIconWhite.svg";
import filterIcon from "assets/img/filterIcon.svg";
import filterIconWhite from "assets/img/filterIconWhite.svg";
import chatIcon from "assets/img/chatIcon.svg";
import chatIconWhite from "assets/img/chatIconWhite.svg";
import closeIcon from "assets/img/closeIcon.svg";
import openDrawerIconWhite from "assets/img/openDrawerIconWhite.svg";
import openDrawerIcon from "assets/img/openDrawerIcon.svg";

import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccrdionDetails,
  StyledAppbar,
  StyledBottomButton,
  StyledButtonPrimary,
  StyledCloseButton,
  StyledDrawer,
  StyledExpandButton,
  StyledFilterWrapper,
  StyledHelpBorder,
  StyledHelpButtons,
  StyledHelpDrawer,
  StyledLink,
  StyledList,
  StyledMainWrapper,
  StyledMiddleButton,
  StyledRatingInfoWrapper,
  StyledRatingWrapper,
  StyledSidebarWrapper,
  StyledSidebarWrapperChat,
  StyledSpacer,
  StyledToolbar,
  StyledToolbarButton,
  StyledToolbarWrapper,
  StyledTopBar,
  StyledTopBarFooter,
  StyledTopButton,
  useStyles,
  useStylesRating,
} from "./AppbarBuyer.styled";
import ChatAI from "./Chat";
import AISuppliers from "./AISupliers";
import CustomSearches from "./CustomSearches";

function ListItemLink(props) {
  const { primary, to, id, pathname } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <Link
          to={to}
          ref={ref}
          {...itemProps}
          type={to === pathname ? "active" : null}
        />
      )),
    [to, pathname]
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        <ListItemText primary={primary} id={id} />
      </ListItem>
    </li>
  );
}

const logout = async () => {
  await auth.logout();
  company.company = null;
  // history.push("/login");
};

const AppbarBuyer = view((props) => {
  const classes = useStyles();
  const classesRating = useStylesRating();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = useState(true);
  const [openSideBar, setOpenSidebar] = useState(false);
  const [expandSidebar, setExpandSidebar] = useState(false);
  const [openTopBar, setOpenTopBar] = useState(false);
  const { pathname } = props.location;
  const [sidebarContent, setSidebarContent] = useState(null);
  const [topBarContent, setTopBarContent] = useState(false);
  const [filterTurnoverExpanded, setFilterTurnoverExpanded] = useState(false);
  const [filterISOExpanded, setFilterISOExpanded] = useState(false);
  const [filterQuickscanExpanded, setFilterQuickscanExpanded] = useState(false);
  const [filterQuickscanRatingExpanded, setFilterQuickscanRatingExpanded] =
    useState(false);
  const [filters, setFilters] = useState({
    turnover5: false,
    turnover20: false,
    turnover100: false,
    turnover500: false,
    turnover1B: false,
    turnover10B: false,
    iso9001_2015: false,
    iso14001_2015: false,
    ohsas18001: false,
    quickscan: false,
    quickscan_rating: false,
  });
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickLanguage = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseLanguage = () => {
    setAnchorEl(null);
  };

  const handlePickLanguage = (lang) => {
    language.setLanguage(lang);
    setAnchorEl(null);
  };

  const handleFilterChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
    searchFilters.filters = {
      ...filters,
      [event.target.name]: event.target.checked,
    };
  };
  const {
    turnover5,
    turnover20,
    turnover100,
    turnover500,
    turnover1B,
    turnover10B,
    iso9001_2015,
    iso14001_2015,
    ohsas18001,
    quickscan,
    quickscan_rating,
  } = filters;
  // const [openMultilevel, setOpenMultilevel] = useState(false);

  // autoEffect(() => {
  //   if (!selfAssessment.selfAssessment) {
  //     selfAssessment.getSelfAssessment();
  //   }
  // }, [selfAssessment.selfAssessment]);

  useEffect(() => {
    if (pathname === "/") {
      setOpenTopBar(true);
    } else {
      setOpenTopBar(false);
    }

    // setFilters({
    //   turnover5: false,
    //   turnover20: false,
    //   turnover100: false,
    //   turnover500: false,
    //   turnover1B: false,
    //   turnover10B: false,
    //   iso9001_2015: false,
    //   iso14001_2015: false,
    //   ohsas18001: false,
    //   quickscan: false,
    //   quickscan_rating: false,
    // });
    setOpenSidebar(false);
    setFilters({
      turnover5: false,
      turnover20: false,
      turnover100: false,
      turnover500: false,
      turnover1B: false,
      turnover10B: false,
      iso9001_2015: false,
      iso14001_2015: false,
      ohsas18001: false,
      quickscan: false,
      quickscan_rating: false,
    });
    searchFilters.filters = null;
  }, [pathname]);

  useEffect(() => {
    if (matches) {
      handleDrawerOpen();
    } else {
      handleDrawerClose();
    }

    if (pathname === "/search") {
      handleDrawerClose();
    }

    if (
      pathname.includes("/search-results") ||
      pathname.includes("/custom-search-results")
    ) {
      handleDrawerClose();
      setOpenTopBar(true);
      setSidebarContent("filter");
      setOpenSidebar(true);
    }

    if (pathname.includes("/custom-search")) {
      handleDrawerClose();
      setOpenTopBar(true);
      // setSidebarContent("info");
      // setOpenSidebar(true);
    }
  }, [matches, pathname]);

  const handleTurnoverAccordionChange = (panel) => (event, isExpanded) => {
    setFilterTurnoverExpanded(isExpanded ? panel : false);
  };

  const handleISOAccordionChange = (panel) => (event, isExpanded) => {
    setFilterISOExpanded(isExpanded ? panel : false);
  };

  const handleQuickscanAccordionChange = (panel) => (event, isExpanded) => {
    setFilterQuickscanExpanded(isExpanded ? panel : false);
  };

  const handleQuickscanRatingAccordionChange =
    (panel) => (event, isExpanded) => {
      setFilterQuickscanRatingExpanded(isExpanded ? panel : false);
    };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleTopBar = () => {
    setOpenTopBar(!openTopBar);
  };

  // const handleDrawerOpenHelp = () => {
  //   setOpenSidebar(true);
  // };

  const handleDrawerCloseHelp = () => {
    setOpenSidebar(false);
    setSidebarContent(null);
  };

  // const handleMultilevel = () => {
  //   setOpenMultilevel(!openMultilevel);
  // };

  const handleSidebarContent = (content) => {
    setSidebarContent(content);
    setOpenSidebar(true);
  };

  const handleDrawerExpandHelp = () => {
    setExpandSidebar((prev) => !prev);
  };

  const renderSidebarContent = (path) => {
    switch (path) {
      case "/self-assessment":
        return (
          <StyledSidebarWrapper>
            <div>
              <h3>tutorial</h3>
              <p>
                If you want to get information how to use YVOO please have a
                look at our Tutorial. The tutorial explains the benefits of the
                platform and gives you an introduction how to run the self
                assessment.
              </p>
              <StyledButtonPrimary>
                <PlayCircleFilledIcon />
                video
              </StyledButtonPrimary>
            </div>
          </StyledSidebarWrapper>
        );
      default:
        return (
          <StyledSidebarWrapper>
            {/* {t("no data")} */}
            <img src={logoBlack} alt="yvoo" />
          </StyledSidebarWrapper>
        );
    }
  };

  const renderFilterContent = () => {
    return (
      <StyledFilterWrapper>
        <h3>{t("filter")}</h3>
        <StyledAccordion
          expanded={filterTurnoverExpanded}
          onChange={handleTurnoverAccordionChange("turnover")}
        >
          <StyledAccordionSummary
            expandIcon={
              filterTurnoverExpanded ? (
                <img src={minusSign} alt="expand" />
              ) : (
                <img src={plusSign} alt="expand" />
              )
            }
            aria-controls="turnover-content"
            id="turnover-header"
          >
            <h2>{t("turnover")}</h2>
          </StyledAccordionSummary>
          <StyledAccrdionDetails>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={turnover5}
                      onChange={handleFilterChange}
                      name="turnover5"
                    />
                  }
                  label={t("<5M")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={turnover20}
                      onChange={handleFilterChange}
                      name="turnover20"
                    />
                  }
                  label={t("<20M")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={turnover100}
                      onChange={handleFilterChange}
                      name="turnover100"
                    />
                  }
                  label={t("<100M")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={turnover500}
                      onChange={handleFilterChange}
                      name="turnover500"
                    />
                  }
                  label={t("<500M")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={turnover1B}
                      onChange={handleFilterChange}
                      name="turnover1B"
                    />
                  }
                  label={t("<1B")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={turnover10B}
                      onChange={handleFilterChange}
                      name="turnover10B"
                    />
                  }
                  label={t("<10B")}
                />
              </FormGroup>
            </FormControl>
          </StyledAccrdionDetails>
        </StyledAccordion>
        <StyledAccordion
          expanded={filterISOExpanded}
          onChange={handleISOAccordionChange("iso")}
        >
          <StyledAccordionSummary
            expandIcon={
              filterISOExpanded ? (
                <img src={minusSign} alt="expand" disableRipple />
              ) : (
                <img src={plusSign} alt="expand" />
              )
            }
            aria-controls="iso-content"
            id="iso-header"
          >
            <h2>{t("certificate")}</h2>
          </StyledAccordionSummary>
          <StyledAccrdionDetails>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={iso9001_2015}
                      onChange={handleFilterChange}
                      name="iso9001_2015"
                    />
                  }
                  label={t("iso 9001-2015")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={iso14001_2015}
                      onChange={handleFilterChange}
                      name="iso14001_2015"
                    />
                  }
                  label={t("iso 14001-2015")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ohsas18001}
                      onChange={handleFilterChange}
                      name="ohsas18001"
                    />
                  }
                  label={t("ohsas 18001")}
                />
              </FormGroup>
            </FormControl>
          </StyledAccrdionDetails>
        </StyledAccordion>
        <StyledAccordion
          expanded={filterQuickscanExpanded}
          onChange={handleQuickscanAccordionChange("quickscan")}
        >
          <StyledAccordionSummary
            expandIcon={
              filterQuickscanExpanded ? (
                <img src={minusSign} alt="expand" />
              ) : (
                <img src={plusSign} alt="expand" />
              )
            }
            aria-controls="quickscan-content"
            id="quickscan-header"
          >
            <h2>{t("quickscan")}</h2>
          </StyledAccordionSummary>
          <StyledAccrdionDetails>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={quickscan}
                      onChange={handleFilterChange}
                      name="quickscan"
                    />
                  }
                  label={t("quickscan available")}
                />
              </FormGroup>
            </FormControl>
          </StyledAccrdionDetails>
        </StyledAccordion>
        <StyledAccordion
          expanded={filterQuickscanRatingExpanded}
          onChange={handleQuickscanRatingAccordionChange("quickscan-rating")}
        >
          <StyledAccordionSummary
            expandIcon={
              filterQuickscanRatingExpanded ? (
                <img src={minusSign} alt="expand" />
              ) : (
                <img src={plusSign} alt="expand" />
              )
            }
            aria-controls="quickscan-rating-content"
            id="quickscan-rating-header"
          >
            <h2>{t("quickscan rating")}</h2>
          </StyledAccordionSummary>
          <StyledAccrdionDetails>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={quickscan_rating}
                      onChange={handleFilterChange}
                      name="quickscan_rating"
                    />
                  }
                  label={t("quickscan score > 3")}
                />
              </FormGroup>
            </FormControl>
          </StyledAccrdionDetails>
        </StyledAccordion>
      </StyledFilterWrapper>
    );
  };

  const renderTopBarContent = () => {
    // const result = await timer();
    let pfp = ``;
    if (auth.user.dbData.pfp_url) {
      pfp = `${process.env.REACT_APP_S3_ENDPOINT}/${auth.user.dbData.pfp_url}`;
    }
    const userAttributes = {
      name: auth.user.dbData.name,
      profile_photo: pfp,
      mobile_phone: auth.user.dbData.mobile_phone,
      country: auth.user.dbData.country,
      address: auth.user.dbData.address,
      city: auth.user.dbData.city,
      zip_code: auth.user.dbData.zip,
      job_function: auth.user.dbData.job_function,
      job_level: auth.user.dbData.job_level,
      discipline: auth.user.dbData.discipline,
      industry: auth.user.dbData.industry,
      company_name: auth.user.dbData.company_name,
    };

    const attributeCount = Object.values(userAttributes).filter((value) => {
      return value;
    }).length;

    const user = {
      overall_completenes: Math.round((attributeCount / 12) * 100),
    };

    const isSearch =
      pathname.includes("/search-results") ||
      pathname.includes("/custom-search-results") ||
      pathname.includes("/custom-search");

    if (isSearch) {
      return topBarContent ? (
        <Container style={{ height: "100%" }}>
          <Grid container style={{ height: "100%" }} alignItems="center">
            <Grid item xs={12}>
              <SearchInputWithFilter />
            </Grid>
          </Grid>
        </Container>
      ) : null;
    } else {
      return topBarContent ? (
        <Container style={{ height: "100%" }}>
          <Grid container style={{ height: "100%" }} alignItems="center">
            <Grid item xs={12} lg={5}>
              <StyledRatingWrapper>
                <div className={classesRating.root}>
                  <CircularProgress
                    variant="determinate"
                    className={classesRating.bottom}
                    size={109}
                    thickness={1}
                    {...props}
                    value={100}
                  />
                  <CircularProgress
                    variant="static"
                    className={clsx(classesRating.top, {
                      [classesRating.red]: user.overall_completenes < 20,
                      [classesRating.orange]:
                        user.overall_completenes >= 20 &&
                        user.overall_completenes < 40,
                      [classesRating.yellow]:
                        user.overall_completenes >= 40 &&
                        user.overall_completenes < 60,
                      [classesRating.blue]:
                        user.overall_completenes >= 60 &&
                        user.overall_completenes < 80,
                      [classesRating.green]: user.overall_completenes >= 80,
                    })}
                    classes={{
                      circle: classesRating.circle,
                    }}
                    size={117}
                    thickness={4}
                    value={user ? user.overall_completenes : 0}
                    {...props}
                  />
                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <h3>
                      {user ? user.overall_completenes : 0}
                      <span> %</span>
                    </h3>
                  </Box>
                </div>
                <StyledRatingInfoWrapper>
                  <h4>{t("completed user profile")}</h4>
                  {/* <p>
                    {t(
                      "what would be the chances to be qualified by the leading customers in the industry? The rating depends on completeness and quality of information provided by you during quick-scan"
                    )}
                    .
                  </p> */}
                </StyledRatingInfoWrapper>
              </StyledRatingWrapper>
            </Grid>
          </Grid>
        </Container>
      ) : null;
    }
  };

  useEffect(() => {
    if (openTopBar) {
      const timer = setTimeout(() => {
        setTopBarContent(true);
      }, 225);

      return () => clearTimeout(timer);
    } else {
      setTopBarContent(false);
    }
  }, [openTopBar]);

  // autoEffect(() => {
  //   if (!countries.countries) {
  //     countries.getCountries();
  //   }
  // });

  return (
    <Fragment>
      {!auth.user || !auth.user.dbData ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          <div
            style={{
              display: "flex",
              minHeight: "100vh",
            }}
          >
            <StyledAppbar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <StyledToolbar
                className={clsx({ [classes.toolbarClosed]: !open })}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, open && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
                <Link to={`/`}>
                  <img src={logo} alt="yvoo logo" />
                </Link>
                <div className={classes.grow} />
                <StyledToolbarWrapper>
                  <StyledLink to="/profile">
                    <StyledToolbarButton>
                      <img src={profileIcon} alt="profile icon" />
                      <span>{auth.user.dbData.name}</span>
                    </StyledToolbarButton>
                  </StyledLink>
                  <StyledToolbarButton
                    aria-controls="language-menu"
                    aria-haspopup="true"
                    onClick={handleClickLanguage}
                  >
                    <span>{language.language.toUpperCase()}</span>
                  </StyledToolbarButton>
                  <Menu
                    id="language-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseLanguage}
                  >
                    {language.languages.map((lang) => {
                      if (lang !== language.language) {
                        return (
                          <MenuItem onClick={() => handlePickLanguage(lang)}>
                            {lang.toUpperCase()}
                          </MenuItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Menu>
                  <StyledToolbarButton onClick={logout}>
                    <img src={logoutIcon} alt="logout icon" />
                    <span>{t("logout")}</span>
                  </StyledToolbarButton>
                </StyledToolbarWrapper>
              </StyledToolbar>
            </StyledAppbar>

            <StyledDrawer
              className={classes.drawer}
              variant="persistent"
              anchor="left"
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                  <MenuIcon />
                </IconButton>
              </div>
              <StyledList>
                <ListItemLink
                  to="/"
                  primary={t("dashboard")}
                  pathname={pathname}
                />
                <ListItemLink
                  to="/search"
                  primary={t("search")}
                  pathname={pathname}
                />
                <ListItemLink
                  to="/my-suppliers"
                  primary={t("my suppliers")}
                  pathname={pathname}
                />
                <ListItemLink
                  to="/custom-searches"
                  primary={t("custom searches")}
                  pathname={pathname}
                />

                <ListItemLink
                  to="/ai-suppliers"
                  primary={t("History AI search")}
                  pathname={pathname}
                />
                <ListItemLink
                  to="/my-quickscans"
                  primary={t("my quick-scans")}
                  pathname={pathname}
                />
                <ListItemLink
                  to="/faq"
                  primary={t("faq")}
                  pathname={pathname}
                />
                <ListItemLink
                  to="/help"
                  primary={t("help")}
                  pathname={pathname}
                />
              </StyledList>
            </StyledDrawer>
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: open,
              })}
            >
              {auth.user && auth.user.dbData ? (
                <Fragment>
                  <StyledTopBar openTopBar={openTopBar} matches={matches}>
                    {renderTopBarContent()}
                  </StyledTopBar>
                  <StyledTopBarFooter
                    openTopBar={openTopBar}
                    onClick={toggleTopBar}
                  >
                    <img src={openDrawerIconWhite} alt="open drawer icon" />
                  </StyledTopBarFooter>
                </Fragment>
              ) : null}
              <StyledMainWrapper pathname={pathname}>
                <Switch>
                  {/* <Route path="/" exact component={Home} props={auth} /> */}
                  <ProtectedRoute path="/" exact auth={auth} gdpr={gdpr}>
                    <Dashboard />
                  </ProtectedRoute>
                  <ProtectedRoute path="/profile" exact auth={auth} gdpr={gdpr}>
                    <UserProfile />
                  </ProtectedRoute>
                  <ProtectedRoute path="/search" exact auth={auth} gdpr={gdpr}>
                    <Search />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/search-results/:input/region/:region/page/:page"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <SearchResults />
                  </ProtectedRoute>
                  <Route path="/custom-search/:input" exact>
                    <CustomSearch
                      setOpenSideBar={setOpenSidebar}
                      setSidebarContent={setSidebarContent}
                      openSideBar={openSideBar}
                    />
                  </Route>
                  <ProtectedRoute
                    path="/my-suppliers"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <MySuppliers />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/custom-searches"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <CustomSearches />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/ai-suppliers"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <AISuppliers />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/my-quickscans"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <MyQuickscans />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/companies/:id"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <Company />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/reports/:id"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <SpecificReport />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/admin-quickscan-report/:id"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <AdminQuickscanReport />
                  </ProtectedRoute>
                  <ProtectedRoute path="/rfq/:id" exact auth={auth} gdpr={gdpr}>
                    <RFI />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/custom-search-results/:id"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <CustomSearchResults />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/accept-terms"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <AcceptTerms />
                  </ProtectedRoute>
                  <ProtectedRoute path="/help" exact auth={auth} gdpr={gdpr}>
                    <Help />
                  </ProtectedRoute>
                  <ProtectedRoute path="/faq" exact auth={auth} gdpr={gdpr}>
                    <FAQ />
                  </ProtectedRoute>

                  <Route path="/terms-and-conditions" exact>
                    <TermsAndConditions />
                  </Route>
                  <Route path="/privacy" exact>
                    <Privacy />
                  </Route>
                  <Route path="/impressum" exact>
                    <Impressum />
                  </Route>
                  <Route path="/login" exact>
                    <Login />
                  </Route>
                </Switch>
              </StyledMainWrapper>
              <StyledHelpBorder open={openSideBar} id="helpBorder">
                {pathname.includes("/search-results") ||
                pathname.includes("/custom-search-results") ? (
                  <StyledHelpButtons>
                    <StyledTopButton
                      onClick={() => handleSidebarContent("filter")}
                      className={sidebarContent === "filter" ? "active" : null}
                      disableRipple={true}
                      disableFocusRipple={true}
                    >
                      {sidebarContent === "filter" ? (
                        <img src={filterIconWhite} alt="info icon white" />
                      ) : (
                        <img src={filterIcon} alt="info icon" />
                      )}
                    </StyledTopButton>
                    <StyledMiddleButton
                      onClick={() => handleSidebarContent("info")}
                      className={sidebarContent === "info" ? "active" : null}
                      disableRipple={true}
                      disableFocusRipple={true}
                    >
                      {sidebarContent === "info" ? (
                        <img src={infoIconWhite} alt="info icon white" />
                      ) : (
                        <img src={infoIcon} alt="info icon" />
                      )}
                    </StyledMiddleButton>
                    <StyledBottomButton
                      onClick={() => handleSidebarContent("chat")}
                      className={sidebarContent === "chat" ? "active" : null}
                      disableRipple={true}
                      disableFocusRipple={true}
                    >
                      {sidebarContent === "chat" ? (
                        <img src={chatIconWhite} alt="chat icon white" />
                      ) : (
                        <img src={chatIcon} alt="chat icon" />
                      )}
                    </StyledBottomButton>
                  </StyledHelpButtons>
                ) : (
                  <StyledHelpButtons>
                    <StyledTopButton
                      onClick={() => handleSidebarContent("info")}
                      className={sidebarContent === "info" ? "active" : null}
                      disableRipple={true}
                      disableFocusRipple={true}
                    >
                      {sidebarContent === "info" ? (
                        <img src={infoIconWhite} alt="info icon white" />
                      ) : (
                        <img src={infoIcon} alt="info icon" />
                      )}
                    </StyledTopButton>
                    <StyledBottomButton
                      onClick={() => handleSidebarContent("chat")}
                      className={sidebarContent === "chat" ? "active" : null}
                      disableRipple={true}
                      disableFocusRipple={true}
                    >
                      {sidebarContent === "chat" ? (
                        <img src={chatIconWhite} alt="chat icon white" />
                      ) : (
                        <img src={chatIcon} alt="chat icon" />
                      )}
                    </StyledBottomButton>
                  </StyledHelpButtons>
                )}
              </StyledHelpBorder>
            </main>
          </div>
          <StyledHelpDrawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={openSideBar}
            expand={expandSidebar}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {sidebarContent === "info" ? (
              renderSidebarContent(pathname)
            ) : sidebarContent === "chat" ? (
              <StyledSidebarWrapperChat>
                {/* {t("chat placeholder")} */}
                <ChatAI
                  userEmail={auth.user.attributes.email}
                  userName={auth.user.dbData.name}
                />
              </StyledSidebarWrapperChat>
            ) : sidebarContent === "filter" ? (
              renderFilterContent()
            ) : null}

            <StyledExpandButton
              onClick={handleDrawerExpandHelp}
              expand={expandSidebar}
            >
              <img src={openDrawerIcon} alt="expand icon" />
            </StyledExpandButton>

            <StyledCloseButton onClick={handleDrawerCloseHelp}>
              <img src={closeIcon} alt="close icon" />
            </StyledCloseButton>
          </StyledHelpDrawer>
        </Fragment>
      )}
    </Fragment>
  );
});

export default withRouter(AppbarBuyer);
