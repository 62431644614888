import React, { useEffect } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { view, autoEffect } from "@risingstack/react-easy-state";

import { location } from "stores/location";

import CircularProgress from "@material-ui/core/CircularProgress";

const ProfileRoute = view(({ children, auth, gdpr, ...rest }) => {
  let history = useHistory();
  let loc = useLocation();

  autoEffect(() => {
    location.prevLocation = loc.pathname;

    if (!auth.isLoading && !auth.isLoggedIn) {
      history.push("/login");
    }

    if (!auth.isLoading && auth.isLoggedIn && gdpr.terms && !gdpr.loading) {
      redirect();
    }
  });

  const redirect = () => {
    if (gdpr.terms) {
      const legalCheck = gdpr.terms.filter((term) => {
        return term.required && !term.accepted;
      });

      if (legalCheck.length === 0) {
        if (history.location.pathname === "/accept-terms") {
          if (
            auth.user &&
            auth.user.signInUserSession.idToken.payload["cognito:groups"].find(
              (group) => group === "yvoo-buyer"
            )
          ) {
            history.push("/search");
          } else {
            history.push("/");
          }
        } else return null;
      } else {
        if (history.location.pathname !== "/accept-terms") {
          history.push("/accept-terms");
        } else {
          return null;
        }
      }
    }
  };

  useEffect(() => {
    if (!auth.isLoading && auth.isLoggedIn && gdpr.terms && !gdpr.loading) {
      redirect();
    }

    // eslint-disable-next-line
  }, [history.location.pathname]);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return !auth ? <CircularProgress /> : children;
      }}
    />
  );
});

export default ProfileRoute;
