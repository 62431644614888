import { store } from "@risingstack/react-easy-state";

import { auth } from "./auth";

import { API_ENDPOINT } from "../consts/globals";

import { authFetch } from "functions";

const abortController = new AbortController();
const signal = abortController.signal;

export const countries = store({
  countries: null,
  loading: false,
  async getCountries() {
    countries.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    try {
      const response = await authFetch(`${API_ENDPOINT}/system/countries`, {
        signal: signal,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      countries.countries = responseJson.data;
      countries.loading = false;
    } catch (error) {
      countries.loading = false;
      console.log(error);
    }
  },
});
