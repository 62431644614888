import axios from "axios";
import { store } from "@risingstack/react-easy-state";
import { Auth } from "aws-amplify";
import AWS from "aws-sdk";

import { gdpr } from "stores/gdpr";

import { API_ENDPOINT } from "../consts/globals";
// import { PAYMENT_ENDPOINT } from "../consts/globals";

import { authFetch } from "functions";

const abortController = new AbortController();
const signal = abortController.signal;

export const auth = store({
  authState: null,
  user: null,
  creds: null,
  isLoggedIn: false,
  isLoading: true,
  errors: null,
  updateSuccess: false,
  passwordErrors: null,
  passwordUpdateSuccess: false,
  async getUser() {
    try {
      auth.isLoading = true;
      let user = await Auth.currentAuthenticatedUser({ bypassCache: true });

      const userFetch = await authFetch(`${API_ENDPOINT}/system/user`, {
        method: "GET",
        signal: signal,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user?.signInUserSession.idToken.jwtToken}`,
        },
      });
      user.dbData = (await userFetch.json()).data ?? null;

      auth.user = { ...user };

      await gdpr.getTerms();

      auth.isLoggedIn = true;
      auth.isLoading = false;
    } catch {
      auth.user = null;
      auth.isLoggedIn = false;
      auth.isLoading = false;
    }
  },
  async updateUser(attributes) {
    auth.errors = null;
    auth.updateSuccess = false;
    auth.isLoading = true;

    try {
      const newDbData = await authFetch(`${API_ENDPOINT}/system/user`, {
        method: "PATCH",
        signal: signal,
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify(attributes),
      });
      auth.user.dbData = (await newDbData.json()).data;
      auth.updateSuccess = true;
      return newDbData;
    } catch (err) {
      auth.errors = err;
    } finally {
      auth.isLoading = false;
      auth.updateSuccess = false;
    }
  },
  async changePassword(values) {
    auth.passwordErrors = null;
    auth.passwordUpdateSuccess = false;
    auth.isLoading = true;

    let params = {
      AccessToken: auth.user.signInUserSession.accessToken.jwtToken,
      PreviousPassword: values.current_password,
      ProposedPassword: values.repeat_password,
    };

    const cognito = new AWS.CognitoIdentityServiceProvider();
    cognito.changePassword(params, function (error, data) {
      if (error) {
        console.log(error);
        auth.passwordErrors = error;
        auth.passwordUpdateSuccess = false;
        auth.isLoading = false;
      } else {
        auth.passwordErrors = null;
        auth.passwordUpdateSuccess = true;
        auth.refreshToken();
        auth.getUser();
        auth.isLoading = false;
        auth.passwordUpdateSuccess = false;
      }
    });
  },
  async refreshToken() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        async (err, session) => {
          // const { idToken, refreshToken, accessToken } = session;
          await auth.getUser();
        }
      );
    } catch (e) {
      console.log("Unable to refresh Token", e);
    }
  },
  async logout() {
    await Auth.signOut();
    auth.user = null;
    auth.isLoggedIn = false;
  },
});

export const location = store({
  prevLocation: null,
});

export const news = store({
  news: null,
  singleNews: null,
  async getNews() {
    try {
      const response = await axios.get(`${API_ENDPOINT}/system/news`);

      if (response.status !== 200) {
        throw Error(response.statusText);
      }

      news.news = response.data.data;
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getSingleNews(id) {
    try {
      const response = await axios.get(`${API_ENDPOINT}/system/news/${id}`);

      if (response.status !== 200) {
        throw Error(response.statusText);
      }

      news.singleNews = response.data;
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
});
