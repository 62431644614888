import styled from "styled-components";
import { Link } from "react-router-dom";
import { Field } from "formik";
import { withStyles } from "@material-ui/core/styles";
import { InputLabel } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Rating from "@material-ui/lab/Rating";
import Button from "@material-ui/core/Button";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";

export const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

export const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 40px;
  }

  h3 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: 400;
    font-size: 18px;

    span {
      font-weight: 700;
    }
  }
`;

export const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  margin-bottom: 60px;
`;

export const StyledResultsWrapper = styled(Container)`
  position: relative;
  z-index: 2;
`;

/* ===================================================== */
export const StyledRating = withStyles({
  root: {
    marginBottom: "11px",
  },
  icon: {
    color: "#C4C4C4",
  },
  iconFilled: {
    color: "#06D7F9",
  },
})(Rating);

export const StyledResultWrapper = styled(Box)`
  position: relative;
  margin-bottom: 56px;
  opacity: ${(props) => (props.loading ? 0.5 : 1)};
  transition: all 0.3s ease-in-out;
`;

export const StyledLogoWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 26px;

  img {
    height: 104px;
    width: 104px;
    max-width: 100%;
    object-fit: contain;
    margin-bottom: 10px;
  }

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 0 !important;
  }

  h6 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    text-transform: capitalize;
    color: #b0b0b0;
    margin: 0;
  }
`;

export const StyledImageNotFound = styled(BrokenImageIcon)`
  height: 104px;
  width: 104px;
  max-width: 100%;
  object-fit: contain;
  margin-bottom: 10px;
  fill: #b0b0b0;
`;

export const StyledLogoLinkWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;

  img {
    width: 22.5px;
    height: 18.6px;
    margin-right: 6px;
  }

  a {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #000000;
  }

  span {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: ${(props) => (props.ordered ? "none" : "underline")};
    text-transform: capitalize;
    color: ${(props) => (props.ordered ? "#b0b0b0" : "#000000")};
    cursor: ${(props) => (props.ordered ? "default" : "pointer")};
  }
`;

export const StyledNameWrapper = styled(Box)`
  flex-wrap: wrap;

  h3 {
    font-family: "RobotoMedium", "Arial", sans-serif !important;
    font-size: 21px !important;
    font-weight: 700 !important;
    line-height: 24px;
    text-transform: uppercase;
    color: #000000;
    margin: 0;
    margin-bottom: 16px !important;
  }

  a {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 12px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #000000;
    margin: 0;
  }
`;

export const StyledDescWrapper = styled(Box)`
  min-height: 68px;
  margin-bottom: 14px;

  p {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0;
  }
`;

export const StyledLinkWrapper = styled(Box)`
  a {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #00c9e9;
    display: flex;
    margin-bottom: 26px;
  }
`;

export const StyledProductsWrapper = styled(Box)`
  h4 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000000;
    margin: 0;
    margin-bottom: 16px;
  }

  ul {
    list-style-position: inside;
    padding-left: 0;
    margin: 0;
    min-height: 68px;
    margin-bottom: 14px;

    li {
      font-family: "RobotoRegular", "Arial", sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #ff490f;

      span {
        color: #000000;
      }
    }
  }

  a {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #00c9e9;
  }
`;

export const StyledLinksWrapper = styled(Box)`
  img {
    width: 22px;
    height: 18px;
    margin-right: 6px;
  }

  a,
  span {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #000000;
    margin-right: 18px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const StyledNoRatingWrapper = styled(Box)`
  min-height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    width: 80%;
    text-align: center;
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    text-transform: capitalize;
    margin: 0;
  }
`;

export const StyledField = styled(Field)`
  background: #ffffff;
  margin-bottom: 40px;

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
  }

  .MuiInputBase-input {
    background: #ffffff;
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin: 0;
    bottom: -24px;
  }
`;

export const StyledDatePicker = styled(KeyboardDatePicker)`
  background: #ffffff;
  margin: 0;
  margin-bottom: 40px;

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
  }

  .MuiInputBase-input {
    background: #ffffff;
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin: 0;
    bottom: -24px;
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  font-family: "RobotoRegular", "Arial", sans-serif;
  transform: none !important;
  display: block;
  font-size: 14px;
  line-height: 93.1%;
  color: #000000;
  margin-bottom: 20px;
`;

export const StyledButtonPrimary = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;
  padding: 10px 40px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

export const StyledLinkMain = styled(Link)`
  text-decoration-line: none !important;

  &:hover {
    opacity: 0.8;
  }
`;

export const StyledButtonWithIcon = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;
  margin-bottom: 40px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

export const StyledPlusSign = styled(Box)`
  height: 100%;
  width: 46px;
  background: #00bfe9;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #ffffff;
  }
`;

export const StyledRemoveWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  padding-top: 33px;
`;

export const StyledMinusSign = styled(Box)`
  height: 40px;
  width: 40px;
  background: #00bfe9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    color: #ffffff;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const StyledButtonText = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
`;

export const StyledBestCostCountryWrapper = styled(Box)`
  height: 62px;
  width: 95px;
  margin-bottom: 10px;

  img {
    height: 100%;
    width: 100%;
    margin-bottom: 0;
  }
`;

export const StyledDetailsWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  p {
    margin: 0;
    margin-right: 20px;

    span {
      font-weight: 700;
    }
  }
`;
