import styled from "styled-components";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

export const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 40px;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    margin-bottom: 40px;
  }
`;

// export const StyledIntroBackground = styled(Box)`
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   top: 0;
//   left: 0;
//   background: url(${"test"}) no-repeat center center;
//   background-size: cover;
//   z-index: 0;
// `;

// export const StyledIntroGradient = styled(Box)`
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   top: 0;
//   left: 0;
//   background: rgba(15, 15, 15, 0.7);
//   z-index: 1;
// `;

export const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  padding-bottom: 40px;
`;

export const StyledStepsWrapper = styled(Container)`
  position: relative;
  z-index: 2;
`;

export const StyledStepWrapper = styled(Box)`
  border-right: 1px solid #dddddd;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;

  img {
    margin-bottom: 50px;
  }

  h1 {
    font-family: "NeueMachinaRegular", "Arial", sans-serif;
    font-size: 54px;
    line-height: 52px;
    color: #06d7f9;
    margin: 0;
  }

  h3 {
    font-size: 21px;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
  }

  p {
    font-size: 12px;
    line-height: 17px;
    color: #7b7b7b;
  }

  @media (max-width: 1279px) {
    border-right: none;
  }

  @media (max-width: 959px) {
    h1 {
      margin-bottom: 24px;
    }

    h3 {
      margin-bottom: 24px;
    }
  }
`;

export const StyledStepWrapperBorderless = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;

  img {
    margin-bottom: 50px;
  }

  h1 {
    font-family: "NeueMachinaRegular", "Arial", sans-serif;
    font-size: 54px;
    line-height: 52px;
    color: #06d7f9;
    margin: 0;
  }

  h3 {
    font-size: 21px;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
  }

  p {
    font-size: 12px;
    line-height: 17px;
    color: #7b7b7b;
  }

  @media (max-width: 959px) {
    h1 {
      margin-bottom: 24px;
    }

    h3 {
      margin-bottom: 24px;
    }
  }
`;

export const StyledImageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledStepInner = styled(Box)`
  width: 100%;
  padding: 0 4rem;
`;

export const StyledStepFirstRow = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 959px) {
    text-align: center;
  }
`;

export const StyledImage = styled.img`
  margin-bottom: 0 !important;
`;

export const StyledStepSecondRow = styled(Grid)`
  margin-bottom: 40px;

  @media (max-width: 959px) {
    text-align: center;
  }
`;

export const StyledStepThirdRow = styled(Grid)`
  margin-bottom: 40px;

  @media (max-width: 959px) {
    text-align: center;
  }
`;

export const StyledButtonPrimary = styled.button`
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #06d7f9;
  height: 45px;
  cursor: pointer;

  color: #06d7f9;

  &:hover {
    opacity: 0.8;
  }
`;

export const StyledStepFourthRow = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  @media (max-width: 959px) {
    text-align: center;
  }

  .MuiLinearProgress-root {
    height: 5px;
  }

  .MuiLinearProgress-colorPrimary {
    background-color: #ededed;
  }

  .MuiLinearProgress-barColorPrimary {
    background: #06d7f9;
  }
`;

export const StyledEndWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;

  p {
    font-size: 12px;
    line-height: 17px;
    color: #2ecc71;
    margin: 0;
  }

  @media (max-width: 959px) {
    justify-content: center;
  }
`;

export const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;
