import styled from "styled-components";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import { InputLabel } from "@material-ui/core";

export const StyledSpacer = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
`;

export const StyledWrapper = styled(Box)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;

  form {
    width: 100%;
  }

  .MuiInputLabel-root {
    font-family: "RobotoRegular", "Arial", sans-serif;
    transform: none;
    display: block;
    font-size: 14px;
    line-height: 93.1%;
    color: #06d7f9;
    margin-bottom: 9px;
    position: relative;
  }
`;

export const StyledSearchWrapper = styled(Box)`
  z-index: 3;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSearchButton = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  font-family: "RobotoRegular", "Arial", sans-serif;
  transform: none !important;
  display: block;
  font-size: 14px;
  line-height: 93.1%;
  color: #000000;
  margin-bottom: 20px;
`;

export const StyledSelect = styled(FormControl)`
  // margin-bottom: 30px;
  margin-right: 2px;
  width: 250px;

  .MuiSelect-outlined {
    border-radius: 0;
  }

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
  }

  .MuiInputBase-root {
    svg {
      color: #ffffff;
    }
  }

  .MuiInputBase-input {
    background: #06d7f9;
    color: #ffffff;
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin: 0;
    bottom: -24px;
  }

  fieldset {
    border: none !important;
  }
`;

export const StyledSearch = styled(Box)`
  width: 100%;
`;

export const StyledInnerWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    marginRight: "20px",
    "@media (max-width: 959px)": {
      width: "100%",
    },
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "none",
    borderWidth: "0 !important",
    boxShadow: "none",
    borderBottom: "1px solid #ffffff !important",
    outline: "none",
    minHeight: "40px",
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  // placeholder: (provided) => ({
  //   ...provided,
  //   color: "#0091d4",
  //   fontSize: "16px",
  // }),
  // input: (provided) => ({
  //   ...provided,
  //   color: "#0091d4",
  //   fontSize: "16px",
  // }),
  // valueContainer: (provided) => ({
  //   ...provided,
  //   padding: "17px",
  // }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    zIndex: 10,
  }),
  // option: (provided) => ({
  //   ...provided,
  //   backgroundColor: "transparent",
  //   padding: "17px",
  //   "&:hover": {
  //     color: "#0091d4",
  //     cursor: "pointer",
  //   },
  // }),
};
