import { store } from "@risingstack/react-easy-state";

import { auth } from "./auth";
import { company } from "./company";

import { API_ENDPOINT } from "../consts/globals";

import { authFetch } from "functions";

const abortController = new AbortController();
const signal = abortController.signal;

export const dashboards = store({
  buyerDashboard: null,
  supplierDashboard: null,
  errors: null,
  loading: false,
  async getBuyerDashboard() {
    dashboards.buyerDashboard = null;
    dashboards.errors = false;
    dashboards.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    // if (!company.company) {
    //   await company.getCompany();
    // }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/buyer-dashboard`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        dashboards.errors = true;
        dashboards.loading = false;
        dashboards.buyerDashboard = null;
        throw Error(response.statusText);
      }

      dashboards.errors = false;
      dashboards.loading = false;
      dashboards.buyerDashboard = responseJson;
      return responseJson;
    } catch (error) {
      dashboards.errors = true;
      dashboards.loading = false;
      dashboards.buyerDashboard = null;
      console.log(error);
    }
  },
  async getSupplierDashboard() {
    dashboards.supplierDashboard = null;
    dashboards.errors = false;
    dashboards.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/supplier-dashboard`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        dashboards.errors = true;
        dashboards.loading = false;
        dashboards.supplierDashboard = null;
        throw Error(response.statusText);
      }

      dashboards.errors = false;
      dashboards.loading = false;
      dashboards.supplierDashboard = responseJson;
      return responseJson;
    } catch (error) {
      dashboards.errors = true;
      dashboards.loading = false;
      dashboards.supplierDashboard = null;
      console.log(error);
    }
  },
});
