import React, { Fragment, useState } from "react";
import { Formik, Form } from "formik";
import { useTranslation, Trans } from "react-i18next";
import { autoEffect, view } from "@risingstack/react-easy-state";

import { company } from "stores/company";
import { supplierProducts } from "stores/supplierProducts";

import Loader from "components/shared/parts/Loader";
import MainForm from "./supplierProducts/FormPage/Forms/MainForm";

import validationSchema from "./supplierProducts/FormPage/FormModel/validationSchema";
import formModel from "./supplierProducts/FormPage/FormModel/formModel";
import formInitialValues from "./supplierProducts/FormPage/FormModel/formInitialValues";

import { CircularProgress } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import useStyles from "../CompanyDetails/FormPage/styles";
import {
  StyledButton,
  StyledContainer,
  StyledFormWrapper,
  StyledIntro,
  StyledIntroWrapper,
  StyledLink,
  StyledSpacer,
} from "./styled";

const { formId, formField } = formModel;

const SupplierProducts = view(() => {
  const classes = useStyles();
  const currentValidationSchema = validationSchema[0];
  // const [companyId, setCompanyId] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();

  autoEffect(async () => {
    if (!company.company) {
      await company.getCompany();
    }

    if (!supplierProducts.segments) {
      await supplierProducts.getSegments();
    }

    if (!supplierProducts.supplierProducts) {
      await supplierProducts.getSupplierProducts();
    }

    if (supplierProducts.supplierProducts) {
      // setCompanyId(company.company.company_id);
      setInitialValues({
        segment: "",
        family: "",
        class: "",
        commodities: supplierProducts.supplierProducts.map((product) => {
          return {
            category_id: product.categories.id || "",
            title: product.categories.commodity_name || "",
          };
        }),
      });
    } else {
      // setCompanyId(null);
      setInitialValues(formInitialValues);
    }
  });

  async function _submitForm(values, actions) {
    const modifiedValues = values.commodities.map((commodity) => {
      return { category_id: commodity.category_id };
    });

    const preparedValues = { categories: modifiedValues };

    setInitialValues(null);
    supplierProducts.addSupplierProducts(preparedValues);
    // if (companyId) {
    //   supplierProducts.editSupplierProducts(values, companyId);
    // } else {
    //   supplierProducts.addSupplierProducts(values);
    // }
    actions.setSubmitting(false);
  }

  function _handleSubmit(values, actions) {
    _submitForm(values, actions);
  }

  return !initialValues ||
    supplierProducts.loading ||
    company.loading ||
    !supplierProducts.segments ? (
    <Fragment>
      <StyledSpacer />
      <Loader />
    </Fragment>
  ) : !company.company ? (
    <Fragment>
      <StyledIntro>
        <StyledIntroWrapper fixed>
          <Grid
            container
            justify={matches ? "space-between" : "flex-start"}
            alignItems={"center"}
            style={{ marginBottom: "40px" }}
          >
            <Grid item xs={12}>
              <h3>
                <Trans i18nKey="complete-step-one">
                  <StyledLink to="/company-details"></StyledLink>
                </Trans>
              </h3>
            </Grid>
          </Grid>
        </StyledIntroWrapper>
      </StyledIntro>
    </Fragment>
  ) : (
    <Fragment>
      <StyledContainer>
        <StyledFormWrapper fixed>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({
              isSubmitting,
              setFieldValue,
              setTouched,
              values,
              errors,
              touched,
            }) => (
              <Form id={formId}>
                <MainForm
                  formField={formField}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  setTouched={setTouched}
                  initialValues={initialValues}
                  segments={supplierProducts.segments}
                />
                <div>
                  <StyledButton disabled={isSubmitting} type="submit">
                    {t("submit")}
                  </StyledButton>
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </StyledFormWrapper>
      </StyledContainer>
    </Fragment>
  );
});

export default SupplierProducts;
