import React from "react";
import { HeaderWrapper, StyledSpacer, StyledToolbarButton } from "./styled";
import { auth } from "stores/auth";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.svg";

import logoutIcon from "../../assets/img/logoutIcon.svg";
import { useTranslation } from "react-i18next";

const UserIsAuditor = () => {
  const { t } = useTranslation();

  const logout = async () => {
    await auth.logout();

    // history.push("/login");
  };

  return (
    <>
      <HeaderWrapper>
        <Link>
          <img src={logo} alt="yvoo logo" />
        </Link>
        <StyledToolbarButton onClick={logout}>
          <img src={logoutIcon} alt="logout icon" />
          <span>{t("logout")}</span>
        </StyledToolbarButton>
      </HeaderWrapper>
      <StyledSpacer>
        <div>
          <h1>
            Look&apos;s like your role is "
            <b style={{ color: "#06d7f9" }}>Auditor</b>"
            <br /> but you are trying to login into YVOO Search
            <span style={{ color: "#06d7f9" }}>Pro+</span>
          </h1>

          <p>
            To login into <b style={{ color: "#06d7f9" }}>YVOO SearchPro+</b>{" "}
            register as "<span style={{ color: "#06d7f9" }}>Buyer</span>" or "
            <span style={{ color: "#06d7f9" }}>Supplier</span>" or contact{" "}
            <a href="mailto:contact@yvoo.io">contact@yvoo.io</a> to assign you a
            correct role
          </p>
        </div>
      </StyledSpacer>
    </>
  );
};

export default UserIsAuditor;
