import React from "react";
import { useHistory } from "react-router-dom";
import { view, autoEffect } from "@risingstack/react-easy-state";

import { auth } from "stores/auth";

import Auth from "./Auth";
import GdprFooter from "../parts/GdprFooter";

import Grid from "@material-ui/core/Grid";
import { StyledGridContainer, StyledWrapper } from "./styled";

const Login = view(() => {
  let history = useHistory();

  autoEffect(() => {
    if (auth.isLoggedIn) {
      if (
        auth.user &&
        auth.user.signInUserSession.idToken.payload["cognito:groups"].find(
          (group) => group === "yvoo-buyer"
        )
      ) {
        history.push("/search");
      } else {
        history.push("/");
      }
      // if (location.prevLocation) {
      //   history.push(location.prevLocation);
      // } else {
      //   history.push("/");
      // }
    }
  });

  return (
    <StyledGridContainer container>
      <Grid item xs={12}>
        <StyledWrapper>
          <Auth />
          <GdprFooter />
        </StyledWrapper>
      </Grid>
    </StyledGridContainer>
  );
});

export default Login;
