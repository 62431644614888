import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { autoEffect, view } from "@risingstack/react-easy-state";

import { auth } from "stores/auth";
import { quickscan } from "stores/quickscan";

import Loader from "components/shared/parts/Loader";

import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import {
  StyledMain,
  StyledMainWrapper,
  StyledSpacer,
  StyledSpecialCell,
  StyledTableCell,
  StyledTableCellBorder,
  StyledTableContainer,
} from "./styled";

const prettyStatusMapping = {
  notVerified: "not verified",
  started: "started",
  open: "open",
  pending: "pending",
  completed: "completed",
};

export function QuickscansTable({ limit, rows }) {
  const { t } = useTranslation();

  return (
    <StyledTableContainer>
      <Table aria-label="quickscans table">
        <TableHead>
          <TableRow>
            <TableCell>{t("company name")}</TableCell>
            <TableCell>{t("date")}</TableCell>
            <TableCell>{t("status")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(0, limit).map((row, index) => (
            <TableRow
              key={row.id}
              style={{
                backgroundColor: index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
              }}
            >
              <TableCell component="th" scope="row">
                <StyledSpecialCell>{row.companyName}</StyledSpecialCell>
              </TableCell>
              <TableCell>
                {(() => {
                  const date = new Date(row.createdAt);
                  // prettier-ignore
                  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}.`;
                })()}
              </TableCell>
              <StyledTableCell>
                <a href={row.orderUrl}>
                  {prettyStatusMapping[row.status] ?? "unknown"}
                </a>

                <StyledTableCellBorder status={row.status} />
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}

const MyQuickscans = view(() => {
  const { t } = useTranslation();
  useEffect(() => {
    quickscan.userQuickscans = null;
    quickscan.getUserQuickscans();
  }, []);
  return (
    <Fragment>
      {!auth.user || !quickscan.userQuickscans ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          <StyledMain>
            <StyledMainWrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <h3>
                    {t("quick scans ordered")}{" "}
                    <span className={"separator"}>|</span>{" "}
                    <span>{quickscan.userQuickscans.length}</span>
                  </h3>
                  <QuickscansTable rows={quickscan.userQuickscans} />
                </Grid>
              </Grid>
            </StyledMainWrapper>
          </StyledMain>
        </Fragment>
      )}
    </Fragment>
  );
});

export default MyQuickscans;
