import styled from "styled-components";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";

export const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    // margin-bottom: 40px;
  }

  h3 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin: 0;

    span {
      font-weight: 700;
    }
  }

  p {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    margin-bottom: 40px;
  }
`;

export const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  padding-bottom: 40px;
`;

export const StyledStepsWrapper = styled(Container)`
  position: relative;
  z-index: 2;

  a {
    color: #00bfe9;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

export const StyledTable = styled(Table)`
  border: 1px solid rgba(224, 224, 224, 1);
  .MuiTableRow-root:nth-child(odd) {
    background-color: #f3f9fa;
  }
  .MuiTableCell-root {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin: 0;
  }
  .MuiTableCell-root:first-of-type {
    font-weight: 700;
    border-right: 1px solid rgba(224, 224, 224, 1);
    vertical-align: top;
    text-align: right;
    width: 260px;
  }
`;
