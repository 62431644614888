import { store } from "@risingstack/react-easy-state";

import { auth } from "./auth";
import { company } from "./company";
import { selfAssessment } from "./selfAssessment";

import { API_ENDPOINT } from "../consts/globals";

import { authFetch } from "functions";

const abortController = new AbortController();
const signal = abortController.signal;

export const quickscan = store({
  quickscan: null,
  specificQuickscan: null,
  adminQuickscan: null,
  loading: true,
  tempValues: null,
  specificTempValues: null,
  error: false,
  step: 1,
  userQuickscans: null,
  async getQuickscan() {
    quickscan.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    if (company.company) {
      try {
        const response = await authFetch(
          `${API_ENDPOINT}/system/quick-scan/${company.company.id}`,
          {
            signal: signal,
            method: "GET",
            headers: {
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        );

        if (!response.ok) {
          throw Error(response.statusText);
        }

        const responseJson = await response.json();

        if (responseJson.errors) {
          quickscan.loading = false;
          return;
        } else {
          let convertedValues = Object.fromEntries(
            // convert to array, map, and then fromEntries gives back the object
            Object.entries(responseJson).map(([key, value]) => {
              if (value === null) {
                value = "";
              } else {
                value = value.toString();
              }
              return [key, value];
            })
          );

          selfAssessment.selfAssessment = null;
          await selfAssessment.getSelfAssessment();
          quickscan.tempValues = convertedValues;
          quickscan.quickscan = convertedValues;
          quickscan.loading = false;
        }
      } catch (error) {
        quickscan.loading = false;
        console.log(error);
      }
    } else {
      quickscan.loading = false;
    }
  },
  async getSpecificQuickscan(id) {
    quickscan.specificQuickscan = null;
    quickscan.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    if (!company.specificCompany) {
      await company.getSpecificCompany(id);
    }

    if (company.specificCompany) {
      try {
        const response = await authFetch(
          `${API_ENDPOINT}/system/quick-scan/${company.specificCompany.id}`,
          {
            signal: signal,
            method: "GET",
            headers: {
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        );

        if (!response.ok) {
          throw Error(response.statusText);
        }

        const responseJson = await response.json();

        if (responseJson.errors) {
          quickscan.loading = false;
          return;
        } else {
          let convertedValues = Object.fromEntries(
            // convert to array, map, and then fromEntries gives back the object
            Object.entries(responseJson).map(([key, value]) => {
              if (value === null) {
                value = "";
              } else {
                value = value.toString();
              }
              return [key, value];
            })
          );

          quickscan.specificTempValues = convertedValues;
          quickscan.specificQuickscan = convertedValues;
          quickscan.loading = false;
        }
      } catch (error) {
        quickscan.loading = false;
        console.log(error);
      }
    } else {
      quickscan.loading = false;
    }
  },
  async getAdminQuickscan(id) {
    quickscan.adminQuickscan = null;
    quickscan.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    // if (!company.specificCompany) {
    //   await company.getSpecificCompany(id);
    // }

    if (id) {
      try {
        const response = await authFetch(
          `${API_ENDPOINT}/system/admin-quick-scan/${id}?include=quickScanOrder`,
          {
            signal: signal,
            method: "GET",
            headers: {
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        );

        if (!response.ok) {
          throw Error(response.statusText);
        }

        const responseJson = await response.json();

        if (responseJson.errors) {
          quickscan.loading = false;
          return;
        } else {
          let convertedValues = Object.fromEntries(
            // convert to array, map, and then fromEntries gives back the object
            Object.entries(responseJson).map(([key, value]) => {
              if (key === "quickScanOrder") {
              } else {
                if (value === null) {
                  value = "";
                } else {
                  value = value.toString();
                }
              }

              return [key, value];
            })
          );

          // quickscan.specificTempValues = convertedValues;
          quickscan.adminQuickscan = convertedValues;
          quickscan.loading = false;
        }
      } catch (error) {
        quickscan.loading = false;
        console.log(error);
      }
    } else {
      quickscan.loading = false;
    }
  },
  async addQuickscan(values) {
    quickscan.loading = true;

    let convertedValues = Object.fromEntries(
      // convert to array, map, and then fromEntries gives back the object
      Object.entries(values).map(([key, value]) => {
        if (value === "") {
          value = null;
        } else {
          value = parseInt(value);
        }
        return [key, value];
      })
    );

    if (!auth.user) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    convertedValues.company_id = company.company.id;

    try {
      const response = await authFetch(`${API_ENDPOINT}/system/quick-scan`, {
        signal: signal,
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(convertedValues),
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await response.json();

      quickscan.quickscan = null;
      await quickscan.getQuickscan();
    } catch (error) {
      quickscan.quickscan = null;
      console.log(error);
    }
  },
  async editQuickscan(values, id) {
    quickscan.loading = true;

    // let convertedValues = Object.fromEntries(
    //   // convert to array, map, and then fromEntries gives back the object
    //   Object.entries(values).filter(([key, value]) => {
    //     // if (value === "no answer") {
    //     //   value = null;
    //     // }
    //     return value !== "no answer" ? [key, value] : null;
    //   })
    // );

    let convertedValues = Object.fromEntries(
      // convert to array, map, and then fromEntries gives back the object
      Object.entries(values).map(([key, value]) => {
        if (value === "") {
          value = null;
        } else {
          value = parseInt(value);
        }
        return [key, value];
      })
    );

    if (!auth.user) {
      await auth.getUser();
    }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/quick-scan/${id}`,
        {
          signal: signal,
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(convertedValues),
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await response.json();

      quickscan.quickscan = null;
      await quickscan.getQuickscan();
    } catch (error) {
      quickscan.quickscan = null;
      console.log(error);
    }
  },
  async orderQuickscan(id) {
    quickscan.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }
    const values = { company_id: id, visible: true };
    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/quick-scan-order`,
        {
          signal: signal,
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        quickscan.error = true;
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      quickscan.error = false;
      quickscan.loading = false;
      return responseJson;
    } catch (error) {
      quickscan.error = true;
      quickscan.loading = false;
      console.log(error);
    }
  },
  async getUserQuickscans() {
    quickscan.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/ordered-quickscans`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      quickscan.userQuickscans = responseJson;
      quickscan.loading = false;
    } catch (error) {
      quickscan.loading = false;
      console.log(error);
    }
  },
});
