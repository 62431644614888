import { store } from "@risingstack/react-easy-state";

import { auth } from "./auth";

import { API_ENDPOINT } from "../consts/globals";

import { authFetch } from "functions";

const abortController = new AbortController();
const signal = abortController.signal;

export const support = store({
  loading: false,
  errors: null,
  sent: false,
  async sendSupport(formValues) {
    support.sent = false;
    support.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    const values = {
      user_email: auth.user.attributes.email,
      topic: formValues.topic,
      message: formValues.message,
    };

    try {
      const response = await authFetch(`${API_ENDPOINT}/system/support-mail`, {
        signal: signal,
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        support.error = true;
        support.loading = false;
        support.sent = false;
        throw Error(response.statusText);
      }

      await response.json();

      support.error = false;
      support.loading = false;
      support.sent = true;
    } catch (error) {
      support.error = true;
      support.loading = false;
      support.sent = false;
      console.log(error);
    }
  },
});
