import styled from "styled-components";

import { Field } from "formik";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Rating from "@material-ui/lab/Rating";
import { withStyles } from "@material-ui/core/styles";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import { InputLabel } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

export const StyledRating = withStyles({
  root: {
    marginBottom: "11px",
  },
  icon: {
    color: "#C4C4C4",
  },
  iconFilled: {
    color: "#06D7F9",
  },
})(Rating);

export const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

export const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  // h2 {
  //   font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
  //   font-size: 36px;
  //   line-height: 40px;
  //   display: flex;
  //   align-items: center;
  //   text-transform: uppercase;
  //   margin: 0;
  //   margin-bottom: 40px;
  // }
`;

export const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
`;

export const StyledResultWrapper = styled(Box)`
  position: relative;
  margin-bottom: 56px;
  opacity: ${(props) => (props.loading ? 0.5 : 1)};
  transition: all 0.3s ease-in-out;
`;

export const StyledLogoWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 26px;

  img {
    height: 104px;
    width: 104px;
    max-width: 100%;
    object-fit: contain;
    margin-bottom: 20px;
  }

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 0 !important;
  }

  h6 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    text-transform: capitalize;
    color: #b0b0b0;
    margin: 0;
  }
`;

export const StyledLogoImageWrapper = styled(Box)`
  width: 154px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 1279px) {
    justify-content: flex-start;
  }
`;

export const StyledImageNotFound = styled(BrokenImageIcon)`
  height: 104px;
  width: 104px;
  max-width: 100%;
  object-fit: contain;
  fill: #b0b0b0;
`;

export const StyledRatingWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  // margin-bottom: 40px;

  @media (max-width: 1279px) {
    align-items: flex-start;
  }
`;

export const StyledLogoLinkWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;

  img {
    width: 22.5px;
    height: 18.6px;
    margin-right: 6px;
    margin-bottom: 0;
  }

  a {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #000000;
  }

  span {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: ${(props) => (props.ordered ? "none" : "underline")};
    text-transform: capitalize;
    color: ${(props) => (props.ordered ? "#b0b0b0" : "#000000")};
    cursor: ${(props) => (props.ordered ? "default" : "pointer")};
  }
`;

export const StyledNameWrapper = styled(Box)`
  h2 {
    font-family: "RobotoBold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    text-transform: uppercase;
    color: #000000;
    margin: 0;
    margin-bottom: 12px;
  }

  a {
    display: block;
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 12px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #000000;
    margin: 0;
    margin-bottom: 22px;
  }

  span {
    display: block;
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 12px;
    line-height: 17px;
    color: #000000;
    margin: 0;
    margin-bottom: 22px;
  }
`;

export const StyledDescWrapper = styled(Box)`
  margin-bottom: 40px;

  p {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0;
  }
`;

export const StyledProductsWrapper = styled(Box)`
  ul {
    list-style-position: inside;
    padding-left: 0;
    margin: 0;
    min-height: 68px;

    li {
      font-family: "RobotoRegular", "Arial", sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #ff490f;

      span {
        color: #000000;
      }
    }
  }
`;

export const StyledNoRatingWrapper = styled(Box)`
  min-height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    // width: 80%;
    text-align: center;
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    text-transform: capitalize;
    margin: 0;
  }

  @media (max-width: 1279px) {
    justify-content: flex-start;

    p {
      text-align: unset;
    }
  }
`;

export const StyledCentralWrapperTop = styled(Box)`
  padding-top: 24px;
  padding-right: 55px;
  padding-bottom: 46px;
  padding-left: 55px;
  border-bottom: 1px solid #b0b0b0;

  @media (max-width: 1279px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const StyledButtonDownload = styled(Button)`
  background: #ffffff;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  padding: 10px 0;
  text-transform: none;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #00bfe9;
  color: #00bfe9;
  width: 210px;

  &:first-of-type {
    margin-top: 20px;
  }

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

export const StyledCentralWrapperBottom = styled(Box)`
  padding-top: 46px;
  padding-right: 55px;
  padding-bottom: 46px;
  padding-left: 55px;
  min-height: 300px;

  h3 {
    font-family: "NeueMachinaUltraBold", "Arial", sans-serif;
    font-size: 21px;
    font-weight: normal;
    line-height: 20px;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
    margin-bottom: 30px;
  }

  @media (max-width: 1279px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const StyledPhotosWrapper = styled(Box)`
  padding-top: 24px;
  padding-right: 38px;
  padding-bottom: 46px;
  padding-left: 38px;

  h3 {
    font-family: "NeueMachinaUltraBold", "Arial", sans-serif;
    font-size: 21px;
    font-weight: normal;
    line-height: 20px;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    margin: 0;
    margin-bottom: 30px;

    span {
      font-weight: 700;
    }
  }

  img {
    height: 120px;
    width: 100%;
    max-width: 190px;
    object-fit: cover;
    margin-bottom: 20px;
  }

  @media (max-width: 1279px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const StyledField = styled(Field)`
  background: #ffffff;
  margin-bottom: 40px;

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
  }

  .MuiInputBase-input {
    background: #ffffff;
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin: 0;
    bottom: -24px;
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  font-family: "RobotoRegular", "Arial", sans-serif;
  transform: none !important;
  display: block;
  font-size: 14px;
  line-height: 93.1%;
  color: #000000;
  margin-bottom: 20px;
`;

export const StyledButtonPrimary = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;
  padding: 10px 40px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

export const StyledButtonWithIcon = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;
  margin-bottom: 40px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

export const StyledPlusSign = styled(Box)`
  height: 100%;
  width: 46px;
  background: #00bfe9;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #ffffff;
  }
`;

export const StyledRemoveWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  padding-top: 33px;
`;

export const StyledMinusSign = styled(Box)`
  height: 40px;
  width: 40px;
  background: #00bfe9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    color: #ffffff;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const StyledButtonText = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
`;

export const StyledDatePicker = styled(KeyboardDatePicker)`
  background: #ffffff;
  margin: 0;
  margin-bottom: 40px;

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
  }

  .MuiInputBase-input {
    background: #ffffff;
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin: 0;
    bottom: -24px;
  }
`;

export const StyledBestCostCountryWrapper = styled(Box)`
  height: 62px;
  width: 95px;
  margin-bottom: 10px;

  img {
    height: 100%;
    width: 100%;
    margin-bottom: 0;
  }
`;

export const StyledDetailsWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  p {
    margin: 0;
    margin-right: 20px;

    span {
      font-weight: 600;
    }
  }
`;
