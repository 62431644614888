import styled from "styled-components";

import { Link } from "react-router-dom";
import { Button, Container, Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

export const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 40px;
  }

  h3 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin: 0;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    margin-bottom: 40px;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #00c9e9;

  &:hover {
    opacity: 0.8;
  }
`;

export const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  padding-bottom: 40px;
`;

export const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

export const StyledContainer = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 40px;
  }

  h3 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 21px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
    margin-bottom: 20px;
  }
`;

export const StyledFormWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  padding-bottom: 40px;
`;

export const StyledButtonWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 959px) {
    flex-direction: column;
  }
`;

export const StyledButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 959px) {
    flex-direction: column;
  }
`;

export const StyledButtonItem = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;

  @media (max-width: 959px) {
    margin-bottom: 10px;
  }
`;

export const StyledButton = styled(Button)`
  background: #ffffff;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  padding: 10px 0;
  text-transform: none;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }

  &.save {
    border: 1px solid #00bfe9;
    color: #00bfe9;
  }

  &.cancel {
    border: 1px solid #ffae95;
    color: #ffae95;
  }

  @media (max-width: 959px) {
    margin-bottom: 10px;
  }
`;

export const StyledPagination = styled(Pagination)`
  position: fixed;
  margin-left: 50px;

  .MuiPagination-ul {
    flex-direction: column;

    li {
      margin-bottom: 20px;
    }
  }

  .MuiPaginationItem-page {
    position: relative;
    height: 16px;
    width: 16px;
    min-width: 16px;
    background-color: #f8f8f8;
    border: 1px solid #bcbcbc;
    // transition: none !important;

    span {
      position: absolute;
      right: -40px;
      font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
      font-size: 16px;
      line-height: 17px;
      color: #000000;
    }

    &:hover {
      background-color: #f8f8f8 !important;
      opacity: 0.8;
    }

    &[type="red"] {
      background-color: #ff8a80 !important;

      &:hover {
        background-color: #ff8a80 !important;
      }
    }
    &[type="orange"] {
      background-color: #ffc2af !important;

      &:hover {
        background-color: #ffc2af !important;
      }
    }
    &[type="yellow"] {
      background-color: #ffedbe !important;

      &:hover {
        background-color: #ffedbe !important;
      }
    }
    &[type="blue"] {
      background-color: #06d7f9 !important;

      &:hover {
        background-color: #06d7f9 !important;
      }
    }
    &[type="green"] {
      background-color: #00e8a6 !important;

      &:hover {
        background-color: #00e8a6 !important;
      }
    }
  }

  .Mui-selected {
    background-color: #f8f8f8 !important;

    span {
      font-size: 24px;
      color: #00c9e9;
      right: -48px;
    }

    &:hover {
      background-color: #f8f8f8 !important;
      opacity: 0.8;
    }
  }

  [aria-label="Go to next page"],
  [aria-label="Go to previous page"] {
    background-color: none;
    border: none;
  }

  @media (max-width: 959px) {
    position: relative;
    margin-left: unset;
  }
`;
