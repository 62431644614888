
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import styled from "styled-components";

export const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 10px;
  }

  h3 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin: 0;
  }

  h4 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 22px;
    margin: 0;
    margin-bottom: 10px;
  }

  h5 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 10px;
  }

  h6 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    margin-bottom: 40px;
  }
`;

export const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  padding-bottom: 40px;
`;

export const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

export const StyledScoreWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  h3 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 24px;
    line-height: 40px;
    text-align: right;
    text-transform: uppercase;
    // color: #06d7f9;
    color: ${(props) =>
      props.value === "0" ||
      props.value === 0 ||
      props.value === null ||
      props.value === ""
        ? "#000000"
        : props.value <= 1.5
        ? "#FF0000"
        : props.value >= 1.5 && props.value <= 2.5
        ? "#FF7B51"
        : props.value >= 2.5 && props.value <= 3.5
        ? "#FFC527"
        : props.value >= 3.5 && props.value <= 4.5
        ? "#06D7F9"
        : props.value >= 4.5 && props.value <= 5
        ? "#01E7AA"
        : "#000000"};
    margin: 0;
    margin-bottom: 8px;
  }

  h5 {
    font-family: "RobotoMedium", "Arial", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    text-align: right;
    color: #000000;
    margin: 0;
    margin-bottom: 8px;
  }

  @media (max-width: 1279px) {
    align-items: flex-start;

    h3,
    h5 {
      text-align: unset;
    }
  }
`;

export const StyledMainWrapper = styled(Box)`
  border-top: 1px solid #dddddd;
  padding-bottom: 40px;
`;

export const StyledGraphWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  border-right: 1px solid #dddddd;
  padding-top: 60px;
`;

export const StyledGroupScoresWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  padding: 60px 34px;
`;

export const StyledGroupScoreInner = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-left: 3px solid ${(props) => props.background};
  padding-bottom: ${(props) => (props.last ? 0 : "36px")};
`;

export const StyledGroupScoreBar = styled(Box)`
  padding: 3px 15px;
  width: ${(props) => props.width};
  background: ${(props) => props.background};
  margin-right: 33px;

  span {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    line-height: 39px;
    color: #ffffff;
    margin: 0;
  }
`;

export const StyledGroupScoreInfo = styled(Box)`
  p {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    margin: 0;
    margin-bottom: 3px;
  }
`;

export const StyledValuesWrapper = styled(Box)`
  height: 100%;
  width: 100%;
`;

export const StyledValueWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2px;
  // height: 112px;
  height: 150px;
  border-right: 3px solid
    ${(props) =>
      props.value === "0" ||
      props.value === 0 ||
      props.value === null ||
      props.value === ""
        ? "#000000"
        : props.value === "1"
        ? "#FF0000"
        : props.value === "2"
        ? "#FF7B51"
        : props.value === "3"
        ? "#FFC527"
        : props.value === "4"
        ? "#06D7F9"
        : props.value === "5"
        ? "#01E7AA"
        : "#000000"};

  @media (max-width: 559px) {
    flex-wrap: wrap;
    min-height: 112px;
    height: auto;
  }
`;

export const StyledValueLabelWrapper = styled(Box)`
  background-color: #f9f9f9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 14px;
  width: 100%;
  height: 100%;

  span {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  }
`;

export const StyledValueLabelInner = styled(Box)`
  position: relative;
`;

export const StyledValueAnswerWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 53px;
  width: 53px;
  color: ${(props) =>
    props.value === "0" ||
    props.value === 0 ||
    props.value === null ||
    props.value === ""
      ? "#000000"
      : props.value === "1"
      ? "#FF0000"
      : props.value === "2"
      ? "#FF7B51"
      : props.value === "3"
      ? "#FFC527"
      : props.value === "4"
      ? "#06D7F9"
      : props.value === "5"
      ? "#01E7AA"
      : "#000000"};

  span {
    font-family: "RobotoMedium", "Arial", sans-serif;
    font-size: 14px;
    line-height: 39px;
    // color: #000000;
  }
`;

export const StyledButton = styled(Button)`
  background: #ffffff;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  padding: 10px 0;
  text-transform: none;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #00bfe9;
  color: #00bfe9;
  width: 210px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

export const StyledScoreScale = styled(Box)`
  margin-bottom: 30px;

  h3 {
    margin-bottom: 20px;
  }

  p {
    margin: 0;
    margin-bottom: 5px;
  }
`;

export const StyledValuesHeadline = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding: 16px 10px;
`;