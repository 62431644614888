import React, { useState } from "react";
import SupportChat from "./SupportChat";
import MessageResponse from "./MessageResponse";
import {
  StyledButton,
  StyledButtonWrapper,
  StyledMainWrapper,
  StyledWrapper,
} from "./styled";

import "./styles.css";
import SupplierChat from "./SupplierChat";
import CostingChat from "./CostingChat";

const ChatAI = ({ userEmail, userName }) => {
  const [chatType, setChatType] = useState("");

  return (
    <StyledMainWrapper>
      <StyledWrapper>
        {!chatType && (
          <>
            <MessageResponse
              message={[
                `Hello, Nice to meet you, ${userName}!  I’m specialized in providing expert procurement advice and support. I am pleased to help you with supplier search or you can ask me anything, and I have the answers to your procurement-related queries. If you are looking for supplier search press supplier chat, otherwise if you are looking for chat support press chat support`,
              ]}
            />
            <StyledButtonWrapper>
              <StyledButton
                onClick={() => {
                  setChatType("supplier");
                }}
              >
                Start Supplier Search Engine
              </StyledButton>
              {/* <StyledButton
                onClick={() => {
                  setChatType("costing");
                }}
              >
                Start Costing Advisor Chat
              </StyledButton> */}
              <StyledButton
                onClick={() => {
                  setChatType("support");
                }}
              >
                Start Expert Advisor Chat
              </StyledButton>
            </StyledButtonWrapper>
          </>
        )}
        {chatType === "supplier" && (
          <SupplierChat
            userEmail={userEmail}
            userName={userName}
            setChatType={setChatType}
          />
        )}
        {chatType === "costing" && (
          <CostingChat
            userEmail={userEmail}
            userName={userName}
            setChatType={setChatType}
          />
        )}
        {chatType === "support" && <SupportChat />}
      </StyledWrapper>
    </StyledMainWrapper>
  );
};

export default ChatAI;
