import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Grid, Box, InputLabel } from "@material-ui/core";
import { SelectField } from "../../FormFields";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Virtuoso } from "react-virtuoso";

import Loader from "components/shared/parts/Loader";
import { view } from "@risingstack/react-easy-state";
import { supplierProducts } from "stores/supplierProducts";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = (
  <CheckBoxIcon fontSize="small" style={{ color: "#06D7F9" }} />
);

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledSectionOuter = styled(Box)`
  border-left: 2px solid #01e7aa;
  padding-left: 11px;
  margin-bottom: 60px;
`;

const StyledSectionInner = styled(Box)`
  background: #f3f9fa;
  padding: 40px;

  .MuiInputLabel-root {
    font-family: "RobotoRegular", "Arial", sans-serif;
    transform: none;
    display: block;
    font-size: 14px;
    line-height: 93.1%;
    color: #000000;
    margin-bottom: 9px;
  }

  .MuiFormLabel-asterisk {
    color: #ff490f;
  }

  .MuiDropzoneArea-root {
    height: 133px;
    border-radius: 0;
    min-height: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 29px;

    p {
      margin: 0;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 120.1%;
      text-align: center;
      color: #b0b0b0;
    }
  }

  .MuiDropzonePreviewList-root {
    display: flex;
    justify-content: center;
  }

  .MuiSnackbar-root {
    right: 24px;
    left: auto;
  }

  .MuiDropzoneArea-text {
    display: none;
  }
`;

const StyledTextField = styled(TextField)`
  background: #ffffff;
  margin-bottom: 30px;

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
  }

  .MuiInputBase-input {
    background: #ffffff;
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin: 0;
    bottom: -24px;
  }

  &.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    // border-color: green;
  }

  &:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    // border-color: red;
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #06d7f9;
  }

  &.MuiOutlinedInput-input {
    // color: green;
  }

  &:hover .MuiOutlinedInput-input {
    // color: red;
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
    color: #06d7f9;
  }

  &.MuiInputLabel-outlined {
    // color: green;
  }

  &:hover .MuiInputLabel-outlined {
    // color: red;
  }

  &.MuiInputLabel-outlined.Mui-focused {
    color: #06d7f9;
  }

  .MuiChip-root {
    border-radius: 0;
    background-color: transparent;
    border: 1px solid #e0e0e0;
  }
`;

const StyledTitle = styled(Box)`
  h2 {
    margin: 0;
    margin-bottom: 20px !important;
  }

  p {
    margin: 0;
    margin-bottom: 40px;
  }
`;

const MainForm = view((props) => {
  const {
    initialValues,
    segments,
    values,
    errors,
    touched,
    setFieldValue,
    setTouched,
  } = props;
  const [filteredFamilies, setFilteredFamilies] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [filteredCommodities, setFilteredCommodities] = useState([]);
  // const [dropdownCommodities, setDropdownCommodities] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");
  let inputRef;
  const { t } = useTranslation();

  const segmentsModel = segments.map((segment) => {
    return { value: segment.segment, label: segment.segment_name };
  });

  const prepareFamilies = async (segment) => {
    await supplierProducts.getFamilies(segment);

    const familiesModel = supplierProducts.families.map((family) => {
      return {
        value: family.family,
        label: family.family_name,
      };
    });

    setFilteredFamilies(familiesModel);
  };

  useEffect(() => {
    setFieldValue("family", "");
    setFieldValue("class", "");
    if (values.segment) {
      prepareFamilies(values.segment);
    }
    // eslint-disable-next-line
  }, [values.segment]);

  const prepareClasses = async (family) => {
    await supplierProducts.getClasses(family);

    const classesModel = supplierProducts.classes.map((pclass) => {
      return {
        value: pclass.class,
        label: pclass.class_name,
      };
    });

    setFilteredClasses(classesModel);
  };

  useEffect(() => {
    setFieldValue("class", "");
    if (values.family) {
      prepareClasses(values.family);
    }
    // eslint-disable-next-line
  }, [values.family]);

  const prepareCommodities = async (pclass) => {
    await supplierProducts.getCommodities(pclass);

    const commoditiesModel = supplierProducts.commodities.map((commodity) => {
      return {
        category_id: commodity.category_id,
        title: commodity.commodity_name,
      };
    });

    setFilteredCommodities(commoditiesModel);
    // setDropdownCommodities(commoditiesModel);
  };

  useEffect(() => {
    if (values.class) {
      prepareCommodities(values.class);
      inputRef.focus();
    }
    // eslint-disable-next-line
  }, [values.class]);

  let timeout = null;

  const ListboxComponent = useMemo(() => {
    return {
      List: React.forwardRef(function ListboxComponent(
        { children, ...props },
        ref
      ) {
        const items = children;

        return (
          <div ref={ref}>
            <Virtuoso
              data={items}
              style={{ height: "40vh" }}
              {...props}
              itemContent={(index, data) => data}
            />
          </div>
        );
      }),
    };
  }, []);

  const setControllableSearchInput = async (value) => {
    setSearchInputValue(value);
  };

  // async useEffect() to get data on search input value change if it's 3 or more characters
  useEffect(() => {
    const getProducts = async (value) => {
      // eslint-disable-next-line
      clearTimeout(timeout);
      // eslint-disable-next-line
      timeout = setTimeout(async () => {
        const res = await supplierProducts.searchCommodities(value);

        const manipulatedData = res.map((item) => {
          return {
            category_id: item.id,
            title: item.commodity_name,
          };
        });

        /* setFilteredCommodities([
                          ...manipulatedData,
                          ...dropdownCommodities,
                        ]); */

        setFilteredCommodities([...manipulatedData]);
      }, 1000);
    };
    if (searchInputValue.length > 2) {
      getProducts(searchInputValue);
    }
  }, [searchInputValue]);

  return !initialValues || !segments ? (
    <Fragment>
      <StyledSpacer />
      <Loader />
    </Fragment>
  ) : (
    <Fragment>
      {supplierProducts.sideLoading ? <Loader /> : null}
      <Grid container>
        <Grid item xs={12}>
          <StyledTitle>
            <h2>{t("add supplier products")}</h2>
            <p>
              {t(
                "products can be added through the dropdown selection, or searched directly in comodities input field by name or UNSPSC code"
              )}
              .
            </p>
          </StyledTitle>
        </Grid>
      </Grid>
      <StyledSectionOuter>
        <StyledSectionInner>
          <Grid container>
            <Grid item xs={12} lg={4}>
              <InputLabel htmlFor="segment" shrink={true} variant="outlined">
                {t("segment")}:
              </InputLabel>
              <SelectField
                id="segment"
                name="segment"
                data={segmentsModel}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputLabel htmlFor="family" shrink={true} variant="outlined">
                {t("family")}:
              </InputLabel>
              <SelectField
                id="family"
                name="family"
                data={filteredFamilies}
                fullWidth
                variant="outlined"
                size="small"
                disabled={filteredFamilies.length < 1}
                open={filteredFamilies.length > 0}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputLabel htmlFor="class" shrink={true} variant="outlined">
                {t("class")}:
              </InputLabel>
              <SelectField
                id="class"
                name="class"
                data={filteredClasses}
                fullWidth
                variant="outlined"
                size="small"
                disabled={filteredClasses.length < 1}
                open={filteredClasses.length > 0}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                htmlFor="commodities"
                shrink={true}
                variant="outlined"
                required
              >
                {t("commodities")}:
              </InputLabel>
              <Autocomplete
                multiple
                id="commodities"
                name="commodities"
                options={filteredCommodities}
                defaultValue={initialValues.commodities}
                openOnFocus
                getOptionSelected={(option, value) =>
                  option.category_id === value.category_id
                }
                disableCloseOnSelect
                onChange={(event, value) => {
                  setFieldValue("commodities", value || []);
                }}
                freeSolo={true}
                inputValue={searchInputValue}
                filterOptions={(options, state) => options}
                onInputChange={async (event, value, reason) => {
                  if (reason !== "reset") {
                    // await set controllable input value
                    await setControllableSearchInput(value);

                    // clear api request function until you're done typing (1 sec)
                    if (value.length > 2) {
                      clearTimeout(timeout);
                    } else {
                      clearTimeout(timeout);
                      setFilteredCommodities([]);
                    }
                  }
                }}
                onBlur={() => setTouched({ ...touched, commodities: true })}
                getOptionLabel={(option) => option.title}
                ListboxComponent={ListboxComponent.List}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.title}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    error={
                      errors[params.id] && touched[params.id] ? true : false
                    }
                    helperText={
                      errors[params.id] && touched[params.id]
                        ? errors[params.id]
                        : null
                    }
                    fullWidth
                    variant="outlined"
                    size="small"
                    inputRef={(input) => {
                      inputRef = input;
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </StyledSectionInner>
      </StyledSectionOuter>
    </Fragment>
  );
});

export default MainForm;
