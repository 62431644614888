import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { view } from "@risingstack/react-easy-state";

import { company } from "stores/company";
import { rfi } from "stores/rfi";

import Loader from "components/shared/parts/Loader";

import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  StyledIntro,
  StyledIntroWrapper,
  StyledSpacer,
  StyledStepsWrapper,
  StyledTable,
} from "./styled";

const RFI = view(() => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  let params = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (params.id) {
      rfi.getRFI(params.id);
    }
  }, [params.id]);

  return (
    <Fragment>
      {rfi.loading || company.loading ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : !rfi.rfi || !company.company ? (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"flex-start"}
              >
                <Grid item xs={12} lg={4}>
                  <h3>{t("not available")}</h3>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
          </StyledIntro>
        </Fragment>
      ) : rfi.rfi.supplier_id !== company.company.id ? (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"flex-start"}
              >
                <Grid item xs={12} lg={4}>
                  <h3>{t("not available")}</h3>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
          </StyledIntro>
        </Fragment>
      ) : (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"flex-start"}
              >
                <Grid item xs={12} lg={4}>
                  <h2>{t("rfq details")}</h2>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
            <StyledStepsWrapper fixed>
              <Grid container>
                <Grid item xs={12} lg={8}>
                  <TableContainer>
                    <StyledTable size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell>{t("from")}:</TableCell>
                          <TableCell>
                            {rfi.rfi.sender_client_name} |{" "}
                            <a href={`mailto:${rfi.rfi.sender_client_email}`}>
                              {rfi.rfi.sender_client_email}
                            </a>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t("product name")}:</TableCell>
                          <TableCell>{rfi.rfi.product_name}</TableCell>
                        </TableRow>
                        {rfi.rfi.manufacturing_process ? (
                          <TableRow>
                            <TableCell>{t("manufacturing process")}:</TableCell>
                            <TableCell>
                              {rfi.rfi.manufacturing_process}
                            </TableCell>
                          </TableRow>
                        ) : null}
                        {rfi.rfi.special_requirements ? (
                          <TableRow>
                            <TableCell>{t("special requirements")}:</TableCell>
                            <TableCell>
                              {rfi.rfi.special_requirements}
                            </TableCell>
                          </TableRow>
                        ) : null}
                        {rfi.rfi.location ? (
                          <TableRow>
                            <TableCell>{t("location")}:</TableCell>
                            <TableCell>{rfi.rfi.location}</TableCell>
                          </TableRow>
                        ) : null}
                        {rfi.rfi.quanitity ? (
                          <TableRow>
                            <TableCell>{t("quantity")}:</TableCell>
                            <TableCell>{rfi.rfi.quanitity}</TableCell>
                          </TableRow>
                        ) : null}
                        {rfi.rfi.certificates ? (
                          <TableRow>
                            <TableCell>{t("certificates")}:</TableCell>
                            <TableCell>{rfi.rfi.certificates}</TableCell>
                          </TableRow>
                        ) : null}
                        {rfi.rfi.deadline ? (
                          <TableRow>
                            <TableCell>{t("deadline")}:</TableCell>
                            <TableCell>{rfi.rfi.deadline}</TableCell>
                          </TableRow>
                        ) : null}
                        {rfi.rfi.rfi_deadline ? (
                          <TableRow>
                            <TableCell>{t("rfq deadline")}:</TableCell>
                            <TableCell>{rfi.rfi.rfi_deadline}</TableCell>
                          </TableRow>
                        ) : null}
                        {rfi.rfi.supplier_target_countries ? (
                          <TableRow>
                            <TableCell>
                              {t("supplier target countries")}:
                            </TableCell>
                            <TableCell>
                              {rfi.rfi.supplier_target_countries}
                            </TableCell>
                          </TableRow>
                        ) : null}
                        {rfi.rfi.material ? (
                          <TableRow>
                            <TableCell>{t("material")}:</TableCell>
                            <TableCell>{rfi.rfi.material}</TableCell>
                          </TableRow>
                        ) : null}
                        {rfi.rfi.application_area ? (
                          <TableRow>
                            <TableCell>{t("application area")}:</TableCell>
                            <TableCell>{rfi.rfi.application_area}</TableCell>
                          </TableRow>
                        ) : null}
                        {rfi.rfi.keyword ? (
                          <TableRow>
                            <TableCell>{t("keyword")}:</TableCell>
                            <TableCell>{rfi.rfi.keyword}</TableCell>
                          </TableRow>
                        ) : null}
                        {rfi.rfi.type_of_sourcing_item ? (
                          <TableRow>
                            <TableCell>{t("type of sourcing item")}:</TableCell>
                            <TableCell>
                              {rfi.rfi.type_of_sourcing_item}
                            </TableCell>
                          </TableRow>
                        ) : null}
                        {rfi.rfi.message ? (
                          <TableRow>
                            <TableCell>{t("message")}:</TableCell>
                            <TableCell>{rfi.rfi.message}</TableCell>
                          </TableRow>
                        ) : null}
                      </TableBody>
                    </StyledTable>
                  </TableContainer>
                </Grid>
              </Grid>
            </StyledStepsWrapper>
          </StyledIntro>
        </Fragment>
      )}
    </Fragment>
  );
});

export default RFI;
