import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import { useTranslation } from "react-i18next";
import { autoEffect, view } from "@risingstack/react-easy-state";

import { auth } from "stores/auth";
import { favorites } from "stores/favorites";
import { invite } from "stores/invite";

import Loader from "components/shared/parts/Loader";
import LoaderAbsolute from "components/shared/parts/LoaderAbsolute";
import MainModal from "components/shared/parts/MainModal";

import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import {
  StyledButtonPrimary,
  StyledField,
  StyledInputLabel,
  StyledMain,
  StyledMainWrapper,
  StyledSpacer,
  StyledSpecialCell,
  StyledTableContainer,
} from "./styled";
import removeIcon from "assets/img/removeIcon.png";

const MySuppliers = view(() => {
  const [favoritesRows, setFavoritesRows] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
  const { t } = useTranslation();
  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  useEffect(() => {
    favorites.userFavorites = null;
    favorites.getUserFavorites();
  }, []);

  const createFavoritesData = (id, company_id, company_name, date) => {
    return { id, company_id, company_name, date };
  };

  autoEffect(() => {
    if (favorites.userFavorites) {
      setFavoritesRows(
        favorites.userFavorites.map((favorite) => {
          return createFavoritesData(
            favorite.id ? favorite.id : null,
            favorite.company && favorite.company.id
              ? favorite.company.id
              : null,
            favorite.company && favorite.company.name
              ? favorite.company.name
              : t("no data"),
            favorite.created_at
              ? moment(favorite.created_at).format("DD.MM.YYYY.")
              : t("no data")
          );
        })
      );
      setIsFetched(true);
    }
  });

  const handleInviteModalOpen = () => {
    invite.sent = false;
    setInviteModalOpen(true);
  };

  const handleInviteModalClose = () => {
    setInviteModalOpen(false);
    setTimeout(() => {
      invite.sent = false;
    }, 500);
  };

  const validateInvite = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = t("email is required");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = t("invalid email address");
    }

    return errors;
  };

  const handleFavorite = async (id) => {
    await favorites.removeFavorite(id);
    if (!favorites.error) {
      setFavoritesRows((prevItems) =>
        prevItems.filter((item) => item.company_id !== id)
      );
    }
  };

  return (
    <Fragment>
      {!auth.user ||
      !favorites.userFavorites ||
      favorites.loading ||
      !favoritesRows ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          {/* <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"flex-start"}
              >
                <Grid item xs={12} lg={6}>
                  <h2>{auth.user.dbData.name}</h2>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
          </StyledIntro> */}
          <StyledMain>
            <StyledMainWrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <MainModal
                    modalOpen={inviteModalOpen}
                    handleModalClose={handleInviteModalClose}
                  >
                    {invite.loading ? <LoaderAbsolute /> : null}
                    {!invite.loading && invite.sent ? (
                      <p style={{ padding: "4rem 3rem" }}>
                        {t("invitation sent successfully.")}
                      </p>
                    ) : !invite.loading && invite.errors ? (
                      <p style={{ padding: "4rem 3rem" }}>
                        {t("there's been an error. please try again.")}
                      </p>
                    ) : (
                      <Formik
                        initialValues={{
                          email: "",
                        }}
                        validate={validateInvite}
                        onSubmit={(values, { setSubmitting }) => {
                          setSubmitting(false);
                          invite.sendInvite(values);
                        }}
                      >
                        {({ submitForm, isSubmitting }) => (
                          <Form>
                            <StyledInputLabel
                              htmlFor="email"
                              shrink={true}
                              variant="outlined"
                              required
                            >
                              {t("supplier email")}
                            </StyledInputLabel>
                            <StyledField
                              component={TextField}
                              name="email"
                              autoComplete="off"
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="email"
                              multiline
                            />

                            {isSubmitting}
                            <br />
                            <StyledButtonPrimary
                              type="button"
                              disabled={isSubmitting}
                              onClick={submitForm}
                            >
                              {t("send an invitation")}
                            </StyledButtonPrimary>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </MainModal>
                  <h3>
                    {t("suppliers added to my suppliers")}{" "}
                    <span className={"separator"}>|</span>{" "}
                    <span>{favoritesRows.length}</span>
                  </h3>
                  <StyledTableContainer>
                    <Table aria-label="favorites table">
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("company name")}</TableCell>
                          <TableCell align="right">{t("date")}</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {favoritesRows.length === 0 && isFetched && (
                          <p>{t("no suppliers")}</p>
                        )}
                        {favoritesRows.map((row, index) => (
                          <TableRow
                            key={row.id}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Link to={`/companies/${row.company_id}`}>
                                <StyledSpecialCell>
                                  {row.company_name}
                                </StyledSpecialCell>
                              </Link>
                            </TableCell>
                            <TableCell align="right">{row.date}</TableCell>
                            <TableCell align="right">
                              <img
                                onClick={() => handleFavorite(row.company_id)}
                                src={removeIcon}
                                alt="remove"
                                className="removeIcon"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </StyledTableContainer>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <StyledButtonPrimary onClick={handleInviteModalOpen}>
                    {t("invite a new supplier")}
                  </StyledButtonPrimary>
                </Grid>
              </Grid>
            </StyledMainWrapper>
          </StyledMain>
        </Fragment>
      )}
    </Fragment>
  );
});

export default MySuppliers;
