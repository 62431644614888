import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { view } from "@risingstack/react-easy-state";

import { language } from "stores/language";

import { Box, Grid, Container } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import logo from "assets/img/logo.svg";

const StyledHeaderWrapper = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  height: 75px;
  width: 100%;
  background-color: #000000;
  z-index: 3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const StyledLink = styled(Link)`
  display: block;
`;

const StyledToolbarButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 12px;
  }

  span {
    color: #06d7f9;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const Header = view(() => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickLanguage = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseLanguage = () => {
    setAnchorEl(null);
  };

  const handlePickLanguage = (lang) => {
    language.setLanguage(lang);
    setAnchorEl(null);
  };

  return (
    <StyledHeaderWrapper>
      <Container fixed>
        <Grid container>
          <Grid item xs={12}>
            <StyledWrapper>
              <StyledLink to="/">
                <img src={logo} alt="logo" />
              </StyledLink>
              <StyledToolbarButton
                aria-controls="language-menu"
                aria-haspopup="true"
                onClick={handleClickLanguage}
              >
                <span>{language.language.toUpperCase()}</span>
              </StyledToolbarButton>
              <Menu
                id="language-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseLanguage}
              >
                {language.languages.map((lang) => {
                  if (lang !== language.language) {
                    return (
                      <MenuItem
                        key={lang}
                        onClick={() => handlePickLanguage(lang)}
                      >
                        {lang.toUpperCase()}
                      </MenuItem>
                    );
                  } else {
                    return null;
                  }
                })}
              </Menu>
            </StyledWrapper>
          </Grid>
        </Grid>
      </Container>
    </StyledHeaderWrapper>
  );
});

export default Header;
