import { auth } from "stores/auth";

export async function authFetch(url, params) {
  // allow custom authorization
  if (!params?.headers?.Authorization) {
    params.headers = {
      ...(params.headers ?? null),
      Authorization: `Bearer ${auth.user?.signInUserSession.idToken.jwtToken}`,
    };
  }
  return await fetch(url, {
    ...params,
  });
}

export function formatCurrency(amount) {
  const formattedAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2, // Ensure two decimal places
    maximumFractionDigits: 2, // Ensure two decimal places
  }).format(amount / 100); // Divide by 100 to adjust for cents

  return formattedAmount;
}
