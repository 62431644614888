import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { autoEffect, view } from "@risingstack/react-easy-state";

import { auth } from "stores/auth";

import Loader from "components/shared/parts/Loader";

import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import {
  StyledMain,
  StyledMainWrapper,
  StyledSpacer,
  StyledTableContainer,
} from "./styled";
import MainModal from "components/shared/parts/MainModal";
import { dashboards } from "stores/dashboards";
import moment from "moment";
import { StyledSeeAllButton } from "../Dashboard/styled";

const CustomSearches = view(() => {
  const [customSearchesRows, setCustomSearchesRows] = useState([]);
  const [customSearchesModalOpen, setCustomSearchesModalOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    dashboards.buyerDashboard = null;
    dashboards.getBuyerDashboard();
  }, []);

  const handleCustomSearchesModalOpen = () => {
    setCustomSearchesModalOpen(true);
  };

  const handleCustomSearchesModalClose = () => {
    setCustomSearchesModalOpen(false);
  };

  const createCustomSearchesData = (id, product_name, industry, date) => {
    return { id, product_name, industry, date };
  };

  autoEffect(() => {
    if (
      dashboards.buyerDashboard &&
      dashboards.buyerDashboard.CustomSearches?.length > 0
    ) {
      setCustomSearchesRows(
        dashboards.buyerDashboard.CustomSearches.map((search) => {
          return createCustomSearchesData(
            search.id ? search.id : null,
            search.product_name ? search.product_name : t("no data"),
            search.industry ? search.industry : t("no data"),
            search.created_at
              ? moment(search.created_at).format("DD.MM.YYYY.")
              : t("no data")
          );
        })
      );
    }
  });

  return (
    <Fragment>
      {!auth.user || !customSearchesRows ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          <StyledMain>
            <StyledMainWrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <MainModal
                    modalOpen={customSearchesModalOpen}
                    handleModalClose={handleCustomSearchesModalClose}
                  >
                    <StyledTableContainer>
                      <Table aria-label="shortlisted table">
                        <TableHead>
                          <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>{t("industry")}</TableCell>
                            <TableCell align="right">
                              {t("product name")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {customSearchesRows.map((row, index) => (
                            <TableRow
                              key={row.id}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <Link to={`/custom-search-results/${row.id}`}>
                                  {row.id}
                                </Link>
                              </TableCell>
                              <TableCell>{row.industry}</TableCell>
                              <TableCell align="right">
                                {row.product_name}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  </MainModal>
                  <h3>
                    {t("custom searches")}{" "}
                    <span className={"separator"}>|</span>{" "}
                    <span>{customSearchesRows.length}</span>
                  </h3>
                  {customSearchesRows.length > 0 ? (
                    <StyledTableContainer>
                      <Table aria-label="shortlisted table">
                        <TableHead>
                          <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>{t("industry")}</TableCell>
                            <TableCell align="right">
                              {t("product name")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {customSearchesRows.map((row, index) => {
                            if (index <= 4) {
                              return (
                                <TableRow
                                  key={row.id}
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    <Link
                                      to={`/custom-search-results/${row.id}`}
                                    >
                                      {row.id}
                                    </Link>
                                  </TableCell>
                                  <TableCell>{row.industry}</TableCell>
                                  <TableCell align="right">
                                    {row.product_name}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                            return null;
                          })}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  ) : null}
                  {customSearchesRows.length > 5 ? (
                    <StyledSeeAllButton onClick={handleCustomSearchesModalOpen}>
                      {t("see all")}
                    </StyledSeeAllButton>
                  ) : null}
                </Grid>
              </Grid>
            </StyledMainWrapper>
          </StyledMain>
        </Fragment>
      )}
    </Fragment>
  );
});

export default CustomSearches;
