import { createGlobalStyle } from "styled-components";
import { createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import "@aws-amplify/ui/dist/style.css";

import RobotoLight from "../assets/fonts/Roboto/Roboto-Light.ttf";
import RobotoRegular from "../assets/fonts/Roboto/Roboto-Regular.ttf";
import RobotoMedium from "../assets/fonts/Roboto/Roboto-Medium.ttf";
import RobotoBold from "../assets/fonts/Roboto/Roboto-Bold.ttf";
import RobotoBlack from "../assets/fonts/Roboto/Roboto-Black.ttf";
import NeueMachinaLight from "../assets/fonts/NeueMachina/NeueMachina-Light.otf";
import NeueMachinaRegular from "../assets/fonts/NeueMachina/NeueMachina-Regular.otf";
import NeueMachinaUltrabold from "../assets/fonts/NeueMachina/NeueMachina-Ultrabold.otf";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'RobotoLight';
    src: local('RobotoLight'), local('RobotoLight'),
    url(${RobotoLight}) format('truetype')
  }
  @font-face {
    font-family: 'RobotoRegular';
    src: local('RobotoRegular'), local('RobotoRegular'),
    url(${RobotoRegular}) format('truetype')
  }
  @font-face {
    font-family: 'RobotoMedium';
    src: local('RobotoMedium'), local('RobotoMedium'),
    url(${RobotoMedium}) format('truetype')
  }
  @font-face {
    font-family: 'RobotoBold';
    src: local('RobotoBold'), local('RobotoBold'),
    url(${RobotoBold}) format('truetype')
  }
  @font-face {
    font-family: 'RobotoBlack';
    src: local('RobotoBlack'), local('RobotoBlack'),
    url(${RobotoBlack}) format('truetype')
  }
  @font-face {
    font-family: 'NeueMachinaLight';
    src: local('NeueMachinaLight'), local('NeueMachinaLight'),
    url(${NeueMachinaLight}) format('opentype')
  }
  @font-face {
    font-family: 'NeueMachinaRegular';
    src: local('NeueMachinaRegular'), local('NeueMachinaRegular'),
    url(${NeueMachinaRegular}) format('opentype')
  }
  @font-face {
    font-family: 'NeueMachinaUltrabold';
    src: local('NeueMachinaUltrabold'), local('NeueMachinaUltrabold'),
    url(${NeueMachinaUltrabold}) format('opentype')
  }

  body {
    color: #000000;
    background-color: #ffffff;
    font-family: 'RobotoRegular', 'Arial', sans-serif;
  }

  .MuiTypography-body1 {
    color: #000000;
    font-family: 'RobotoRegular', 'Arial', sans-serif;
  }

  h1,h2,h3,h4,h5,h6 {
    color: #000000;
    font-family: 'NeueMachinaRegular', 'Arial', sans-serif;
  }

  a {
    outline: 0;
  }

  amplify-authenticator {
    --box-shadow: none;
    --container-display: block;
    --container-height: 100%;
    text-align: center;
  }

  :root {
    --amplify-font-family: 'RobotoRegular', 'Arial', sans-serif;
    --amplify-primary-color: #00bfe9;
    --amplify-secondary-tint: #00bfe9;
    --amplify-background-color: transparent;
    --amplify-primary-tint: #00bfe9;
    --amplify-primary-shade: #00bfe9;
    --amplify-text-md-sub: 36px;
  }

  main {
    width: 100%;
  }
  
  #SRLLightbox {
    z-index: 99999;
  }
`;

export const darkTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#06d6f8",
    },
  },
  props: {
    MuiButtonBase: {
      // The properties to apply
      // disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  overrides: {
    // MuiIconButton: {
    //   root: {
    //     "&:hover": {
    //       backgroundColor: "transparent !important",
    //     },
    //   },
    // },
    MuiPickersDay: {
      day: {
        borderRadius: "0 !important",
      },
      daySelected: {
        backgroundColor: "#00C9E9",
        "&:hover": {
          backgroundColor: "#00C9E9",
        },
      },
    },
  },
});

export const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

export const useStyles = makeStyles({
  root: {
    zIndex: 9999,
  },
});
