import React, { Fragment } from "react";
import { Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { view, autoEffect } from "@risingstack/react-easy-state";

import { auth } from "stores/auth";
import { support } from "stores/support";

import Loader from "components/shared/parts/Loader";

import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem";

import {
  menuItemStyle,
  StyledButtonPrimary,
  StyledField,
  StyledInputLabel,
  StyledIntro,
  StyledIntroWrapper,
  StyledMain,
  StyledMainWrapper,
  StyledSectionInner,
  StyledSectionOuter,
  StyledSelect,
  StyledSpacer,
} from "./styled";

const Help = view((props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();

  const validate = (values) => {
    const errors = {};

    if (!values.topic) {
      errors.topic = t("topic required");
    }
    if (!values.message) {
      errors.message = t("message required");
    }

    return errors;
  };

  autoEffect(() => {
    if (support.errors) {
      props.enqueueSnackbar(t("something went wrong"), {
        variant: "error",
      });
    }

    if (support.sent) {
      props.enqueueSnackbar(t("sent successfully"), {
        variant: "success",
      });
    }
  });

  return (
    <Fragment>
      {!auth.user ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"center"}
              >
                <Grid item xs={12}>
                  <h2>{t("contact our support")}</h2>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
          </StyledIntro>
          <StyledMain>
            <StyledMainWrapper>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"center"}
              >
                <Grid item xs={12}>
                  <StyledSectionOuter>
                    <StyledSectionInner>
                      <Formik
                        initialValues={{
                          message: "",
                          topic: "",
                        }}
                        validate={validate}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(false);
                          await support.sendSupport(values);
                          if (support.sent) {
                            resetForm();
                          }
                        }}
                      >
                        {({
                          submitForm,
                          isSubmitting,
                          setFieldValue,
                          errors,
                        }) => (
                          <Form>
                            <StyledSelect
                              fullWidth
                              variant="outlined"
                              size="small"
                              error={errors.topic}
                            >
                              <StyledInputLabel
                                htmlFor="topic"
                                shrink={true}
                                variant="outlined"
                                required
                              >
                                {t("topic")}
                              </StyledInputLabel>
                              <Select
                                onChange={(event) =>
                                  setFieldValue("topic", event.target.value)
                                }
                                id="topic"
                                name="topic"
                              >
                                <MenuItem value=""></MenuItem>
                                <ListSubheader disableSticky>
                                  My Account
                                </ListSubheader>
                                <MenuItem
                                  value={"Creating a New Account"}
                                  style={menuItemStyle}
                                >
                                  Creating a New Account
                                </MenuItem>
                                <MenuItem
                                  value={"Account Termination / Delete"}
                                  style={menuItemStyle}
                                >
                                  Account Termination / Delete
                                </MenuItem>
                                <MenuItem
                                  value={"I Want to Change Account Type"}
                                  style={menuItemStyle}
                                >
                                  I Want to Change Account Type
                                </MenuItem>
                                <ListSubheader disableSticky>
                                  Security
                                </ListSubheader>
                                <MenuItem
                                  value={"Forgot Password"}
                                  style={menuItemStyle}
                                >
                                  Forgot Password
                                </MenuItem>
                                <MenuItem
                                  value={"Account abuse"}
                                  style={menuItemStyle}
                                >
                                  Account abuse
                                </MenuItem>
                                <ListSubheader disableSticky>
                                  Troubleshooting
                                </ListSubheader>
                                <MenuItem
                                  value={
                                    "I Changed my Company, How Can I De-Assign Myself From Current One"
                                  }
                                  style={menuItemStyle}
                                >
                                  I Changed my Company, How Can I De-Assign
                                  Myself From Current One
                                </MenuItem>
                                <MenuItem
                                  value={
                                    "Someone Else has Rights to Edit my Company"
                                  }
                                  style={menuItemStyle}
                                >
                                  Someone Else has Rights to Edit my Company
                                </MenuItem>
                                <MenuItem
                                  value={
                                    "I Want to Transfer Company Rights to Another User"
                                  }
                                  style={menuItemStyle}
                                >
                                  I Want to Transfer Company Rights to Another
                                  User
                                </MenuItem>
                                <MenuItem
                                  value={
                                    "I Have Problems with Receiving Emails and Notifications"
                                  }
                                  style={menuItemStyle}
                                >
                                  I Have Problems with Receiving Emails and
                                  Notifications
                                </MenuItem>
                                <ListSubheader disableSticky>
                                  General
                                </ListSubheader>
                                <MenuItem
                                  value={"About Yvoo"}
                                  style={menuItemStyle}
                                >
                                  About Yvoo
                                </MenuItem>
                                <MenuItem value={"Other"} style={menuItemStyle}>
                                  Other
                                </MenuItem>
                              </Select>
                              {errors.topic ? (
                                <FormHelperText>{errors.topic}</FormHelperText>
                              ) : null}
                              {/* <FormHelperText>{error}</FormHelperText>; */}
                            </StyledSelect>
                            <StyledInputLabel
                              htmlFor="message"
                              shrink={true}
                              variant="outlined"
                              required
                            >
                              {t("message")}
                            </StyledInputLabel>
                            <StyledField
                              component={TextField}
                              name="message"
                              autoComplete="off"
                              fullWidth
                              variant="outlined"
                              size="small"
                              multiline
                              rows={4}
                            />
                            {isSubmitting}
                            <br />
                            <StyledButtonPrimary
                              type="button"
                              disabled={isSubmitting}
                              onClick={submitForm}
                            >
                              {t("send message")}
                            </StyledButtonPrimary>
                          </Form>
                        )}
                      </Formik>
                    </StyledSectionInner>
                  </StyledSectionOuter>
                </Grid>
              </Grid>
            </StyledMainWrapper>
          </StyledMain>
        </Fragment>
      )}
    </Fragment>
  );
});

export default withSnackbar(Help);
