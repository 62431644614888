import React from "react";
import styled from "styled-components";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";

const SModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SModalContainer = styled(Box)`
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
  max-height: 60vh;
  max-width: 600px;
  margin: 2rem;
  padding: 0;
  overflow-y: auto;

  form {
    height: 100%;
    margin: 4rem 3rem;
  }
`;

const MainModal = (props) => {
  return (
    <SModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.modalOpen}
      onClose={props.handleModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.modalOpen}>
        <SModalContainer>{props.children}</SModalContainer>
      </Fade>
    </SModal>
  );
};

export default MainModal;
