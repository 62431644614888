import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { autoEffect, view } from "@risingstack/react-easy-state";

import { auth } from "stores/auth";

import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignUp,
  AmplifyConfirmSignIn,
  AmplifyConfirmSignUp,
  AmplifyForgotPassword,
} from "@aws-amplify/ui-react";
import { onAuthUIStateChange, Translations } from "@aws-amplify/ui-components";
import { I18n } from "aws-amplify";
import { Hub } from "@aws-amplify/core";
import {
  UI_AUTH_CHANNEL,
  TOAST_AUTH_ERROR_EVENT,
} from "@aws-amplify/ui-components";

import AuthStateHandler from "./AuthStateHandler";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import signUpEllipseLeft from "assets/img/signUpEllipseLeft.svg";
import signUpEllipseRight from "assets/img/signUpEllipseRight.svg";
import signUpArrowLeft from "assets/img/signUpArrowLeft.svg";
import signUpArrowRight from "assets/img/signUpArrowRight.svg";
import { useHistory } from "react-router-dom";
import LoaderAbsolute from "../parts/LoaderAbsolute";
import amplifyNotification from "assets/img/amplifyNotification.svg";

import {
  StyledAuthWrapper,
  StyledGraphicInner,
  StyledGraphicWrapper,
  StyledMainWrapper,
  StyledToast,
  StyledToastWrapper,
  StyledTypeBox,
  StyledTypeWrapper,
  StyledWrapperOuter,
} from "./styled";

const Auth = view(() => {
  const [userType, setUserType] = useState("yvoo-supplier");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const params = useParams();
  const history = useHistory();
  const [signupUsername, setSignupUsername] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [signupName, setSignupName] = useState("");
  const [signupCompanyName, setSignupCompanyName] = useState("");
  const [signupPhone, setSignupPhone] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    if (params.slot && params.slot === "signup" && params.type) {
      if (params.type === "supplier") {
        setUserType("yvoo-supplier");
      } else if (params.type === "buyer") {
        setUserType("yvoo-buyer");
      }
    }
  }, [params]);

  const handleToastErrors = ({ payload }) => {
    if (payload.event === TOAST_AUTH_ERROR_EVENT && payload.message) {
      setAlertMessage(payload.message);
    }
  };

  useEffect(() => {
    Hub.listen(UI_AUTH_CHANNEL, handleToastErrors);
    return () => Hub.remove(UI_AUTH_CHANNEL, handleToastErrors);
  });

  const handleUserType = (type) => {
    setUserType(type);
    history.push(
      `/login/signup/${type === "yvoo-buyer" ? "buyer" : "supplier"}`
    );
    // window.location.reload();
  };

  autoEffect(() => {
    if (
      auth.authState !== "signedIn" &&
      auth.authState !== "signup" &&
      auth.authState
    ) {
      history.push("/login");
    }
  });

  const authScreenLabels = {
    en: {
      "Sign in to your account": t("sign in"),
      "Enter your username": t("enter your email"),
      "Create a new account": "signUpMessage",
      "Reset your password": t("reset password"),
      "Confirmation Code": t("confirmation code (sent to email)"),
      "Create account": t("sign up"),
      "Forgot your password?": t("forgot your password?"),
      "Reset password": t("reset password"),
      "Sign In": t("sign in"),
      "No account?": t("no account?"),
      "Create Account": t("create account"),
      "Sign in": t("sign in"),
      "Have an account?": t("have an account?"),
      "Confirm Sign up": t("confirm sign up"),
      "Lost your code?": t("lost your code?"),
      "Resend Code": t("resend code"),
      Confirm: t("confirm"),
      "Back to Sign In": t("back to sign in"),
      "Send Code": t("send code"),
      "Verification code": t("verification code"),
      "New password": t("new password"),
      Submit: t("submit"),
      [Translations.EMAIL_LABEL]: t("email"),
      [Translations.EMAIL_PLACEHOLDER]: t("enter your email address"),
      [Translations.PASSWORD_LABEL]: t("password"),
      [Translations.PASSWORD_PLACEHOLDER]: t("enter your password"),
      [Translations.CODE_LABEL]: t("verification code"),
      [Translations.CODE_PLACEHOLDER]: t("enter code"),
      [Translations.NEW_PASSWORD_LABEL]: t("new password"),
      [Translations.NEW_PASSWORD_PLACEHOLDER]: t("enter your new password"),
    },
  };

  I18n.setLanguage("en");
  I18n.putVocabularies(authScreenLabels);

  const removeAutoComplete = () => {
    const allInputs = document.querySelectorAll("input");

    allInputs.forEach((input) => {
      input.setAttribute("autoComplete", "off");
    });
  };

  useEffect(() => {
    removeAutoComplete();
    // eslint-disable-next-line
  }, [auth.authState]);

  autoEffect(() => {
    setLoading(true);
    let authenticator = null;

    if (auth.authState === "signin") {
    }

    switch (auth.authState) {
      case "signin":
        authenticator = document.querySelector("amplify-sign-in");
        break;
      case "confirmSignIn":
        authenticator = document.querySelector("amplify-confirm-sign-in");
        break;
      case "signup":
        authenticator = document.querySelector("amplify-sign-up");
        break;
      case "confirmSignUp":
        authenticator = document.querySelector("amplify-confirm-sign-up");
        break;
      case "forgotpassword":
        authenticator = document.querySelector("amplify-forgot-password");
        break;

      default:
        authenticator = null;
        setLoading(false);
        break;
    }

    if (authenticator) {
      setTimeout(() => {
        applyStyles(authenticator);
      }, 500);
    }
  }, [auth.authState]);

  const applyStyles = (element) => {
    let childrenNodeArr = Array.from(element.shadowRoot.children);

    const amplifyFormSection = childrenNodeArr.find((e) => {
      return e.tagName === "AMPLIFY-FORM-SECTION";
    });

    if (amplifyFormSection) {
      let childrenNodeArr = Array.from(amplifyFormSection.shadowRoot.children);

      const amplifyForm = childrenNodeArr.find((e) => {
        return e.tagName === "FORM";
      });

      if (amplifyForm) {
        let childrenNodeArr = Array.from(amplifyForm.children);

        const amplifySection = childrenNodeArr.find((e) => {
          return e.tagName === "AMPLIFY-SECTION";
        });

        if (amplifySection) {
          const sectionHeaderDiv = amplifySection.children[0];
          const sectionFooterDiv = amplifySection.children[2];

          if (sectionHeaderDiv) {
            let childrenNodeArr = Array.from(sectionHeaderDiv.children);

            const headerSlot = childrenNodeArr.find((e) => {
              const nameValue = e.getAttribute("name");
              return nameValue === "amplify-form-section-header";
            });

            if (headerSlot) {
              let childrenNodeArr = Array.from(headerSlot.children);

              const headerElement = childrenNodeArr.find((e) => {
                return e.classList.contains("form-section-header");
              });

              if (headerElement) {
                headerElement.style.textAlign = "center";
                headerElement.style.textTransform = "uppercase";
                headerElement.style.color = "#000000";

                let childrenNodeArr = Array.from(headerElement.children);

                const headerTitle = childrenNodeArr.find((e) => {
                  return e.classList.contains("header");
                });

                if (headerTitle) {
                  headerTitle.style.fontSize = "24px";
                }
              }
            }
          }

          if (sectionFooterDiv) {
            let childrenNodeArr = Array.from(sectionFooterDiv.children);

            const footerSlot = childrenNodeArr.find((e) => {
              const nameValue = e.getAttribute("name");
              return nameValue === "amplify-form-section-footer";
            });

            if (footerSlot) {
              let childrenNodeArr = Array.from(footerSlot.children);

              const footerElement = childrenNodeArr.find((e) => {
                return e.classList.contains("form-section-footer");
              });

              if (footerElement) {
                footerElement.style.display = "flex";
                footerElement.style.flexDirection = "column";
                footerElement.style.alignItems = "center";
                footerElement.style.justifyContent = "space-around";

                let childrenNodeArr = Array.from(footerElement.children);

                const footerButton = childrenNodeArr.find((e) => {
                  return e.tagName === "AMPLIFY-BUTTON";
                });

                if (footerButton) {
                  footerButton.style.marginBottom = "5px";
                  footerButton.style.width = "100%";
                }
              }
            }
          }
        }
      }
    }

    if (element.tagName === "AMPLIFY-SIGN-IN") {
      const style = document.createElement("style");
      style.innerHTML =
        ".sign-in-form-footer{display: flex !important;flex-direction: column-reverse !important;align-items: center !important;justify-content: space-around !important;}" +
        ".sign-in-form-footer amplify-button{margin-bottom: 20px !important; width: 100% !important;}";
      element.shadowRoot.appendChild(style);
    }

    if (element.tagName === "AMPLIFY-SIGN-UP") {
      const style = document.createElement("style");
      style.innerHTML =
        ".sign-up-form-footer{display: flex !important;flex-direction: column-reverse !important;align-items: center !important;justify-content: space-around !important;}" +
        ".sign-up-form-footer amplify-button{margin-bottom: 20px !important; width: 100% !important;}";
      element.shadowRoot.appendChild(style);
    }

    setLoading(false);
  };

  autoEffect(() => {
    if (auth.authState) {
      setAlertMessage("");
    }

    return onAuthUIStateChange((nextAuthState) => {
      auth.authState = nextAuthState;
    });
  }, [auth.authState]);

  return (
    <StyledAuthWrapper>
      {/* <Authenticator
        signInConfig={signInConfig}
        signUpConfig={signUpConfig}
        usernameAttributes="Email"
        hide={[Greetings]}
      >
        <AuthStateHandler />
      </Authenticator> */}
      {loading ? <LoaderAbsolute bg="#ffffff" /> : null}
      {alertMessage && (
        <StyledToast>
          <StyledToastWrapper>
            <img src={amplifyNotification} alt="notification" />
            {alertMessage}
          </StyledToastWrapper>
        </StyledToast>
      )}
      <AmplifyAuthenticator
        usernameAlias="email"
        hideToast
        initialAuthState={
          params.slot && params.slot === "signup" ? "signup" : "signin"
        }
      >
        <StyledWrapperOuter slot="sign-in">
          <h4>{t("the easy of procurement")}</h4>
        </StyledWrapperOuter>
        <StyledWrapperOuter slot="sign-up">
          <h2>{t("sign up")}</h2>
          <p>{t("please select")}</p>
          <StyledMainWrapper>
            <StyledTypeWrapper
              border="#02DFAD"
              style={matches ? { marginRight: "40px" } : null}
            >
              <StyledTypeBox
                border="#02DFAD"
                selected={userType === "yvoo-buyer"}
                onClick={() => handleUserType("yvoo-buyer")}
              >
                <div></div>
              </StyledTypeBox>
              <h3>{t("buyer")}</h3>
              <h4>{t("the intelligent way of procurement")}</h4>
            </StyledTypeWrapper>
            <StyledGraphicWrapper style={matches ? null : { display: "none" }}>
              <StyledGraphicInner onClick={() => handleUserType("yvoo-buyer")}>
                <img src={signUpEllipseLeft} alt="ellipse left" />
                <img
                  src={signUpArrowLeft}
                  alt="arrow left"
                  style={{ position: "absolute", left: "-16px" }}
                />
              </StyledGraphicInner>
              <StyledGraphicInner
                onClick={() => handleUserType("yvoo-supplier")}
              >
                <img src={signUpEllipseRight} alt="ellipse right" />
                <img
                  src={signUpArrowRight}
                  alt="arrow right"
                  style={{ position: "absolute", right: "-16px" }}
                />
              </StyledGraphicInner>
            </StyledGraphicWrapper>
            <StyledTypeWrapper
              border="#06D6F8"
              style={matches ? { marginLeft: "40px" } : null}
            >
              <StyledTypeBox
                border="#06D6F8"
                selected={userType === "yvoo-supplier"}
                onClick={() => handleUserType("yvoo-supplier")}
              >
                <div></div>
              </StyledTypeBox>
              <h3>{t("supplier")}</h3>
              <h4>{t("your forecast of success")}</h4>
            </StyledTypeWrapper>
          </StyledMainWrapper>
        </StyledWrapperOuter>
        <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          headerText=""
          formFields={[
            {
              label: t("email"),
              placeholder: t("enter your email address"),
              key: "email",
              required: true,
              type: "email",
              autocomplete: "off",
              handleInputChange: (e) => {
                setSignupUsername(e.target.value);
              },
              value: signupUsername,
            },
            {
              label: t(
                "password (at least 8 characters, 1 uppercase letter and 1 number)"
              ),
              placeholder: t("enter your password"),
              key: "password",
              required: true,
              type: "password",
              handleInputChange: (e) => {
                setSignupPassword(e.target.value);
              },
              value: signupPassword,
            },
            {
              label: t("full name"),
              placeholder: t("enter your full name"),
              key: "name",
              required: true,
              type: "name",
              handleInputChange: (e) => {
                setSignupName(e.target.value);
              },
              value: signupName,
            },
            {
              label: t("company name"),
              placeholder: t("enter your company name"),
              key: "custom:company_name",
              required: false,
              type: "custom:company_name",
              handleInputChange: (e) => {
                setSignupCompanyName(e.target.value);
              },
              value: signupCompanyName,
            },
            {
              label: t("mobile phone"),
              placeholder: t("enter your mobile phone number"),
              key: "custom:mobile_phone",
              required: false,
              type: "custom:mobile_phone",
              handleInputChange: (e) => {
                setSignupPhone(e.target.value);
              },
              value: signupPhone,
            },
            {
              label: `${t("user type")}: ${
                userType === "yvoo-buyer"
                  ? t("buyer")
                  : userType === "yvoo-supplier"
                  ? t("supplier")
                  : t("not selected")
              }`,
              type: "custom:user_type",
              key: "custom:user_type",
              required: true,
              inputProps: {
                type: "hidden",
              },
              value: userType,
            },
          ]}
        />
        <AmplifySignIn slot="sign-in" usernameAlias="email" />
        <AmplifyConfirmSignIn slot="confirm-sign-in" usernameAlias="email" />
        <AmplifyConfirmSignUp slot="confirm-sign-up" usernameAlias="email" />
        <AmplifyForgotPassword slot="forgot-password" usernameAlias="email" />

        <AuthStateHandler authState={auth.authState} />
      </AmplifyAuthenticator>
    </StyledAuthWrapper>
  );
});

export default Auth;
