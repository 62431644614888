import { store } from "@risingstack/react-easy-state";

export const language = store({
  languages: ["en", "ru", "de"],
  language: "en",
  async getLanguage() {
    const storedLang = localStorage.getItem("language");
    if (storedLang) {
      language.language = storedLang;
    }
  },
  async setLanguage(lang) {
    localStorage.setItem("language", lang);
    language.language = lang;
  },
});