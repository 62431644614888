import styled from "styled-components";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

export const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

export const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  height: 100%;
`;

export const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  height: 100%;
`;

export const StyledGridFullHeight = styled(Grid)`
  height: 100%;
`;

/* ===================================================== */

export const StyledWrapper = styled(Box)`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSearchWrapper = styled(Box)`
  position: absolute;
  z-index: 3;
  width: 100%;
  bottom: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledImage = styled.img`
  position: relative;
  max-width: 100%;
  width: 100%;
  z-index: 2;
`;

export const StyledSearchButton = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    marginRight: "20px",
    "@media (max-width: 959px)": {
      width: "100%",
    },
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "none",
    borderWidth: "0 !important",
    boxShadow: "none",
    borderBottom: "1px solid #ffffff !important",
    outline: "none",
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  // placeholder: (provided) => ({
  //   ...provided,
  //   color: "#0091d4",
  //   fontSize: "16px",
  // }),
  // input: (provided) => ({
  //   ...provided,
  //   color: "#0091d4",
  //   fontSize: "16px",
  // }),
  // valueContainer: (provided) => ({
  //   ...provided,
  //   padding: "17px",
  // }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
  }),
  // option: (provided) => ({
  //   ...provided,
  //   backgroundColor: "transparent",
  //   padding: "17px",
  //   "&:hover": {
  //     color: "#0091d4",
  //     cursor: "pointer",
  //   },
  // }),
};
