import styled from "styled-components";

import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

export const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

export const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;
  padding-left: 24px;
  padding-right: 24px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
  }
`;

export const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  margin-bottom: 60px;

  h3 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin: 0;

    a {
      text-decoration: none;
      color: #00c9e9;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const StyledMain = styled(Box)`
  width: 100%;
  position: relative;
  z-index: 2;
  padding-left: 24px;
  padding-right: 24px;
  border-top: 1px solid #dddddd;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
  }
`;

export const StyledMainWrapper = styled(Container)`
  width: 100%;
  position: relative;
  z-index: 2;
  padding-bottom: 60px;
  padding-top: 60px;

  h3 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-weight: normal;
    font-size: 21px;
    line-height: 24px;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
    margin-bottom: 36px;

    span {
      color: #808285;

      &.separator {
        color: #cfcfcf;
      }
    }
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  .MuiTable-root {
    border-collapse: separate;
  }

  .MuiTableCell-root {
    border-bottom: none;
  }

  .MuiTableHead-root {
    background-color: #000000;

    .MuiTableCell-head {
      font-family: "RobotoRegular", "Arial", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      color: #ffffff;
    }
  }

  .MuiTableCell-body {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;

    a {
      text-decoration-line: underline;
      color: #00c9e9;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

// export const StyledSpecialCell = styled.span`
//   font-family: "RobotoRegular", "Arial", sans-serif;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 14px;
//   line-height: 13px;
//   text-transform: uppercase;
//   color: #00bfe9;
// `;

export const StyledCountWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 255px;

  h1 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 45px;
    line-height: 45px;
    text-transform: uppercase;
    color: #808285;
    margin: 0;
    margin-right: 13px;
  }

  h3 {
    margin-bottom: 0;
  }
`;

export const StyledImageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  h2 {
    margin-bottom: 4px;
  }

  img {
    height: 85px;
    width: 85px;
    max-width: 100%;
    object-fit: contain;
    margin-right: 16px;
  }
`;

export const StyledRatingBox = styled(Box)``;

export const StyledRating = styled.h3`
  font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 26px;
  text-transform: uppercase;
  color: #000000;
  margin: 0;
  margin-bottom: 6px;

  span {
    color: ${(props) =>
      props.score < 20
        ? "#FF490F"
        : props.score >= 20 && props.score < 40
        ? "#FF7B51"
        : props.score >= 40 && props.score < 60
        ? "#FFC527"
        : props.score >= 60 && props.score < 80
        ? "#06D7F9"
        : props.score >= 80
        ? "#01E7AA"
        : "#000000"};
  }
`;

export const StyledTableCell = styled(TableCell)`
  position: relative;
`;

export const StyledTableCellBorder = styled(Box)`
  position: absolute;
  right: 0;
  bottom: 0;
  height: calc(100% - 2px);
  width: 10px;
  background-color: ${(props) =>
    props.status === "open"
      ? "#FFC527"
      : props.status === "complete"
      ? "#01E7AA"
      : "#FFFFFF"};
`;

export const StyledSeeAllButton = styled.span`
  display: block;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #474747;
  margin-top: 20px;
  cursor: pointer;
`;

export const StyledLink = styled(Link)`
  color: #00c9e9;
`;
