import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { autoEffect, view } from "@risingstack/react-easy-state";

import { auth } from "stores/auth";
import { customSearch } from "stores/customSearch";
import { dashboards } from "stores/dashboards";

import MainModal from "components/shared/parts/MainModal";
import Loader from "components/shared/parts/Loader";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import {
  StyledIntro,
  StyledIntroWrapper,
  StyledMain,
  StyledMainWrapper,
  StyledSeeAllButton,
  StyledSpacer,
  StyledSpecialCell,
  StyledTableCell,
  StyledTableCellBorder,
  StyledTableContainer,
} from "./styled";
import { QuickscansTable } from "../MyQuickscans";

const Dashboard = view(() => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [rfiRows, setRfiRows] = useState([]);
  const [favoritesRows, setFavoritesRows] = useState([]);
  const [shortlistedRows, setShortlistedRows] = useState([]);
  const [customSearchesRows, setCustomSearchesRows] = useState([]);
  const [rfiModalOpen, setRfiModalOpen] = useState(false);
  const [customSearchesModalOpen, setCustomSearchesModalOpen] = useState(false);
  const [favoritesModalOpen, setFavoritesModalOpen] = useState(false);
  const [shortlistedModalOpen, setShortlistedModalOpen] = useState(false);
  const [quickscanModalOpen, setQuickscanModalOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    dashboards.buyerDashboard = null;
    dashboards.getBuyerDashboard();
    customSearch.getCustomSearches();
  }, []);

  const createRFIData = (
    id,
    product_name,
    company_id,
    company_name,
    date,
    rfi_deadline
  ) => {
    return { id, product_name, company_id, company_name, date, rfi_deadline };
  };

  const createQuickscanData = (id, company_id, company_name, date, status) => {
    return { id, company_id, company_name, date, status };
  };

  const createFavoritesData = (id, company_id, company_name, date) => {
    return { id, company_id, company_name, date };
  };

  const createShortlistedData = (id, company_name, date, input) => {
    return { id, company_name, date, input };
  };

  const createCustomSearchesData = (id, product_name, industry, date) => {
    return { id, product_name, industry, date };
  };

  autoEffect(() => {
    if (
      dashboards.buyerDashboard &&
      dashboards.buyerDashboard.Rfis?.length > 0
    ) {
      setRfiRows(
        dashboards.buyerDashboard.Rfis.map((rfi) => {
          return createRFIData(
            rfi.id ? rfi.id : null,
            rfi.product_name ? rfi.product_name : t("no data"),
            rfi.supplier && rfi.supplier.id ? rfi.supplier.id : null,
            rfi.supplier && rfi.supplier.name
              ? rfi.supplier.name
              : t("no data"),
            rfi.created_at
              ? moment(rfi.created_at).format("DD.MM.YYYY.")
              : t("no data"),
            rfi.rfi_deadline
              ? moment(rfi.rfi_deadline).format("DD.MM.YYYY.")
              : t("no data")
          );
        })
      );

      if (dashboards.buyerDashboard.Favorites.length > 0)
        setFavoritesRows(
          dashboards.buyerDashboard.Favorites.map((favorite) => {
            return createFavoritesData(
              favorite.id ? favorite.id : null,
              favorite.company && favorite.company.id
                ? favorite.company.id
                : null,
              favorite.company && favorite.company.name
                ? favorite.company.name
                : t("no data"),
              favorite.created_at
                ? moment(favorite.created_at).format("DD.MM.YYYY.")
                : t("no data")
            );
          })
        );

      let sellersArray = [];

      if (dashboards.buyerDashboard.LongLists?.length > 0)
        dashboards.buyerDashboard.LongLists.map((list) => {
          list.sellers.map((seller) => {
            sellersArray.push({
              id: seller.id ? seller.id : null,
              name: seller.name ? seller.name : t("no data"),
              date: seller.created_at
                ? moment(seller.created_at).format("DD.MM.YYYY.")
                : t("no data"),
              input: list.name ? list.name : t("no data"),
            });

            return null;
          });

          return sellersArray;
        });

      if (sellersArray?.length > 0)
        setShortlistedRows(
          sellersArray.map((seller) => {
            return createShortlistedData(
              seller.id,
              seller.name,
              seller.date,
              seller.input
            );
          })
        );

      if (dashboards.buyerDashboard.customSearches?.length > 0)
        setCustomSearchesRows(
          dashboards.buyerDashboard.customSearches.map((search) => {
            return createCustomSearchesData(
              search.id ? search.id : null,
              search.product_name ? search.product_name : t("no data"),
              search.industry ? search.industry : t("no data"),
              search.created_at
                ? moment(search.created_at).format("DD.MM.YYYY.")
                : t("no data")
            );
          })
        );
    }
  });

  const handleRfiModalOpen = () => {
    setRfiModalOpen(true);
  };

  const handleRFIModalClose = () => {
    setRfiModalOpen(false);
  };

  const handleCustomSearchesModalOpen = () => {
    setCustomSearchesModalOpen(true);
  };

  const handleCustomSearchesModalClose = () => {
    setCustomSearchesModalOpen(false);
  };

  const handleFavoritesModalOpen = () => {
    setFavoritesModalOpen(true);
  };

  const handleFavoritesModalClose = () => {
    setFavoritesModalOpen(false);
  };

  const handleShortlistedModalOpen = () => {
    setShortlistedModalOpen(true);
  };

  const handleShortlistedModalClose = () => {
    setShortlistedModalOpen(false);
  };

  const handleQuickscanModalOpen = () => {
    setQuickscanModalOpen(true);
  };

  const handleQuickscanModalClose = () => {
    setQuickscanModalOpen(false);
  };

  return (
    <Fragment>
      {!auth.user ||
      !dashboards.buyerDashboard ||
      dashboards.loading ||
      !rfiRows ||
      !dashboards.buyerDashboard.OrderedQuickScans ||
      !shortlistedRows ||
      !customSearchesRows ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"flex-start"}
              >
                <Grid item xs={12} lg={6}>
                  <h2>{auth.user.dbData.name}</h2>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
          </StyledIntro>
          <StyledMain>
            <StyledMainWrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <MainModal
                    modalOpen={rfiModalOpen}
                    handleModalClose={handleRFIModalClose}
                  >
                    <StyledTableContainer>
                      <Table aria-label="rfi table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("product")}</TableCell>
                            <TableCell>{t("company")}</TableCell>
                            <TableCell>{t("date")}</TableCell>
                            <TableCell align="right">
                              {t("rfq deadline")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rfiRows.map((row, index) => (
                            <TableRow
                              key={row.id}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <Link to={`/rfq/${row.id}`}>
                                  {row.product_name}
                                </Link>
                              </TableCell>
                              <TableCell>
                                <Link to={`/companies/${row.company_id}`}>
                                  <StyledSpecialCell>
                                    {row.company_name}
                                  </StyledSpecialCell>
                                </Link>
                              </TableCell>
                              <TableCell>{row.date}</TableCell>
                              <TableCell align="right">
                                {row.rfi_deadline}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  </MainModal>
                  <h3>
                    {t("sent rfqs")} <span className={"separator"}>|</span>{" "}
                    <span>{rfiRows.length}</span>
                  </h3>
                  {rfiRows.length > 0 ? (
                    <StyledTableContainer>
                      <Table aria-label="rfi table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("product")}</TableCell>
                            <TableCell>{t("company")}</TableCell>
                            <TableCell>{t("date")}</TableCell>
                            <TableCell align="right">
                              {t("rfq deadline")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rfiRows.map((row, index) => {
                            if (index <= 4) {
                              return (
                                <TableRow
                                  key={row.id}
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    <Link to={`/rfq/${row.id}`}>
                                      {row.product_name}
                                    </Link>
                                  </TableCell>
                                  <TableCell>
                                    <Link to={`/companies/${row.company_id}`}>
                                      <StyledSpecialCell>
                                        {row.company_name}
                                      </StyledSpecialCell>
                                    </Link>
                                  </TableCell>
                                  <TableCell>{row.date}</TableCell>
                                  <TableCell align="right">
                                    {row.rfi_deadline}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                            return null;
                          })}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  ) : null}
                  {rfiRows.length > 5 ? (
                    <StyledSeeAllButton onClick={handleRfiModalOpen}>
                      {t("see all")}
                    </StyledSeeAllButton>
                  ) : null}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MainModal
                    modalOpen={customSearchesModalOpen}
                    handleModalClose={handleCustomSearchesModalClose}
                  >
                    <StyledTableContainer>
                      <Table aria-label="shortlisted table">
                        <TableHead>
                          <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>{t("industry")}</TableCell>
                            <TableCell align="right">
                              {t("product name")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {customSearchesRows.map((row, index) => (
                            <TableRow
                              key={row.id}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <Link to={`/custom-search-results/${row.id}`}>
                                  {row.id}
                                </Link>
                              </TableCell>
                              <TableCell>{row.industry}</TableCell>
                              <TableCell align="right">
                                {row.product_name}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  </MainModal>
                  <h3>
                    {t("custom searches")}{" "}
                    <span className={"separator"}>|</span>{" "}
                    <span>{customSearchesRows.length}</span>
                  </h3>
                  {customSearchesRows.length > 0 ? (
                    <StyledTableContainer>
                      <Table aria-label="shortlisted table">
                        <TableHead>
                          <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>{t("industry")}</TableCell>
                            <TableCell align="right">
                              {t("product name")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {customSearchesRows.map((row, index) => {
                            if (index <= 4) {
                              return (
                                <TableRow
                                  key={row.id}
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    <Link
                                      to={`/custom-search-results/${row.id}`}
                                    >
                                      {row.id}
                                    </Link>
                                  </TableCell>
                                  <TableCell>{row.industry}</TableCell>
                                  <TableCell align="right">
                                    {row.product_name}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                            return null;
                          })}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  ) : null}
                  {customSearchesRows.length > 5 ? (
                    <StyledSeeAllButton onClick={handleCustomSearchesModalOpen}>
                      {t("see all")}
                    </StyledSeeAllButton>
                  ) : null}
                </Grid>
              </Grid>
            </StyledMainWrapper>
          </StyledMain>
          <StyledMain>
            <StyledMainWrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <MainModal
                    modalOpen={favoritesModalOpen}
                    handleModalClose={handleFavoritesModalClose}
                  >
                    {favoritesRows.length > 0 ? (
                      <StyledTableContainer>
                        <Table aria-label="favorites table">
                          <TableHead>
                            <TableRow>
                              <TableCell>{t("company name")}</TableCell>
                              <TableCell align="right">{t("date")}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {favoritesRows.map((row, index) => (
                              <TableRow
                                key={row.id}
                                style={{
                                  backgroundColor:
                                    index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <Link to={`/companies/${row.id}`}>
                                    <StyledSpecialCell>
                                      {row.company_name}
                                    </StyledSpecialCell>
                                  </Link>
                                </TableCell>
                                <TableCell align="right">{row.date}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </StyledTableContainer>
                    ) : null}
                  </MainModal>
                  <h3>
                    {t("suppliers added to my suppliers")}{" "}
                    <span className={"separator"}>|</span>{" "}
                    <span>{favoritesRows.length}</span>
                  </h3>
                  {favoritesRows.length > 0 ? (
                    <StyledTableContainer>
                      <Table aria-label="favorites table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("company name")}</TableCell>
                            <TableCell align="right">{t("date")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {favoritesRows.map((row, index) => {
                            if (index <= 4) {
                              return (
                                <TableRow
                                  key={row.id}
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    <Link to={`/companies/${row.company_id}`}>
                                      <StyledSpecialCell>
                                        {row.company_name}
                                      </StyledSpecialCell>
                                    </Link>
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.date}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                            return null;
                          })}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  ) : null}
                  {favoritesRows.length > 5 ? (
                    <StyledSeeAllButton onClick={handleFavoritesModalOpen}>
                      {t("see all")}
                    </StyledSeeAllButton>
                  ) : null}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MainModal
                    modalOpen={shortlistedModalOpen}
                    handleModalClose={handleShortlistedModalClose}
                  >
                    <StyledTableContainer>
                      <Table aria-label="shortlisted table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("company name")}</TableCell>
                            <TableCell>{t("search input")}</TableCell>
                            <TableCell align="right">{t("date")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {shortlistedRows.map((row, index) => (
                            <TableRow
                              key={row.id}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <Link to={`/companies/${row.id}`}>
                                  <StyledSpecialCell>
                                    {row.company_name}
                                  </StyledSpecialCell>
                                </Link>
                              </TableCell>
                              <TableCell>{row.input}</TableCell>
                              <TableCell align="right">{row.date}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  </MainModal>
                  <h3>
                    {t("shortlisted suppliers")}{" "}
                    <span className={"separator"}>|</span>{" "}
                    <span>{shortlistedRows.length}</span>
                  </h3>
                  {shortlistedRows.length > 0 ? (
                    <StyledTableContainer>
                      <Table aria-label="shortlisted table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("company name")}</TableCell>
                            <TableCell>{t("search input")}</TableCell>
                            <TableCell align="right">{t("date")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {shortlistedRows.map((row, index) => {
                            if (index <= 4) {
                              return (
                                <TableRow
                                  key={row.id}
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    <Link to={`/companies/${row.id}`}>
                                      <StyledSpecialCell>
                                        {row.company_name}
                                      </StyledSpecialCell>
                                    </Link>
                                  </TableCell>
                                  <TableCell>{row.input}</TableCell>
                                  <TableCell align="right">
                                    {row.date}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                            return null;
                          })}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  ) : null}
                  {shortlistedRows.length > 5 ? (
                    <StyledSeeAllButton onClick={handleShortlistedModalOpen}>
                      {t("see all")}
                    </StyledSeeAllButton>
                  ) : null}
                </Grid>
              </Grid>
            </StyledMainWrapper>
          </StyledMain>
          <StyledMain>
            <StyledMainWrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <MainModal
                    modalOpen={quickscanModalOpen}
                    handleModalClose={handleQuickscanModalClose}
                  >
                    <QuickscansTable
                      rows={dashboards.buyerDashboard.OrderedQuickScans}
                    />
                  </MainModal>
                  <h3>
                    {t("quick scans ordered")}{" "}
                    <span className={"separator"}>|</span>{" "}
                    <span>
                      {dashboards.buyerDashboard.OrderedQuickScans.length}
                    </span>
                  </h3>
                  {dashboards.buyerDashboard.OrderedQuickScans.length > 0 && (
                    <QuickscansTable
                      limit={5}
                      rows={dashboards.buyerDashboard.OrderedQuickScans}
                    />
                  )}

                  {dashboards.buyerDashboard.OrderedQuickScans.length > 5 ? (
                    <StyledSeeAllButton onClick={handleQuickscanModalOpen}>
                      {t("see all")}
                    </StyledSeeAllButton>
                  ) : null}
                </Grid>
              </Grid>
            </StyledMainWrapper>
          </StyledMain>
          {/* <div>
            <h2>Dashboard</h2>
            {JSON.stringify(customSearch.list)}
          </div> */}
        </Fragment>
      )}
    </Fragment>
  );
});

export default Dashboard;
