import React, { Fragment, useState } from "react";
import { Formik, Form } from "formik";
import { useTranslation, Trans } from "react-i18next";
import { autoEffect, view } from "@risingstack/react-easy-state";

import { company } from "stores/company";
import { lean } from "stores/lean";

import Loader from "components/shared/parts/Loader";

import MainForm from "./FormPage/Forms/MainForm";
import validationSchema from "./FormPage/FormModel/validationSchema";
import formModel from "./FormPage/FormModel/formModel";
import formInitialValues from "./FormPage/FormModel/formInitialValues";

import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  StyledButton,
  StyledContainer,
  StyledFormWrapper,
  StyledIntro,
  StyledIntroWrapper,
  StyledLink,
  StyledSpacer,
} from "./styled";

const { formId, formField } = formModel;

const Lean = view(() => {
  const currentValidationSchema = validationSchema[0];
  const [companyId, setCompanyId] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();

  autoEffect(async () => {
    setInitialValues(null);

    if (!lean.lean) {
      await lean.getLean();
    }
    if (lean.lean) {
      setCompanyId(lean.lean.company_id);
      setInitialValues(lean.lean);
    } else {
      setCompanyId(null);
      setInitialValues(formInitialValues);
    }
  });

  // function _sleep(ms) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }

  async function _submitForm(values, actions) {
    if (companyId) {
      lean.editLean(values, companyId);
    } else {
      lean.addLean(values);
    }
    // alert(JSON.stringify(values, null, 2));
    actions.setSubmitting(false);
  }

  function _handleSubmit(values, actions) {
    _submitForm(values, actions);
  }

  return !initialValues || lean.loading || company.loading ? (
    <Fragment>
      <StyledSpacer />
      <Loader />
    </Fragment>
  ) : !company.company ? (
    <Fragment>
      <StyledIntro>
        <StyledIntroWrapper fixed>
          <Grid
            container
            justify={matches ? "space-between" : "flex-start"}
            alignItems={"center"}
            style={{ marginBottom: "40px" }}
          >
            <Grid item xs={12}>
              <h3>
                <Trans i18nKey="complete-step-one">
                  <StyledLink to="/company-details"></StyledLink>
                </Trans>
              </h3>
            </Grid>
          </Grid>
        </StyledIntroWrapper>
      </StyledIntro>
    </Fragment>
  ) : (
    <React.Fragment>
      <React.Fragment>
        <StyledContainer>
          <StyledFormWrapper fixed>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidationSchema}
              onSubmit={_handleSubmit}
            >
              {({ isSubmitting, setFieldValue, values }) => (
                <Form id={formId}>
                  <MainForm
                    formField={formField}
                    values={values}
                    setFieldValue={setFieldValue}
                    initialValues={initialValues}
                  />
                  <div>
                    <StyledButton disabled={isSubmitting} type="submit">
                      {t("submit")}
                    </StyledButton>
                    {isSubmitting && <Loader />}
                  </div>
                </Form>
              )}
            </Formik>
          </StyledFormWrapper>
        </StyledContainer>
      </React.Fragment>
    </React.Fragment>
  );
});

export default Lean;
