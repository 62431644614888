import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
export default makeStyles((theme) => ({
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
}));

export const customStyles = {
  control: (base, state) => ({
    ...base,
    background: "white",
    minHeight: "40px",
    boxSizing: "border-box",
    marginBottom: "32px",
    boxShadow: "none",
    borderRadius: "none",
    fontSize: "16px",
    color: "red",
    border: state.isFocused
      ? "2px solid #06D7F9"
      : `1px solid ${base.borderColor}`,
    "&:hover": {
      borderColor: state.isFocused ? "none" : "rgba(0, 0, 0, 0.87)",
    },
  }),
  input: (base) => ({
    ...base,
    color: "#06D7F9",
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  // placeholder: (provided) => ({
  //   ...provided,
  //   color: "#0091d4",
  //   fontSize: "16px",
  // }),
  // input: (provided) => ({
  //   ...provided,
  //   color: "#0091d4",
  //   fontSize: "16px",
  // }),
  // valueContainer: (provided) => ({
  //   ...provided,
  //   padding: "17px",
  // }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    zIndex: 10,
  }),
  // option: (provided) => ({
  //   ...provided,
  //   backgroundColor: "transparent",
  //   padding: "17px",
  //   "&:hover": {
  //     color: "#0091d4",
  //     cursor: "pointer",
  //   },
  // }),
};

export const TextErrorSC = styled.p`
  color: #f44336;
  font-size: 12px;
`;
