import styled from "styled-components";
import { Field } from "formik";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import TableContainer from "@material-ui/core/TableContainer";
import { InputLabel } from "@material-ui/core";

export const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

export const StyledMain = styled(Box)`
  width: 100%;
  z-index: 2;
  position: relative;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
  }
`;

export const StyledMainWrapper = styled(Container)`
  width: 100%;
  position: relative;

  z-index: 2;
  margin-bottom: 60px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  h3 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-weight: normal;
    font-size: 21px;
    line-height: 24px;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
    margin-bottom: 36px;

    span {
      color: #808285;

      &.separator {
        color: #cfcfcf;
      }
    }
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  width: 100% !important;
  margin-bottom: 40px;
  .removeIcon {
    cursor: pointer;
    width: 18px;
    height: 18px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  .MuiTable-root {
    border-collapse: separate;
  }

  .MuiTableCell-root {
    border-bottom: none;
  }

  .MuiTableHead-root {
    background-color: #000000;

    .MuiTableCell-head {
      font-family: "RobotoRegular", "Arial", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      color: #ffffff;
    }
  }

  .MuiTableCell-body {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;

    a {
      text-decoration-line: underline;
      color: #00c9e9;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const StyleCostingData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  h1 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 13px;
    text-transform: uppercase;
    color: #00bfe9;
    margin-left: 20px;
  }

  .row {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

export const StyledSpecialCell = styled.span`
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  text-transform: uppercase;
  color: #00bfe9;
`;

export const StyledField = styled(Field)`
  background: #ffffff;
  margin-bottom: 40px;

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
  }

  .MuiInputBase-input {
    background: #ffffff;
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin: 0;
    bottom: -24px;
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  font-family: "RobotoRegular", "Arial", sans-serif;
  transform: none !important;
  display: block;
  font-size: 14px;
  line-height: 93.1%;
  color: #000000;
  margin-bottom: 20px;
`;

export const StyledButtonPrimary = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;
  padding: 10px 40px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

export const StyledModalContent = styled(Box)`
  padding: 16px;
`;
