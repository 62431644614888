import { store } from "@risingstack/react-easy-state";

import { auth } from "./auth";
import { company } from "./company";

import { API_ENDPOINT } from "../consts/globals";

import { authFetch } from "functions";

const abortController = new AbortController();
const signal = abortController.signal;

export const report = store({
  report: null,
  specificReport: null,
  adminReport: null,
  loading: false,
  async getReport(id) {
    report.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/assessment/${company.company.id}`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      report.report = responseJson;
      report.loading = false;
    } catch (error) {
      report.loading = false;
      console.log(error);
    }
  },
  async getSpecificReport(id) {
    report.specificReport = null;
    report.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    if (!company.specificCompany) {
      await company.getSpecificCompany(id);
    }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/assessment/${company.specificCompany.id}`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      report.specificReport = responseJson;
      report.loading = false;
    } catch (error) {
      report.loading = false;
      console.log(error);
    }
  },
  async getAdminReport(id) {
    report.adminReport = null;
    report.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    // if (!company.specificCompany) {
    //   await company.getSpecificCompany(id);
    // }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/admin-assessment/${id}`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      report.adminReport = responseJson;
      report.loading = false;
    } catch (error) {
      report.loading = false;
      console.log(error);
    }
  },
});
