import * as Yup from "yup";
import formModel from "./formModel";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
const {
  formField: {
    name,
    email,
    profile_photo,
    mobile_phone,
    country,
    zip,
    job_function,
    job_level,
    discipline,
    industry,
    company_name,
    street,
    city,
  },
} = formModel;

const alphanumericRegEx = /^[a-z0-9]+$/i;

export default [
  Yup.object().shape({
    [name.name]: Yup.string().required(`${name.requiredErrorMsg}`),
    [email.name]: Yup.string().nullable(),
    [profile_photo.name]: Yup.string().nullable(),
    [mobile_phone.name]: Yup.string()
      .test("validator-isPossible", function (value) {
        if (typeof value === "undefined") {
          return;
        }
        const validation = isPossiblePhoneNumber(value.toString());
        if (!validation) {
          return this.createError({
            path: this.path,
            message: mobile_phone.invalidErrorMsg,
          });
        } else {
          return true;
        }
      })
      .test("validator-isValid", function (value) {
        if (typeof value === "undefined") {
          return;
        }
        const validation = isValidPhoneNumber(value.toString());
        if (!validation) {
          return this.createError({
            path: this.path,
            message: mobile_phone.invalidErrorMsg,
          });
        } else {
          return true;
        }
      }),
    [country.name]: Yup.string()
      .nullable()
      .required(`${country.requiredErrorMsg}`),
    [zip.name]: Yup.string().required(`${zip.requiredErrorMsg}`),
    [job_function.name]: Yup.string().nullable(),
    [job_level.name]: Yup.string().nullable(),
    [discipline.name]: Yup.string().nullable(),
    [industry.name]: Yup.string().nullable(),
    [company_name.name]: Yup.string().nullable(),
    [street.name]: Yup.string().required(`${street.requiredErrorMsg}`),
    [city.name]: Yup.string().required(`${city.requiredErrorMsg}`),
  }),
];
