import React, { Fragment } from "react";
import { Grid, Box, InputLabel } from "@material-ui/core";
import { InputField } from "../../FormFields";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Loader from "components/shared/parts/Loader";
import { autoEffect, view } from "@risingstack/react-easy-state";
import { auth } from "stores/auth";

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledSectionOuter = styled(Box)`
  border-left: 2px solid #01e7aa;
  padding-left: 11px;
  margin-bottom: 60px;
`;

const StyledSectionInner = styled(Box)`
  background: #f3f9fa;
  padding: 40px;

  .MuiInputLabel-root {
    font-family: "RobotoRegular", "Arial", sans-serif;
    transform: none;
    display: block;
    font-size: 14px;
    color: #000000;
    margin-bottom: 9px;
    height: 27px;
  }

  .MuiFormLabel-asterisk {
    color: #ff490f;
  }

  .MuiDropzoneArea-root {
    height: 133px;
    border-radius: 0;
    min-height: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 29px;

    p {
      margin: 0;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 120.1%;
      text-align: center;
      color: #b0b0b0;
    }
  }

  .MuiDropzonePreviewList-root {
    display: flex;
    justify-content: center;
  }

  .MuiSnackbar-root {
    right: 24px;
    left: auto;
  }

  .MuiDropzoneArea-text {
    display: none;
  }

  .MuiDropzonePreviewList-image {
    height: 50px;
  }

  .MuiFormHelperText-root {
    top: 48px;
  }
`;

const PasswordForm = view((props) => {
  const {
    formField: { current_password, new_password, repeat_password },
    initialValues,
    validateField,
    resetForm,
  } = props;
  const { t } = useTranslation();

  autoEffect(() => {
    if (auth.passwordErrors) {
      validateField("current_password");
    }

    if (auth.passwordUpdateSuccess) {
      resetForm();
    }
  });

  const validatePassword = (value) => {
    let error;

    if (auth.passwordErrors) {
      error = t("password error");
      auth.passwordErrors = null;
    }

    return error;
  };

  return !initialValues ? (
    <Fragment>
      <StyledSpacer />
      <Loader />
    </Fragment>
  ) : (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <h2>Security</h2>
        </Grid>
      </Grid>
      <StyledSectionOuter>
        <StyledSectionInner>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={4}>
              <InputLabel
                htmlFor={current_password.name}
                shrink={true}
                variant="outlined"
                required
              >
                {current_password.label}
              </InputLabel>
              <InputField
                id={current_password.name}
                name={current_password.name}
                fullWidth
                variant="outlined"
                size="small"
                type="password"
                validate={validatePassword}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputLabel
                htmlFor={new_password.name}
                shrink={true}
                variant="outlined"
                required
              >
                {new_password.label}
              </InputLabel>
              <InputField
                id={new_password.name}
                name={new_password.name}
                fullWidth
                variant="outlined"
                size="small"
                type="password"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputLabel
                htmlFor={repeat_password.name}
                shrink={true}
                variant="outlined"
                required
              >
                {repeat_password.label}
              </InputLabel>
              <InputField
                id={repeat_password.name}
                name={repeat_password.name}
                fullWidth
                variant="outlined"
                size="small"
                type="password"
              />
            </Grid>
          </Grid>
        </StyledSectionInner>
      </StyledSectionOuter>
    </Fragment>
  );
});

export default PasswordForm;
