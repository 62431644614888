import { store } from "@risingstack/react-easy-state";
import axios from "axios";

import { auth } from "./auth";

import { API_ENDPOINT } from "../consts/globals";

import { authFetch } from "functions";

const abortController = new AbortController();
const signal = abortController.signal;

export const gdpr = store({
  terms: null,
  publicTerms: null,
  loading: false,
  async getPublicTerms() {
    try {
      const response = await axios.get(`${API_ENDPOINT}/guest/legal-terms`);

      gdpr.publicTerms = response.data.data;
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getTerms() {
    gdpr.loading = true;

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/legal-terms/check-user`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      gdpr.terms = responseJson.data;
      gdpr.loading = false;
    } catch (error) {
      gdpr.loading = false;
      console.log(error);
    }
  },
  async acceptTerms(values) {
    gdpr.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    let terms = {
      accepted: [],
      rejected: [],
    };

    Object.keys(values).map((key) => {
      if (values[key]) {
        terms.accepted.push(key);
      } else {
        terms.rejected.push(key);
      }

      return terms;
    });

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/legal-terms/update-accepted`,
        {
          signal: signal,
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(terms),
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await response.json();

      await gdpr.getTerms();
      // gdpr.loading = false;
    } catch (error) {
      // gdpr.loading = false;
      console.log(error);
    }
  },
});
