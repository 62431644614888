import { store } from "@risingstack/react-easy-state";
import { auth } from "./auth";

import { API_ENDPOINT } from "../consts/globals";

import { authFetch } from "functions";

const abortController = new AbortController();
const signal = abortController.signal;

export const search = store({
  results: null,
  pagination: null,
  suggestions: null,
  citySuggestions: null,
  loading: false,
  async getSuggestions(input) {
    if (!auth.user) {
      await auth.getUser();
    }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/commodities?filter-commodity_name=${input}`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      search.suggestions = responseJson;
      return responseJson;
    } catch (error) {
      console.log(error);
    }
  },

  async getAutocompleteSuggestions(input) {
    if (!auth.user) {
      await auth.getUser();
    }
    try {
      const response = await authFetch(
        `${process.env.REACT_APP_AUTOCOMPLETE_API}/address?address=${input}`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "x-api-key": process.env.REACT_APP_AUTOCOMPLETE_API_KEY,
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      search.citySuggestions = responseJson;
      return responseJson;
    } catch (error) {
      console.log(error);
      return "";
    }
  },

  async getSearchResults(input, region, page) {
    search.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    try {
      const response = await authFetch(
        region !== "all"
          ? `${API_ENDPOINT}/system/search?filter-term=${input}&page-size=20&page-number=${page}&filter-country_id=${region}&include=categories,quickScanOrder,certificates,country,city`
          : `${API_ENDPOINT}/system/search?filter-term=${input}&page-size=20&page-number=${page}&include=categories,quickScanOrder,certificates,country,city`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      search.results = responseJson.data;
      search.pagination = responseJson.meta;
      search.loading = false;
      return responseJson.data;
    } catch (error) {
      console.log(error);
      search.loading = false;
    }
  },
});
