import { store } from "@risingstack/react-easy-state";

import { auth } from "./auth";

import { API_ENDPOINT } from "../consts/globals";

import { authFetch } from "functions";

const abortController = new AbortController();
const signal = abortController.signal;

export const company = store({
  company: null,
  allCompanies: null,
  specificCompany: null,
  loading: false,
  uploadDocuments: [],
  deleteDocuments: [],
  async getCompanies() {
    company.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    try {
      const response = await authFetch(`${API_ENDPOINT}/system/companies`, {
        signal: signal,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      company.allCompanies = responseJson.data;
      company.loading = false;
    } catch (error) {
      company.loading = false;
      console.log(error);
    }
  },
  async getCompany() {
    company.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/usercompany?include=country,city,certificates,productionLocations,categories`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      // if (!response.ok) {
      //   throw Error(response.statusText);
      // }

      const responseJson = await response.json();

      if (responseJson.errors) {
        // if (!company.allCompanies) {
        //   await company.getCompanies();
        // }
        // if (!countries.countries) {
        //   await countries.getCountries();
        // }
        // if (!cities.cities) {
        //   await cities.getCities();
        // }
        company.loading = false;
        return;
      } else {
        let convertedValues = Object.fromEntries(
          // convert to array, map, and then fromEntries gives back the object
          Object.entries(responseJson).map(([key, value]) => {
            if (
              value === null ||
              (Object.keys(value).length === 0 && value.constructor === Object)
            ) {
              value = "";
            }
            return [key, value];
          })
        );

        if (convertedValues.country.id) {
          convertedValues.country_id = convertedValues.country.name;
        }
        if (convertedValues.city.id) {
          convertedValues.city_id = convertedValues.city.name;
        }

        convertedValues.certificates = responseJson.certificates.filter(
          (certificate) => {
            return (
              certificate.name !== "iso" &&
              certificate.number !== "14001" &&
              certificate.name !== "iso" &&
              certificate.number !== "9001" &&
              certificate.name !== "iso" &&
              certificate.number !== "9001:2015" &&
              certificate.name !== "ohsas" &&
              certificate.number !== "18001"
            );
          }
        );

        convertedValues.production_locations =
          responseJson.production_locations;

        const filteredISO9001 = convertedValues.certificates.find(
          (certificate) => {
            return certificate.name === "iso" && certificate.number === "9001";
          }
        );
        if (filteredISO9001) {
          convertedValues.iso9001 = true;
          convertedValues.iso9001ExpiryDate = filteredISO9001.expiry_date;
        } else {
          convertedValues.iso9001 = false;
        }

        const filteredISO9001_2015 = convertedValues.certificates.find(
          (certificate) => {
            return (
              certificate.name === "iso" && certificate.number === "9001:2015"
            );
          }
        );
        if (filteredISO9001_2015) {
          convertedValues.iso9001_2015 = true;
          convertedValues.iso9001_2015ExpiryDate =
            filteredISO9001_2015.expiry_date;
        } else {
          convertedValues.iso9001_2015 = false;
        }

        const filteredISO14001 = convertedValues.certificates.find(
          (certificate) => {
            return certificate.name === "iso" && certificate.number === "14001";
          }
        );
        if (filteredISO14001) {
          convertedValues.iso14001 = true;
          convertedValues.iso14001ExpiryDate = filteredISO14001.expiry_date;
        } else {
          convertedValues.iso14001 = false;
        }

        const filteredOHSAS18001 = convertedValues.certificates.find(
          (certificate) => {
            return (
              certificate.name === "ohsas" && certificate.number === "18001"
            );
          }
        );
        if (filteredOHSAS18001) {
          convertedValues.ohsas18001 = true;
          convertedValues.ohsas18001ExpiryDate = filteredOHSAS18001.expiry_date;
        } else {
          convertedValues.ohsas18001 = false;
        }

        convertedValues.photos = [];

        company.company = convertedValues;
        // if (!company.allCompanies) {
        //   await company.getCompanies();
        // }
        // if (!countries.countries) {
        //   await countries.getCountries();
        // }
        // if (!cities.cities) {
        //   await cities.getCities();
        // }
        company.loading = false;
      }
    } catch (error) {
      company.loading = false;
      console.log(error);
    }
  },
  async addCompany(values) {
    company.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    const convertedValues = {
      name: values.name || null,
      industry: values.industry || null,
      company_type: values.company_type || null,
      parent_company: values.parent_company || null,
      number_of_employees: parseInt(values.number_of_employees) || null,
      turnover: parseInt(values.turnover) || null,
      duns_number: parseInt(values.duns_number) || null,
      tax_jurisdiction_code: parseInt(values.tax_jurisdiction_code) || null,
      supplier_type: values.supplier_type || null,
      communication_language: values.communication_language || null,
      country_id: values.country_id || null,
      city_id: values.city_id || null,
      postcode: values.postcode || null,
      address: values.address || null,
      phone: values.phone || null,
      email: values.email || null,
      website: values.website || null,
      short_description: values.short_description || null,
      certificates: values.certificates,
      production_locations: values.production_locations,
      vat_id: values.vat_id || null,
    };

    if (values.iso9001) {
      const certificate = {
        name: "iso",
        number: "9001",
        expiry_date: values.iso9001ExpiryDate || null,
      };

      convertedValues.certificates.push(certificate);
    }

    if (values.iso9001_2015) {
      const certificate = {
        name: "iso",
        number: "9001:2015",
        expiry_date: values.iso9001_2015ExpiryDate || null,
      };

      convertedValues.certificates.push(certificate);
    }

    if (values.iso14001) {
      const certificate = {
        name: "iso",
        number: "14001",
        expiry_date: values.iso14001ExpiryDate || null,
      };

      convertedValues.certificates.push(certificate);
    }

    if (values.ohsas18001) {
      const certificate = {
        name: "ohsas",
        number: "18001",
        expiry_date: values.ohsas18001ExpiryDate || null,
      };

      convertedValues.certificates.push(certificate);
    }

    try {
      const data = new FormData();
      data.append("data", JSON.stringify(convertedValues));
      for (const doc of company.uploadDocuments) {
        data.append("files[]", doc.uploadFile);
        data.append("file_types[]", doc.type);
      }
      const response = await authFetch(`${API_ENDPOINT}/system/stepone`, {
        signal: signal,
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: data,
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      company.uploadDocuments = [];
      company.deleteDocuments = [];

      await company.getCompany();
      return responseJson(responseJson);
    } catch (error) {
      company.company = null;
      console.log(error);
    }
  },
  async editCompany(values, id) {
    company.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    const convertedValues = {
      name: values.name || null,
      industry: values.industry || null,
      company_type: values.company_type || null,
      parent_company: values.parent_company || null,
      number_of_employees: parseInt(values.number_of_employees) || null,
      turnover: parseInt(values.turnover) || null,
      duns_number: parseInt(values.duns_number) || null,
      tax_jurisdiction_code: parseInt(values.tax_jurisdiction_code) || null,
      supplier_type: values.supplier_type || null,
      communication_language: values.communication_language || null,
      country_id: values.country_id || null,
      city_id: values.city_id || null,
      postcode: values.postcode || null,
      address: values.address || null,
      phone: values.phone || null,
      email: values.email || null,
      website: values.website || null,
      short_description: values.short_description || null,
      certificates: values.certificates,
      production_locations: values.production_locations,
      company_presentation: values.company_presentation || null,
      reference_list: values.reference_list || null,
      logo: values.logo || null,
      vat_id: values.vat_id || null,
      // medias: values.photos,
    };

    if (values.iso9001) {
      const certificate = {
        name: "iso",
        number: "9001",
        expiry_date: values.iso9001ExpiryDate || null,
      };

      convertedValues.certificates.push(certificate);
    }

    if (values.iso9001_2015) {
      const certificate = {
        name: "iso",
        number: "9001:2015",
        expiry_date: values.iso9001_2015ExpiryDate || null,
      };

      convertedValues.certificates.push(certificate);
    }

    if (values.iso14001) {
      const certificate = {
        name: "iso",
        number: "14001",
        expiry_date: values.iso14001ExpiryDate || null,
      };

      convertedValues.certificates.push(certificate);
    }

    if (values.ohsas18001) {
      const certificate = {
        name: "ohsas",
        number: "18001",
        expiry_date: values.ohsas18001ExpiryDate || null,
      };

      convertedValues.certificates.push(certificate);
    }
    convertedValues.delete_documents = company.deleteDocuments;
    const data = new FormData();
    data.append("data", JSON.stringify(convertedValues));
    for (const doc of company.uploadDocuments) {
      data.append("files[]", doc.uploadFile);
      data.append("file_types[]", doc.type);
    }
    console.log(data);
    try {
      const response = await authFetch(`${API_ENDPOINT}/system/stepone/${id}`, {
        signal: signal,
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: data,
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();
      company.uploadDocuments = [];
      company.deleteDocuments = [];
      return responseJson;

      // await company.getCompany();
    } catch (error) {
      company.company = null;
      console.log(error);
    }
  },
  async getSpecificCompany(id) {
    company.loading = true;
    company.specificCompany = null;

    if (!auth.user) {
      await auth.getUser();
    }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/companies/${id}?include=categories,medias,quickScanOrder,country,certificates,city`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      company.specificCompany = responseJson;
      company.loading = false;
    } catch (error) {
      company.specificCompany = null;
      company.loading = false;
      console.log(error);
    }
  },
});
