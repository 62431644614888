import styled from "styled-components";

export const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 800px;
  height: 100%;
  margin: 0 auto;
  padding: 20px;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
`;

export const StyledButton = styled.button`
  width: 100%;
  padding: 8px 4px;
  border: 1px solid #06d7f9;
  background-color: white;
  color: #06d7f9;
  font-size: 12px;
  border-radius: 8px;
  cursor: pointer;
`;

// supplier chat
export const StyledQuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
`;

export const StyledAIQuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export const StyledResponseWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

export const StyledCustomResponseWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
`;

export const StyledCustomResponseButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin: 0px 10px;
  margin-bottom: 10px;
`;

export const StyledBooleansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  padding: 0 10px;
`;

export const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  padding: 0 20px;
`;

export const StyledInput = styled.input`
  outline: none;
  font-size: 13px;
  border: 1px solid #cfcfcf;
  padding: 6px 8px;
  margin-bottom: 10px;
`;

export const StyledSendButton = styled.button`
  border: 1px solid #06d7f9;
  background-color: white;
  color: #06d7f9;
  font-size: 15px;
  padding: 10px 0;
  cursor: pointer;
`;
