import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useTranslation, Trans } from "react-i18next";
import { autoEffect, view } from "@risingstack/react-easy-state";

import { auth } from "stores/auth";
import { company } from "stores/company";
import { dashboards } from "stores/dashboards";
import { selfAssessment } from "stores/selfAssessment";

import MainModal from "components/shared/parts/MainModal";
import Loader from "components/shared/parts/Loader";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  StyledCountWrapper,
  StyledImageWrapper,
  StyledIntro,
  StyledIntroWrapper,
  StyledLink,
  StyledMain,
  StyledMainWrapper,
  StyledRating,
  StyledRatingBox,
  StyledSeeAllButton,
  StyledSpacer,
  StyledTableCell,
  StyledTableCellBorder,
  StyledTableContainer,
} from "./styled";


import { API_ENDPOINT } from "../../../consts/globals";

import { authFetch } from "functions";


const abortController = new AbortController();
const signal = abortController.signal;

const Dashboard = view(() => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [rfiRows, setRfiRows] = useState([]);
  const [quickscanRows, setQuickscanRows] = useState([]);
  // const [favoritesRows, setFavoritesRows] = useState([]);
  // const [shortlistedRows, setShortlistedRows] = useState([]);
  const [rfiModalOpen, setRfiModalOpen] = useState(false);
  const [quickscanModalOpen, setQuickscanModalOpen] = useState(false);
  const { t } = useTranslation();
  const [s3Logo, setS3Logo] = useState(null);

  const getS3Data = async (type, hook) => {
    const response = await authFetch(
      `${API_ENDPOINT}/system/company-bucket?companyId=${dashboards.supplierDashboard.company_details.id}&type=${type}`,
      {
        signal: signal,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const responseData = await response.json();
    hook(
      responseData.files.map(
        (file) => `${process.env.REACT_APP_S3_ENDPOINT}/${file}`
      )
    );
  };

  autoEffect(async () => {
    if (dashboards.supplierDashboard) {
      await getS3Data("logo", setS3Logo);
    }
  });

  autoEffect(async () => {
    if (!company.company) {
      await company.getCompany();
    }
  });

  autoEffect(async () => {
    dashboards.supplierDashboard = null;
    if (company.company) {
      await dashboards.getSupplierDashboard();
    }
  });

  const createRFIData = (id, product_name, date, rfi_deadline) => {
    return { id, product_name, date, rfi_deadline };
  };

  const createQuickscanData = (id, client_uuid, date, status) => {
    return { id, client_uuid, date, status };
  };

  // const createFavoritesData = (id, company_id, company_name, date) => {
  //   return { id, company_id, company_name, date };
  // };

  // const createShortlistedData = (id, company_name, date, input) => {
  //   return { id, company_name, date, input };
  // };

  autoEffect(() => {
    if (dashboards.supplierDashboard) {
      setRfiRows(
        dashboards.supplierDashboard.Rfis.map((rfi) => {
          return createRFIData(
            rfi.id ? rfi.id : null,
            rfi.product_name ? rfi.product_name : t("no data"),
            rfi.created_at
              ? moment(rfi.created_at).format("DD.MM.YYYY.")
              : t("no data"),
            rfi.rfi_deadline
              ? moment(rfi.rfi_deadline).format("DD.MM.YYYY.")
              : t("no data")
          );
        })
      );

      let quickscansArray = [];

      if (
        dashboards.supplierDashboard &&
        dashboards.supplierDashboard.company_details &&
        dashboards.supplierDashboard.company_details.quick_scan_order
      ) {
        // const orderObj = {
        //   ...dashboards.supplierDashboard.company_details.quick_scan_order,
        // };
        // if (
        //   dashboards.supplierDashboard.quickscan_client &&
        //   dashboards.supplierDashboard.quickscan_client.Name
        // ) {
        //   orderObj.client_name =
        //     dashboards.supplierDashboard.quickscan_client.Name;
        // }

        // quickscansArray.push(orderObj);
        quickscansArray =
          dashboards.supplierDashboard.company_details.quick_scan_order;
      }

      setQuickscanRows(
        quickscansArray.map((quickscan) => {
          return createQuickscanData(
            quickscan.id ? quickscan.id : null,
            quickscan.Client_name ? quickscan.Client_name : t("no data"),
            quickscan.created_at
              ? moment(quickscan.created_at).format("DD.MM.YYYY.")
              : t("no data"),
            quickscan.status ? quickscan.status : t("no data")
          );
        })
      );

      // setFavoritesRows(
      //   dashboards.supplierDashboard.Favorites.map((favorite) => {
      //     return createFavoritesData(
      //       favorite.id ? favorite.id : null,
      //       favorite.company && favorite.company.id
      //         ? favorite.company.id
      //         : null,
      //       favorite.company && favorite.company.name
      //         ? favorite.company.name
      //         : "No data",
      //       favorite.created_at
      //         ? moment(favorite.created_at).format("DD.MM.YYYY.")
      //         : "No data"
      //     );
      //   })
      // );

      // let sellersArray = [];

      // dashboards.supplierDashboard.LongLists.map((list) => {
      //   list.sellers.map((seller) => {
      //     sellersArray.push({
      //       id: seller.id ? seller.id : null,
      //       name: seller.name ? seller.name : "No data",
      //       date: seller.created_at
      //         ? moment(seller.created_at).format("DD.MM.YYYY.")
      //         : "No data",
      //       input: list.name ? list.name : "No data",
      //     });

      //     return null;
      //   });

      //   return sellersArray;
      // });

      // setShortlistedRows(
      //   sellersArray.map((seller) => {
      //     return createShortlistedData(
      //       seller.id,
      //       seller.name,
      //       moment(seller.created_at).format("DD.MM.YYYY."),
      //       seller.input
      //     );
      //   })
      // );
    }
  });

  const handleRfiModalOpen = () => {
    setRfiModalOpen(true);
  };

  const handleRFIModalClose = () => {
    setRfiModalOpen(false);
  };

  const handleQuickscanModalOpen = () => {
    setQuickscanModalOpen(true);
  };

  const handleQuickscanModalClose = () => {
    setQuickscanModalOpen(false);
  };

  return (
    <Fragment>
      {!auth.user ||
      dashboards.loading ||
      !rfiRows ||
      !quickscanRows ||
      !selfAssessment.selfAssessment ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (!dashboards.supplierDashboard && !dashboards.loading) ||
        !company.company ? (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"center"}
              >
                <Grid item xs={12}>
                  <h3>
                    <Trans i18nKey="complete-step-one">
                      <Link to="/company-details"></Link>
                    </Trans>
                  </h3>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
          </StyledIntro>
        </Fragment>
      ) : (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"center"}
                spacing={6}
              >
                <Grid item xs={12} lg={6}>
                  <StyledImageWrapper>
                    {s3Logo && s3Logo.length > 0 ? (
                      <img src={s3Logo[0]} alt="company" />
                    ) : null}
                    <Box>
                      <h2>
                        {dashboards.supplierDashboard.company_details &&
                        dashboards.supplierDashboard.company_details.name
                          ? dashboards.supplierDashboard.company_details.name
                          : t("no data")}
                      </h2>
                      <StyledLink to="/company-profile">
                        {t("company profile")}
                      </StyledLink>
                    </Box>
                  </StyledImageWrapper>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <StyledRatingBox>
                    <StyledRating
                      score={selfAssessment.selfAssessment.score_step_two}
                    >
                      {t("lean manufacturing score")}:{" "}
                      <span>
                        {selfAssessment.selfAssessment.score_step_two}
                      </span>
                    </StyledRating>
                    <StyledRating
                      score={selfAssessment.selfAssessment.score_step_three}
                    >
                      {t("self-assessment score")}:{" "}
                      <span>
                        {selfAssessment.selfAssessment.score_step_three}
                      </span>
                    </StyledRating>
                  </StyledRatingBox>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
          </StyledIntro>
          <StyledMain>
            <StyledMainWrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <MainModal
                    modalOpen={rfiModalOpen}
                    handleModalClose={handleRFIModalClose}
                  >
                    <StyledTableContainer>
                      <Table aria-label="rfi table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("product")}</TableCell>
                            <TableCell>{t("date")}</TableCell>
                            <TableCell align="right">
                              {t("rfq deadline")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rfiRows.map((row, index) => (
                            <TableRow
                              key={row.id}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <Link to={`/rfq/${row.id}`}>
                                  {row.product_name}
                                </Link>
                              </TableCell>
                              <TableCell>{row.date}</TableCell>
                              <TableCell align="right">
                                {row.rfi_deadline}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  </MainModal>
                  <h3>
                    {t("received rfqs")} <span className={"separator"}>|</span>{" "}
                    <span>{rfiRows.length}</span>
                  </h3>
                  {rfiRows.length > 0 ? (
                    <StyledTableContainer>
                      <Table aria-label="rfi table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("product")}</TableCell>
                            <TableCell>{t("date")}</TableCell>
                            <TableCell align="right">
                              {t("rfq deadline")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rfiRows
                            .map((row, index) => {
                              if (index <= 4) {
                                return (
                                  <TableRow
                                    key={row.id}
                                    style={{
                                      backgroundColor:
                                        index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                                    }}
                                  >
                                    <TableCell component="th" scope="row">
                                      <Link to={`/rfq/${row.id}`}>
                                        {row.product_name}
                                      </Link>
                                    </TableCell>
                                    <TableCell>{row.date}</TableCell>
                                    <TableCell align="right">
                                      {row.rfi_deadline}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                              return null;
                            })
                            .reverse()}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  ) : null}
                  {rfiRows.length > 5 ? (
                    <StyledSeeAllButton onClick={handleRfiModalOpen}>
                      {t("see all")}
                    </StyledSeeAllButton>
                  ) : null}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MainModal
                    modalOpen={quickscanModalOpen}
                    handleModalClose={handleQuickscanModalClose}
                  >
                    <StyledTableContainer>
                      <Table aria-label="quickscans table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("sender")}</TableCell>
                            <TableCell>{t("date")}</TableCell>
                            <TableCell>{t("status")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {quickscanRows.map((row, index) => (
                            <TableRow
                              key={row.id}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.client_uuid}
                              </TableCell>
                              <TableCell>{row.date}</TableCell>
                              <StyledTableCell>
                                {row.status === "complete" ? (
                                  <Link
                                    to={`/admin-quickscan-report/${row.id}`}
                                  >
                                    {row.status}
                                  </Link>
                                ) : (
                                  row.status
                                )}
                                <StyledTableCellBorder status={row.status} />
                              </StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  </MainModal>
                  <h3>
                    {t("quick scans ordered")}{" "}
                    <span className={"separator"}>|</span>{" "}
                    <span>{quickscanRows.length}</span>
                  </h3>
                  {quickscanRows.length > 0 ? (
                    <StyledTableContainer>
                      <Table aria-label="quickscans table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("sender")}</TableCell>
                            <TableCell>{t("date")}</TableCell>
                            <TableCell>{t("status")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {quickscanRows.map((row, index) => {
                            if (index <= 4) {
                              return (
                                <TableRow
                                  key={row.id}
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.client_uuid}
                                  </TableCell>
                                  <TableCell>{row.date}</TableCell>
                                  <StyledTableCell>
                                    {row.status === "complete" ? (
                                      <Link
                                        to={`/admin-quickscan-report/${row.id}`}
                                      >
                                        {row.status}
                                      </Link>
                                    ) : (
                                      row.status
                                    )}
                                    <StyledTableCellBorder
                                      status={row.status}
                                    />
                                  </StyledTableCell>
                                </TableRow>
                              );
                            }
                            return null;
                          })}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  ) : null}
                  {quickscanRows.length > 5 ? (
                    <StyledSeeAllButton onClick={handleQuickscanModalOpen}>
                      {t("see all")}
                    </StyledSeeAllButton>
                  ) : null}
                </Grid>
              </Grid>
            </StyledMainWrapper>
          </StyledMain>
          <StyledMain>
            <StyledMainWrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <StyledCountWrapper>
                    {/* <h1>{dashboards.supplierDashboard.favorite_count}</h1> */}
                    <h3>
                      {t("buyers added you to favorites")}{" "}
                      <span className={"separator"}>|</span>{" "}
                      <span>{dashboards.supplierDashboard.favorite_count}</span>
                    </h3>
                    {/* <h3>{t("buyers added you to favorites")}</h3> */}
                  </StyledCountWrapper>
                </Grid>
                {/* <Grid item xs={12} lg={6}>
                  <StyledCountWrapper>
                    <h3>Buyers added you to shortlist</h3>
                  </StyledCountWrapper>
                </Grid> */}
              </Grid>
            </StyledMainWrapper>
          </StyledMain>
          {/* <StyledMain>
            <StyledMainWrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <h3>
                    Suppliers added to favorites{" "}
                    <span className={"separator"}>|</span>{" "}
                    <span>{favoritesRows.length}</span>
                  </h3>
                  {favoritesRows.length > 0 ? (
                    <StyledTableContainer>
                      <Table aria-label="favorites table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Company Name</TableCell>
                            <TableCell align="right">Date</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {favoritesRows.map((row, index) => (
                            <TableRow
                              key={row.id}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <Link to={`/companies/${row.id}`}>
                                  <StyledSpecialCell>
                                    {row.company_name}
                                  </StyledSpecialCell>
                                </Link>
                              </TableCell>
                              <TableCell align="right">{row.date}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  ) : null}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <h3>
                    Shortlisted suppliers <span className={"separator"}>|</span>{" "}
                    <span>{shortlistedRows.length}</span>
                  </h3>
                  {shortlistedRows.length > 0 ? (
                    <StyledTableContainer>
                      <Table aria-label="shortlisted table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Company Name</TableCell>
                            <TableCell>Search Input</TableCell>
                            <TableCell align="right">Date</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {shortlistedRows.map((row, index) => (
                            <TableRow
                              key={row.id}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <Link to={`/companies/${row.id}`}>
                                  <StyledSpecialCell>
                                    {row.company_name}
                                  </StyledSpecialCell>
                                </Link>
                              </TableCell>
                              <TableCell>{row.input}</TableCell>
                              <TableCell align="right">{row.date}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  ) : null}
                </Grid>
              </Grid>
            </StyledMainWrapper>
          </StyledMain> */}
          {/* <div>
            <h2>Dashboard</h2>
            {JSON.stringify(customSearch.list)}
          </div> */}
        </Fragment>
      )}
    </Fragment>
  );
});

export default Dashboard;
