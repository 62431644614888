import React, { Fragment, useEffect, useState } from "react";
import { Grid, Box, InputLabel, Button } from "@material-ui/core";
import {
  InputField,
  CheckboxField,
  SelectField,
  DatePickerField,
} from "../../FormFields";
import { FieldArray } from "formik";
import styled from "styled-components";
import { DropzoneArea } from "material-ui-dropzone";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import fileUploadIcon from "assets/img/fileUpload.svg";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import Loader from "components/shared/parts/Loader";
import { view } from "@risingstack/react-easy-state";

import { company } from "stores/company";
import { useRef } from "react";
import { search } from "stores/search";

import AsyncSelect from "react-select/async";
import { customStyles } from "../styles";
import PhoneInput from "react-phone-number-input";

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledSectionOuter = styled(Box)`
  border-left: 2px solid #01e7aa;
  padding-left: 11px;
  margin-bottom: 60px;
`;

const StyledSectionInner = styled(Box)`
  background: #f3f9fa;
  padding: 40px;

  .MuiInputLabel-root {
    font-family: "RobotoRegular", "Arial", sans-serif;
    transform: none;
    display: block;
    font-size: 14px;
    line-height: 93.1%;
    color: #000000;
    margin-bottom: 9px;
  }

  .MuiFormLabel-asterisk {
    color: #ff490f;
  }

  .MuiDropzoneArea-root {
    height: 133px;
    border-radius: 0;
    min-height: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 29px;

    p {
      margin: 0;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 120.1%;
      text-align: center;
      color: #b0b0b0;
    }
  }

  .custom-dropzone {
    height: 200px;
    border-radius: 0;
    min-height: unset;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    display: block;
    margin-bottom: 29px;
    overflow-y: scroll;
    padding: 20px 40px;

    .MuiDropzoneArea-textContainer {
      padding: 20px;
    }

    .MuiDropzonePreviewList-imageContainer {
      text-align: left;
      border-top: 1px solid #dddddd;
      padding: 12px 0 6px 0;

      &:nth-last-child(1) {
        border-bottom: 1px solid #dddddd;
      }

      .MuiDropzonePreviewList-image {
        width: 50px;
        object-fit: cover;
      }

      .MuiDropzonePreviewList-removeButton {
        top: 16px;
        opacity: 1;
        background-color: transparent;
        box-shadow: none;
      }
    }

    p {
      margin: 0;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 120.1%;
      text-align: center;
      color: #b0b0b0;
    }
  }

  .MuiDropzonePreviewList-root {
    display: flex;
    justify-content: center;
  }

  .MuiSnackbar-root {
    right: 24px;
    left: auto;
  }

  .MuiDropzoneArea-text {
    display: none;
  }

  .MuiDropzonePreviewList-image {
    height: 50px;
  }
`;

const UploadFakeLabel = styled.span`
  display: block;
  font-size: 14px;
  line-height: 93.1%;
  color: #000000;
  margin-bottom: 9px;
`;

const StyledCheckboxWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButtonWithIcon = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

const StyledPlusSign = styled(Box)`
  height: 100%;
  width: 46px;
  background: #00bfe9;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #ffffff;
  }
`;

const StyledRemoveWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  padding-top: 22px;
`;

const StyledMinusSign = styled(Box)`
  height: 40px;
  width: 40px;
  background: #00bfe9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    color: #ffffff;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const StyledButtonText = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
`;

const PhoneInputWrapper = styled.div`
  margin-bottom: 30px;
  .phoneInput {
    input {
      border: 1px solid #cfcfcf;
      border-color:${(props) => props.error && "#f44336"};
      font-size: 16px;
      outline: none;

      &:focus {
        border: 2px solid #06d7f9;
        color: #06d7f9;
      }

      &:not(:focus) {
    
        &:hover {
          border:   ${(props) =>
            typeof props.error === "undefined" &&
            "1px solid rgba(0, 0, 0, 0.87)"}
      }
    }
  }
`;

const TextErrorSC = styled.p`
  color: #f44336;
  font-size: 12px;
`;

const InputEmployees = (inputProps) => (
  <InputField
    type="number"
    fullWidth
    variant="outlined"
    size="small"
    {...inputProps}
  />
);

const InputTurnover = (inputProps) => (
  <InputField
    type="number"
    fullWidth
    variant="outlined"
    size="small"
    {...inputProps}
  />
);

const UploadLimits = {
  photo: 10,
  logo: 1,
  presentation: 1,
  reference: 1,
};

const MainForm = view((props) => {
  const {
    formField: {
      name,
      industry,
      company_type,
      parent_company,
      number_of_employees,
      turnover,
      duns_number,
      tax_jurisdiction_code,
      supplier_type,
      communication_language,
      country_id,
      city_id,
      postcode,
      address,
      phone,
      email,
      website,
      short_description,
      iso9001_2015,
      iso14001,
      ohsas18001,
      iso9001_2015ExpiryDate,
      iso14001ExpiryDate,
      ohsas18001ExpiryDate,
      company_presentation,
      reference_list,
      photos,
      logo,
      vat_id,
    },
    initialValues,
    values,
    setFieldValue,
    companies,
    s3Urls,
    s3Logo,
    s3Presentation,
    s3Reference,
    errors,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("xl"));
  const [photosObjects, setPhotosObjects] = useState([]);
  const [logoObjects, setLogoObjects] = useState([]);
  const [presentationObjects, setPresentationObjects] = useState([]);
  const [referenceObjects, setReferenceObjects] = useState([]);
  const [photoLoading, setPhotoLoading] = useState(false);
  let selectRef = useRef(null);
  const [selectedResult, setSelectedResult] = useState(null);
  const [value, setValue] = useState("");
  const [tempValues, setTempValues] = useState(null);
  let allowCall = true;

  useEffect(() => {
    setFieldValue(photos.name, photosObjects);
    // eslint-disable-next-line
  }, [photosObjects]);

  useEffect(() => {
    if (s3Urls) {
      setPhotosObjects(
        s3Urls.map((url) => {
          return {
            s3Url: url,
            localName: null,
          };
        })
      );
    }

    if (s3Logo) {
      setLogoObjects(
        s3Logo.map((url) => {
          return {
            s3Url: url,
            localName: null,
          };
        })
      );
    }

    if (s3Presentation) {
      setPresentationObjects(
        s3Presentation.map((url) => {
          return {
            s3Url: url,
            localName: null,
          };
        })
      );
    }

    if (s3Reference) {
      setReferenceObjects(
        s3Reference.map((url) => {
          return {
            s3Url: url,
            localName: null,
          };
        })
      );
    }

    // eslint-disable-next-line
  }, [s3Urls, s3Logo, s3Presentation, s3Reference]);

  let preparedCompanies = companies.map((company) => {
    return {
      value: company.id,
      label: company.name,
    };
  });

  preparedCompanies = [
    {
      value: null,
      label: t("none"),
    },
    ...preparedCompanies,
  ];

  async function handleDocumentUpload(
    acceptedFiles,
    documentState,
    setDocumentState,
    docType
  ) {
    if (acceptedFiles.length === 0) {
      return;
    }

    setPhotoLoading(true);

    const tempDocumentArray = [];
    const tempUploadDocumentsArray = [];

    for (const file of acceptedFiles) {
      tempDocumentArray.push({
        localName: file.name,
        s3Url: null,
      });
      tempUploadDocumentsArray.push({
        type: docType,
        uploadFile: file,
      });
    }

    company.uploadDocuments = [
      ...company.uploadDocuments,
      ...tempUploadDocumentsArray,
    ];
    setDocumentState([...documentState, ...tempDocumentArray]);
    setPhotoLoading(false);
  }

  function handleDocumentDelete(
    file,
    documentState,
    setDocumentState,
    docType
  ) {
    let s3Document = documentState.find((photo) => {
      return (
        photo.s3Url?.includes(file.name) || photo.localName?.includes(file.name)
      );
    });

    if (s3Document?.s3Url === null) {
      company.uploadDocuments = company.uploadDocuments.filter(
        (doc) => doc.uploadFile.name !== file.name
      );
      setDocumentState(
        documentState.filter((obj) => !obj.localName?.includes(file.name))
      );
    } else {
      company.deleteDocuments = [
        ...company.deleteDocuments,
        {
          type: docType,
          path: s3Document.s3Url.substring(
            s3Document.s3Url.lastIndexOf("/") + 1
          ),
        },
      ];
      setDocumentState(
        documentState.filter((obj) => !obj.s3Url?.includes(file.name))
      );
    }
  }

  const industries = [
    {
      value: undefined,
      label: t("none"),
    },
    {
      value: "Advertising",
      label: t("advertising"),
    },
    {
      value: "Agriculture",
      label: t("agriculture"),
    },
    {
      value: "Banking & Financial Services",
      label: t("banking & financial services"),
    },
    {
      value: "Civil Construction",
      label: t("civil construction"),
    },
    {
      value: "Consulting",
      label: t("consulting"),
    },
    {
      value: "Ecommerce",
      label: t("ecommerce"),
    },
    {
      value: "Education",
      label: t("education"),
    },
    {
      value: "Electronics / Hardware",
      label: t("electronics / hardware"),
    },
    {
      value: "Energy",
      label: t("energy"),
    },
    {
      value: "Fast Moving Consumer Goods",
      label: t("fast moving consumer goods"),
    },
    {
      value: "Healthcare",
      label: t("healthcare"),
    },
    {
      value: "Human Resources Service Provider",
      label: t("human resources service provider"),
    },
    {
      value: "Industrial Goods",
      label: t("industrial goods"),
    },
    {
      value: "IT Products",
      label: t("IT products"),
    },
    {
      value: "IT Services",
      label: t("IT services"),
    },
    {
      value: "Media",
      label: t("media"),
    },
    {
      value: "Real Estate",
      label: t("real estate"),
    },
    {
      value: "Retail",
      label: t("retail"),
    },
    {
      value: "Telecommunication",
      label: t("telecommunication"),
    },
    {
      value: "Tourism / Hotel / Restaurant",
      label: t("tourism / hotel / restaurant"),
    },
    {
      value: "Transport - Logistic",
      label: t("transport - logistic"),
    },
    {
      value: "Other",
      label: t("other"),
    },
  ];

  const company_types = [
    {
      value: undefined,
      label: t("none"),
    },
    {
      value: "headquarter",
      label: t("headquarter"),
    },
    {
      value: "subsidiary",
      label: t("subsidiary"),
    },
    {
      value: "manufacturing",
      label: t("manufacturing"),
    },
  ];

  const supplier_types = [
    {
      value: undefined,
      label: t("none"),
    },
    {
      value: "manufacturer-producer",
      label: t("manufacturer / producer"),
    },
    {
      value: "distributor",
      label: t("distributor"),
    },
    { value: "service-provider", label: t("service provider") },
    {
      value: "importer",
      label: t("importer"),
    },
  ];

  const languages = [
    {
      value: undefined,
      label: t("none"),
    },
    {
      value: "en",
      label: "English",
    },
    {
      value: "ru",
      label: "Russian",
    },
  ];

  let counter = setTimeout(() => {
    allowCall = true;
  }, 1000);

  let results = (inputValue, data) => {
    // data.filter((key) =>
    //   key.name.toLowerCase().includes(inputValue.toLowerCase())
    return [data];
  };

  const handleKeyDown = () => {
    allowCall = false;
    clearTimeout(counter);
    return counter;
  };

  const handleInputChange = (input, params) => {
    selectRef.current.select.select.getNextFocusedOption = () => false;

    if (params.action === "input-change") {
      setValue({
        inputValue: input,
      });
    }
    // setValue({
    //   inputValue: input,
    // });
    setTempValues(input);
    return { value };
  };

  const handleGetResults = (inputValue) =>
    inputValue.length > 2 &&
    allowCall &&
    search
      .getAutocompleteSuggestions(inputValue)
      .then((response) => {
        return results(inputValue, response);
      })
      .catch((data) => {
        console.log(data);
      });

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(handleGetResults(inputValue));
      }, 1000);
    });

  const handleChange = (selectedOption) => {
    setSelectedResult(selectedOption);
    setFieldValue(city_id.name, selectedOption.city);
    setFieldValue(postcode.name, selectedOption.zipCode);
    setFieldValue(country_id.name, selectedOption.country);
    setFieldValue(address.name, selectedOption.fullAddress);
  };

  return !initialValues ? (
    <Fragment>
      <StyledSpacer />
      <Loader />
    </Fragment>
  ) : (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <h2>{t("company details")}</h2>
        </Grid>
      </Grid>
      <StyledSectionOuter>
        <StyledSectionInner>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={4}>
              {photoLoading ? <Loader /> : null}
              <InputLabel
                htmlFor={name.name}
                shrink={true}
                variant="outlined"
                required
              >
                {name.label}
              </InputLabel>
              <InputField
                id={name.name}
                name={name.name}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={industry.name}
                shrink={true}
                variant="outlined"
                required
              >
                {industry.label}
              </InputLabel>
              <SelectField
                id={industry.name}
                name={industry.name}
                data={industries}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={company_type.name}
                shrink={true}
                variant="outlined"
              >
                {company_type.label}
              </InputLabel>
              <SelectField
                id={company_type.name}
                name={company_type.name}
                data={company_types}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={parent_company.name}
                shrink={true}
                variant="outlined"
              >
                {parent_company.label}
              </InputLabel>
              <SelectField
                id={parent_company.name}
                name={parent_company.name}
                data={preparedCompanies}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={number_of_employees.name}
                shrink={true}
                variant="outlined"
                required
              >
                {number_of_employees.label}
              </InputLabel>
              <NumberFormat
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                id={number_of_employees.name}
                name={number_of_employees.name}
                value={values.number_of_employees}
                onValueChange={(vals) =>
                  setFieldValue(number_of_employees.name, vals.value)
                }
                customInput={InputEmployees}
              />
              <InputLabel
                htmlFor={turnover.name}
                shrink={true}
                variant="outlined"
              >
                {turnover.label}
              </InputLabel>
              <NumberFormat
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix="$"
                isNumericString
                id={turnover.name}
                name={turnover.name}
                value={values.turnover}
                onValueChange={(vals) =>
                  setFieldValue(turnover.name, vals.value)
                }
                customInput={InputTurnover}
              />
              <InputLabel
                htmlFor={duns_number.name}
                shrink={true}
                variant="outlined"
              >
                {duns_number.label}
              </InputLabel>
              <InputField
                id={duns_number.name}
                name={duns_number.name}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={tax_jurisdiction_code.name}
                shrink={true}
                variant="outlined"
              >
                {tax_jurisdiction_code.label}
              </InputLabel>
              <InputField
                id={tax_jurisdiction_code.name}
                name={tax_jurisdiction_code.name}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputLabel
                htmlFor={vat_id.name}
                shrink={true}
                variant="outlined"
              >
                {vat_id.label}
              </InputLabel>
              <InputField
                id={vat_id.name}
                name={vat_id.name}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel shrink={true} variant="outlined">
                Full address
              </InputLabel>
              <AsyncSelect
                id={address.location}
                name={address.location}
                ref={selectRef}
                styles={customStyles}
                value={selectedResult}
                loadOptions={promiseOptions}
                defaultOptions
                onInputChange={handleInputChange}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder={t("search")}
                loadingMessage={() => `${t("searching")}...`}
                noOptionsMessage={() => t("no suggested products")}
                getOptionValue={(option) => {
                  return option.fullAddress;
                }}
                getOptionLabel={(option) => {
                  return option.fullAddress;
                }}
                defaultInputValue={initialValues.address}
                menuIsOpen={tempValues ? true : false}
              />
              <InputLabel
                htmlFor={country_id.name}
                shrink={true}
                variant="outlined"
                required
              >
                {country_id.label}
              </InputLabel>
              <InputField
                id={country_id.name}
                name={country_id.name}
                fullWidth
                variant="outlined"
                size="small"
                inputProps={{
                  readOnly: true,
                  style: { color: "#757575" },
                }}
              />
              <InputLabel
                htmlFor={city_id.name}
                shrink={true}
                variant="outlined"
                required
              >
                {city_id.label}
              </InputLabel>
              <InputField
                id={city_id.name}
                name={city_id.name}
                fullWidth
                variant="outlined"
                size="small"
                inputProps={{
                  readOnly: true,
                  style: { color: "#757575" },
                }}
              />

              <InputLabel
                htmlFor={postcode.name}
                shrink={true}
                variant="outlined"
                required
              >
                {postcode.label}
              </InputLabel>
              <InputField
                id={postcode.name}
                name={postcode.name}
                fullWidth
                variant="outlined"
                size="small"
                inputProps={{
                  readOnly: true,
                  style: { color: "#757575" },
                }}
              />
              <InputLabel
                htmlFor={address.name}
                shrink={true}
                variant="outlined"
                required
              >
                {address.label}
              </InputLabel>
              <InputField
                id={address.name}
                name={address.name}
                fullWidth
                variant="outlined"
                size="small"
                inputProps={{
                  readOnly: true,
                  style: { color: "#757575" },
                }}
              />
              <InputLabel
                htmlFor={phone.name}
                shrink={true}
                variant="outlined"
                required
              >
                {phone.label}
              </InputLabel>
              <PhoneInputWrapper error={errors.phone}>
                <PhoneInput
                  id={phone.name}
                  name={phone.name}
                  className="phoneInput"
                  international
                  placeholder="Enter phone number"
                  onChange={(e) => {
                    setFieldValue(phone.name, e);
                  }}
                  value={initialValues.phone}
                />
                <TextErrorSC>{errors.phone}</TextErrorSC>
              </PhoneInputWrapper>

              <InputLabel
                htmlFor={email.name}
                shrink={true}
                variant="outlined"
                required
              >
                {email.label}
              </InputLabel>
              <InputField
                id={email.name}
                name={email.name}
                fullWidth
                variant="outlined"
                size="small"
                type="email"
              />
              <InputLabel
                htmlFor={website.name}
                shrink={true}
                variant="outlined"
              >
                {website.label}
              </InputLabel>
              <InputField
                id={website.name}
                name={website.name}
                fullWidth
                variant="outlined"
                size="small"
                placeholder="https://website.com"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputLabel
                htmlFor={supplier_type.name}
                shrink={true}
                variant="outlined"
              >
                {supplier_type.label}
              </InputLabel>
              <SelectField
                id={supplier_type.name}
                name={supplier_type.name}
                data={supplier_types}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={communication_language.name}
                shrink={true}
                variant="outlined"
                required
              >
                {communication_language.label}
              </InputLabel>
              <SelectField
                id={communication_language.name}
                name={communication_language.name}
                data={languages}
                fullWidth
                variant="outlined"
                size="small"
              />
              <UploadFakeLabel>{logo.label}</UploadFakeLabel>
              <DropzoneArea
                id={logo.name}
                name={logo.name}
                filesLimit={UploadLimits.logo}
                dropzoneText=""
                showPreviewsInDropzone
                maxFileSize={524288000}
                previewGridProps={{ container: { spacing: 0 } }}
                acceptedFiles={[".png", ".jpg", ".jpeg"]}
                initialFiles={s3Logo}
                Icon={() => {
                  return (
                    <Fragment>
                      <img
                        src={fileUploadIcon}
                        alt="file upload icon"
                        style={{ marginBottom: "15px" }}
                      />
                      {values.logo.length === 0 ? (
                        <p>{t("drag and drop your file here")}</p>
                      ) : null}
                    </Fragment>
                  );
                }}
                onChange={(e) => {
                  setFieldValue(logo.name, e);
                }}
                dropzoneProps={{
                  disabled: logoObjects.length > UploadLimits.logo,
                }}
                onDrop={(acceptedFiles) => {
                  handleDocumentUpload(
                    acceptedFiles,
                    logoObjects,
                    setLogoObjects,
                    "logo"
                  );
                }}
                onDelete={(file) => {
                  handleDocumentDelete(
                    file,
                    logoObjects,
                    setLogoObjects,
                    "logo"
                  );
                }}
              />
              <Grid container spacing={matches ? 6 : 0}>
                <Grid item xs={12} xl={6}>
                  <UploadFakeLabel>
                    {company_presentation.label}
                  </UploadFakeLabel>
                  <DropzoneArea
                    id={company_presentation.name}
                    name={company_presentation.name}
                    filesLimit={UploadLimits.presentation}
                    dropzoneText=""
                    showPreviewsInDropzone
                    maxFileSize={524288000}
                    previewGridProps={{ container: { spacing: 0 } }}
                    acceptedFiles={[".pdf"]}
                    initialFiles={s3Presentation}
                    Icon={() => {
                      return (
                        <Fragment>
                          <img
                            src={fileUploadIcon}
                            alt="file upload icon"
                            style={{ marginBottom: "15px" }}
                          />
                          {values.company_presentation.length === 0 ? (
                            <p>{t("drag and drop your file here")}</p>
                          ) : null}
                        </Fragment>
                      );
                    }}
                    onChange={(e) => {
                      setFieldValue(company_presentation.name, e);
                    }}
                    dropzoneProps={{
                      disabled:
                        presentationObjects.length > UploadLimits.presentation,
                    }}
                    onDrop={(acceptedFiles) => {
                      handleDocumentUpload(
                        acceptedFiles,
                        presentationObjects,
                        setPresentationObjects,
                        "company-presentation"
                      );
                    }}
                    onDelete={(file) => {
                      handleDocumentDelete(
                        file,
                        presentationObjects,
                        setPresentationObjects,
                        "company-presentation"
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} xl={6}>
                  <UploadFakeLabel>{reference_list.label}</UploadFakeLabel>
                  <DropzoneArea
                    id={reference_list.name}
                    name={reference_list.name}
                    filesLimit={UploadLimits.reference}
                    dropzoneText=""
                    showPreviewsInDropzone
                    maxFileSize={524288000}
                    previewGridProps={{ container: { spacing: 0 } }}
                    acceptedFiles={[".pdf"]}
                    initialFiles={s3Reference}
                    Icon={() => {
                      return (
                        <Fragment>
                          <img
                            src={fileUploadIcon}
                            alt="file upload icon"
                            style={{ marginBottom: "15px" }}
                          />
                          {values.reference_list.length === 0 ? (
                            <p>{t("drag and drop your file here")}</p>
                          ) : null}
                        </Fragment>
                      );
                    }}
                    onChange={(e) => {
                      setFieldValue(reference_list.name, e);
                    }}
                    dropzoneProps={{
                      disabled:
                        referenceObjects.length > UploadLimits.reference,
                    }}
                    onDrop={(acceptedFiles) => {
                      handleDocumentUpload(
                        acceptedFiles,
                        referenceObjects,
                        setReferenceObjects,
                        "reference-list"
                      );
                    }}
                    onDelete={(file) => {
                      handleDocumentDelete(
                        file,
                        referenceObjects,
                        setReferenceObjects,
                        "reference-list"
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </StyledSectionInner>
      </StyledSectionOuter>
      <StyledSectionOuter>
        <StyledSectionInner>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <InputLabel
                htmlFor={short_description.name}
                shrink={true}
                variant="outlined"
                required
              >
                {short_description.label}
              </InputLabel>
              <InputField
                id={short_description.name}
                name={short_description.name}
                fullWidth
                variant="outlined"
                size="small"
                multiline
                rows={4}
                placeholder={t("company description placeholder")}
              />
            </Grid>
          </Grid>
        </StyledSectionInner>
      </StyledSectionOuter>
      <StyledSectionOuter>
        <StyledSectionInner>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={6}>
              <UploadFakeLabel>{photos.label}</UploadFakeLabel>
              <DropzoneArea
                id={photos.name}
                name={photos.name}
                dropzoneText=""
                filesLimit={UploadLimits.photo}
                showPreviewsInDropzone
                dropzoneClass="custom-dropzone"
                maxFileSize={524288000}
                previewGridProps={{
                  container: { spacing: 0 },
                  item: { xs: 12 },
                }}
                acceptedFiles={[".png", ".jpg", ".jpeg"]}
                initialFiles={s3Urls}
                dropzoneProps={{
                  disabled: photosObjects.length >= UploadLimits.photo,
                }}
                Icon={() => {
                  return (
                    <Fragment>
                      <img
                        src={fileUploadIcon}
                        alt="file upload icon"
                        style={{ marginBottom: "15px" }}
                      />
                      {values.photos.length === 0 ? (
                        <p>{t("company photos placeholder")}</p>
                      ) : null}
                    </Fragment>
                  );
                }}
                onChange={(e) => {
                  setFieldValue(photos.name, e);
                }}
                onDrop={(acceptedFiles) => {
                  handleDocumentUpload(
                    acceptedFiles,
                    photosObjects,
                    setPhotosObjects,
                    "image"
                  );
                }}
                onDelete={(file) => {
                  handleDocumentDelete(
                    file,
                    photosObjects,
                    setPhotosObjects,
                    "image"
                  );
                }}
              />
            </Grid>
          </Grid>
        </StyledSectionInner>
      </StyledSectionOuter>
      <Grid container>
        <Grid item xs={12}>
          <h3>{t("certificates")}</h3>
        </Grid>
      </Grid>
      <StyledSectionOuter>
        <StyledSectionInner>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <StyledCheckboxWrapper>
                <Grid container>
                  <Grid item xs={12} lg={2}>
                    <CheckboxField
                      name={iso9001_2015.name}
                      label={iso9001_2015.label}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <DatePickerField
                      name={iso9001_2015ExpiryDate.name}
                      format="dd/MM/yyyy"
                      minDate={new Date()}
                      maxDate={new Date("2050/12/31")}
                      fullWidth
                      placeholder={t("expiry date")}
                      disabled={!values.iso9001_2015}
                    />
                  </Grid>
                </Grid>
              </StyledCheckboxWrapper>
            </Grid>
            <Grid item xs={12}>
              <StyledCheckboxWrapper>
                <Grid container>
                  <Grid item xs={12} lg={2}>
                    <CheckboxField
                      name={iso14001.name}
                      label={iso14001.label}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <DatePickerField
                      name={iso14001ExpiryDate.name}
                      format="dd/MM/yyyy"
                      minDate={new Date()}
                      maxDate={new Date("2050/12/31")}
                      fullWidth
                      placeholder={t("expiry date")}
                      disabled={!values.iso14001}
                    />
                  </Grid>
                </Grid>
              </StyledCheckboxWrapper>
            </Grid>
            <Grid item xs={12}>
              <StyledCheckboxWrapper>
                <Grid container>
                  <Grid item xs={12} lg={2}>
                    <CheckboxField
                      name={ohsas18001.name}
                      label={ohsas18001.label}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <DatePickerField
                      name={ohsas18001ExpiryDate.name}
                      format="dd/MM/yyyy"
                      minDate={new Date()}
                      maxDate={new Date("2050/12/31")}
                      fullWidth
                      placeholder={t("expiry date")}
                      disabled={!values.ohsas18001}
                    />
                  </Grid>
                </Grid>
              </StyledCheckboxWrapper>
            </Grid>
          </Grid>
        </StyledSectionInner>
      </StyledSectionOuter>
      <Grid container>
        <Grid item xs={12}>
          <h3>{t("other certificates")}</h3>
        </Grid>
      </Grid>
      <StyledSectionOuter>
        <StyledSectionInner>
          <Grid container spacing={6}>
            <FieldArray
              name="certificates"
              render={(arrayHelpers) => (
                <Fragment>
                  {values.certificates.map((certificate, index) => (
                    <Fragment key={index}>
                      <Grid item xs={12} lg={3}>
                        {/** both these conventions do the same */}
                        <InputLabel
                          htmlFor={`certificates[${index}].name`}
                          shrink={true}
                          variant="outlined"
                        >
                          {t("name")}
                        </InputLabel>
                        <InputField
                          id={`certificates[${index}].name`}
                          name={`certificates[${index}].name`}
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <InputLabel
                          htmlFor={`certificates.${index}.number`}
                          shrink={true}
                          variant="outlined"
                        >
                          {t("number")}
                        </InputLabel>
                        <InputField
                          id={`certificates.${index}.number`}
                          name={`certificates.${index}.number`}
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <InputLabel
                          htmlFor={`certificates.${index}.expiry_date`}
                          shrink={true}
                          variant="outlined"
                        >
                          {t("expiry date")}
                        </InputLabel>
                        <DatePickerField
                          id={`certificates.${index}.expiry_date`}
                          name={`certificates.${index}.expiry_date`}
                          format="dd/MM/yyyy"
                          minDate={new Date()}
                          maxDate={new Date("2050/12/31")}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <StyledRemoveWrapper>
                          <StyledMinusSign
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <RemoveIcon />
                          </StyledMinusSign>
                        </StyledRemoveWrapper>
                      </Grid>
                    </Fragment>
                  ))}

                  <Grid item xs={12}>
                    <StyledButtonWithIcon
                      onClick={() =>
                        arrayHelpers.push({
                          name: "",
                          number: "",
                          expiry_date: "",
                        })
                      }
                    >
                      <StyledPlusSign>
                        <AddIcon />
                      </StyledPlusSign>
                      <StyledButtonText>
                        {t("add certificate")}
                      </StyledButtonText>
                    </StyledButtonWithIcon>
                  </Grid>
                </Fragment>
              )}
            />
          </Grid>
        </StyledSectionInner>
      </StyledSectionOuter>
      <Grid container>
        <Grid item xs={12}>
          <h3>{t("production location")}</h3>
        </Grid>
      </Grid>
      <StyledSectionOuter>
        <StyledSectionInner>
          <Grid container spacing={6}>
            <FieldArray
              name="production_locations"
              render={(arrayHelpers) => (
                <Fragment>
                  {values.production_locations.map((location, index) => (
                    <Fragment key={index}>
                      <Grid item xs={12} lg={8}>
                        {/** both these conventions do the same */}
                        <InputLabel
                          htmlFor={`production_locations[${index}].address`}
                          shrink={true}
                          variant="outlined"
                        >
                          {t("address")}
                        </InputLabel>
                        <InputField
                          id={`production_locations[${index}].address`}
                          name={`production_locations[${index}].address`}
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} lg={8}>
                        <InputLabel
                          htmlFor={`production_locations.${index}.short_description`}
                          shrink={true}
                          variant="outlined"
                        >
                          {t("description")}
                        </InputLabel>
                        <InputField
                          id={`production_locations.${index}.short_description`}
                          name={`production_locations.${index}.short_description`}
                          fullWidth
                          variant="outlined"
                          size="small"
                          multiline
                          rows={4}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <StyledRemoveWrapper>
                          <StyledMinusSign
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <RemoveIcon />
                          </StyledMinusSign>
                        </StyledRemoveWrapper>
                      </Grid>
                    </Fragment>
                  ))}

                  <Grid item xs={12}>
                    <StyledButtonWithIcon
                      onClick={() =>
                        arrayHelpers.push({
                          address: "",
                          short_description: "",
                        })
                      }
                    >
                      <StyledPlusSign>
                        <AddIcon />
                      </StyledPlusSign>
                      <StyledButtonText>{t("add location")}</StyledButtonText>
                    </StyledButtonWithIcon>
                  </Grid>
                </Fragment>
              )}
            />
          </Grid>
        </StyledSectionInner>
      </StyledSectionOuter>
      {/* <StyledSectionOuter>
        <StyledSectionInner>
          <Grid container spacing={6}>
            {productionLocation > 0
              ? Array.from(Array(productionLocation), (e, i) => {
                  return (
                    <Fragment>
                      <Grid item xs={12} lg={4}>
                        <InputLabel
                          htmlFor={`productionLocationCertificateName${i}`}
                          shrink={true}
                          variant="outlined"
                        >
                          Certificate name
                        </InputLabel>
                        <InputField
                          id={`productionLocationCertificateName${i}`}
                          name={`productionLocationCertificateName${i}`}
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} lg={8}>
                        <InputLabel
                          htmlFor={`productionLocationDescription${i}`}
                          shrink={true}
                          variant="outlined"
                        >
                          Description
                        </InputLabel>
                        <InputField
                          id={`productionLocationDescription${i}`}
                          name={`productionLocationDescription${i}`}
                          fullWidth
                          variant="outlined"
                          size="small"
                          multiline
                          rows={4}
                        />
                      </Grid>
                    </Fragment>
                  );
                })
              : null}
            <Grid item xs={12}>
              <StyledButtonWithIcon
                onClick={() => setProductionLocation(productionLocation + 1)}
              >
                <StyledPlusSign>
                  <AddIcon />
                </StyledPlusSign>
                <StyledButtonText>Add location</StyledButtonText>
              </StyledButtonWithIcon>
            </Grid>
          </Grid>
        </StyledSectionInner>
      </StyledSectionOuter> */}
    </Fragment>
  );
});

export default MainForm;
