import React, { Fragment, useState } from "react";
import { Formik, Form } from "formik";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { autoEffect, view } from "@risingstack/react-easy-state";

import { auth } from "stores/auth";
import MainForm from "./FormPage/Forms/MainForm";
import validationSchema from "./FormPage/FormModel/validationSchema";
import formModel from "./FormPage/FormModel/formModel";
import formInitialValues from "./FormPage/FormModel/formInitialValues";
import useStyles from "./FormPage/styles";

import PasswordForm from "./password/FormPage/Forms/PasswordForm";
import passwordValidationSchema from "./password/FormPage/FormModel/validationSchema";
import passwordFormModel from "./password/FormPage/FormModel/formModel";
import passwordFormInitialValues from "./password/FormPage/FormModel/formInitialValues";

import Loader from "components/shared/parts/Loader";

import { CircularProgress } from "@material-ui/core";

import {
  StyledButton,
  StyledContainer,
  StyledFormWrapper,
  StyledSpacer,
} from "./styled";
import { ConnectedFocusError } from "focus-formik-error";

const { formId, formField } = formModel;
const { passwordFormId, passwordFormField } = passwordFormModel;

const UserProfile = view((props) => {
  const classes = useStyles();
  const currentValidationSchema = validationSchema[0];
  const currentPasswordValidationSchema = passwordValidationSchema[0];
  const [initialValues, setInitialValues] = useState(null);
  const { t } = useTranslation();

  autoEffect(async () => {
    // if (!company.company) {
    //   // await company.getCompany();
    //   // await cities.getCities();
    // }

    if (!auth.user) {
      await auth.getUser();
    }

    if (auth.user) {
      let pfp = ``;
      if (auth.user.dbData.pfp_url) {
        pfp = `${process.env.REACT_APP_S3_ENDPOINT}/${auth.user.dbData.pfp_url}`;
      }

      const extractParams = [
        "name",
        "email",
        "mobile_phone",
        "country",
        "zip",
        "job_function",
        "job_level",
        "discipline",
        "industry",
        "company_name",
        "address",
        "city",
        "location",
        "street",
      ];
      const fetchedInitialValues = {
        profile_photo: pfp,
      };
      for (const param of extractParams) {
        fetchedInitialValues[param] = auth.user.dbData[param] ?? "";
      }

      setInitialValues(fetchedInitialValues);
    } else {
      setInitialValues(formInitialValues);
    }
  });

  async function _submitForm(values, actions) {
    const response = await auth.updateUser(values);
    actions.setSubmitting(false);
    if (response.ok) {
      props.enqueueSnackbar(t("update successful"), {
        variant: "success",
      });
    } else {
      props.enqueueSnackbar(t("error updating"), {
        variant: "error",
      });
    }
  }

  async function _submitPasswordForm(values, actions) {
    auth.changePassword(values);
    actions.setSubmitting(false);
  }

  async function _handleSubmit(values, actions) {
    _submitForm(values, actions);
  }

  async function _handlePasswordSubmit(values, actions) {
    _submitPasswordForm(values, actions);
  }

  autoEffect(() => {
    if (auth.errors || auth.passwordErrors) {
      props.enqueueSnackbar(t("something went wrong"), {
        variant: "error",
      });
    }

    if (auth.updateSuccess || auth.passwordUpdateSuccess) {
      props.enqueueSnackbar(t("update successful"), {
        variant: "success",
      });
    }
  });

  return !initialValues || !auth.user || auth.loading ? (
    <Fragment>
      <StyledSpacer />
      <Loader />
    </Fragment>
  ) : (
    <Fragment>
      <StyledContainer>
        <StyledFormWrapper fixed>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting, setFieldValue, values, errors }) => (
              <Form id={formId}>
                <ConnectedFocusError />
                <MainForm
                  formField={formField}
                  values={values}
                  setFieldValue={setFieldValue}
                  initialValues={initialValues}
                  errors={errors}
                />
                <div>
                  <StyledButton disabled={isSubmitting} type="submit">
                    {t("save changes")}
                  </StyledButton>
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </StyledFormWrapper>
        <StyledFormWrapper fixed>
          <Formik
            initialValues={passwordFormInitialValues}
            validationSchema={currentPasswordValidationSchema}
            onSubmit={_handlePasswordSubmit}
          >
            {({ isSubmitting, values, validateField, resetForm }) => (
              <Form id={passwordFormId}>
                <PasswordForm
                  formField={passwordFormField}
                  values={values}
                  initialValues={initialValues}
                  validateField={validateField}
                  resetForm={resetForm}
                />
                <div>
                  <StyledButton disabled={isSubmitting} type="submit">
                    {t("save changes")}
                  </StyledButton>
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </StyledFormWrapper>
      </StyledContainer>
    </Fragment>
  );
});

export default withSnackbar(UserProfile);
