import React from "react";
import { format } from "date-fns";
import logoBlack from "../../../assets/img/logoBlack.svg";

import "./styles.css";

const MessageResponse = ({ message }) => {
  const date = new Date();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
        margin: "10px 0",
      }}
    >
      <div
        style={{
          backgroundColor: "#D3F9EF",
          padding: "0px 20px",
          borderRadius: "10px",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: "16px 0",
            position: "relative",
          }}
        >
          <img
            src={logoBlack}
            style={{
              marginBottom: "5px",
              width: "56px",
              height: "17px",
            }}
            alt="Yvoo"
          />
          {message &&
            message.map((line, index) => {
              return (
                <React.Fragment key={index}>
                  <p
                    style={{
                      color: "#20303C",
                      fontSize: "13px",
                      margin: 0,
                      marginBottom: "13px",
                      whiteSpace: "preline",
                      wordBreak: "break-word",
                    }}
                  >
                    {line}
                  </p>
                </React.Fragment>
              );
            })}

          <p
            style={{
              color: "#ABB7B4",
              fontSize: "14px",
              position: "absolute",
              bottom: 0,
              right: 0,
              margin: 0,
              paddingBottom: "4px",
            }}
          >
            {date && format(date, "HH:mm")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MessageResponse;
