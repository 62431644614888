import React, { Fragment } from "react";
import { view } from "@risingstack/react-easy-state";

import { auth } from "stores/auth";

import SearchInput from "./SearchInput";
import Loader from "components/shared/parts/Loader";

import {
  StyledGridFullHeight,
  StyledIntro,
  StyledIntroWrapper,
  StyledSpacer,
} from "./styled";

const Search = view(() => {
  return (
    <Fragment>
      {!auth.user ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <StyledGridFullHeight container justify="center">
                <StyledGridFullHeight item xs={12} md={6}>
                  <SearchInput />
                </StyledGridFullHeight>
              </StyledGridFullHeight>
            </StyledIntroWrapper>
          </StyledIntro>
        </Fragment>
      )}
    </Fragment>
  );
});

export default Search;
