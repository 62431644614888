import styled from "styled-components";

import { Container, Box } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";

export const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

export const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 40px;
  }

  h3 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin: 0;

    span {
      font-weight: 700;
    }
  }

  p {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    margin-bottom: 40px;
  }
`;

export const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  padding-bottom: 40px;
`;

export const StyledMain = styled(Box)`
  position: relative;
  z-index: 2;
`;

export const StyledMainWrapper = styled(Container)`
  position: relative;
  z-index: 2;

  h2 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 24px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 40px;
  }

  h3 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin: 0;

    span {
      font-weight: 700;
    }
  }
`;

export const StyledSectionOuter = styled(Box)`
  border-left: 2px solid #01e7aa;
  padding-left: 11px;
  margin-bottom: 60px;
`;

export const StyledSectionInner = styled(Box)`
  background: transparent;
  padding: 40px;
`;

export const StyledAccordion = styled(Accordion)`
  border-radius: 0 !important;
  margin: 16px 0 !important;
`;
