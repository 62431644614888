import styled from "styled-components";
import Box from "@material-ui/core/Box";

export const StyledSpacer = styled(Box)`
  min-height: calc(100vh - 300px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 36px;
    font-family: "RobotoBlack";
  }
  p {
    font-size: 18px;
  }
  a {
    color: #06d7f9;
  }
`;

export const HeaderWrapper = styled(Box)`
  height: 75px;
  width: 100%;
  padding-left: 32px;
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
`;

export const StyledToolbarButton = styled.div`
  margin-right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 12px;
  }

  span {
    color: #06d7f9;
  }

  &:hover {
    opacity: 0.8;
  }
`;
