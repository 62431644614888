import { store } from "@risingstack/react-easy-state";

import { auth } from "./auth";
import { company } from "./company";
import { selfAssessment } from "./selfAssessment";

import { API_ENDPOINT } from "../consts/globals";

import { authFetch } from "functions";

const abortController = new AbortController();
const signal = abortController.signal;

export const lean = store({
  lean: null,
  loading: false,
  async getLean() {
    lean.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    if (company.company) {
      try {
        const response = await authFetch(
          `${API_ENDPOINT}/system/company-questions/${company.company.id}`,
          {
            signal: signal,
            method: "GET",
            headers: {
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        );

        if (!response.ok) {
          throw Error(response.statusText);
        }

        const responseJson = await response.json();

        if (responseJson.errors) {
          lean.loading = false;
          return;
        } else {
          let convertedValues = Object.fromEntries(
            // convert to array, map, and then fromEntries gives back the object
            Object.entries(responseJson).map(([key, value]) => {
              if (value === null) {
                value = "no answer";
              }
              return [key, value];
            })
          );

          selfAssessment.selfAssessment = null;
          await selfAssessment.getSelfAssessment();
          lean.lean = convertedValues;
          lean.loading = false;
        }
      } catch (error) {
        lean.loading = false;
        console.log(error);
      }
    } else {
      lean.loading = false;
    }
  },
  async addLean(values) {
    lean.loading = true;

    let convertedValues = Object.fromEntries(
      // convert to array, map, and then fromEntries gives back the object
      Object.entries(values).map(([key, value]) => {
        if (value === "no answer") {
          value = null;
        } else {
          value = parseInt(value);
        }
        return [key, value];
      })
    );

    if (!auth.user) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    convertedValues.company_id = company.company.id;

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/company-questions`,
        {
          signal: signal,
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(convertedValues),
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await response.json();

      lean.lean = null;
      await lean.getLean();
    } catch (error) {
      lean.lean = null;
      console.log(error);
    }
  },
  async editLean(values, id) {
    lean.loading = true;

    // let convertedValues = Object.fromEntries(
    //   // convert to array, map, and then fromEntries gives back the object
    //   Object.entries(values).filter(([key, value]) => {
    //     // if (value === "no answer") {
    //     //   value = null;
    //     // }
    //     return value !== "no answer" ? [key, value] : null;
    //   })
    // );

    let convertedValues = Object.fromEntries(
      // convert to array, map, and then fromEntries gives back the object
      Object.entries(values).map(([key, value]) => {
        if (value === "no answer") {
          value = null;
        } else {
          value = parseInt(value);
        }
        return [key, value];
      })
    );

    if (!auth.user) {
      await auth.getUser();
    }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/company-questions/${id}`,
        {
          signal: signal,
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(convertedValues),
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await response.json();

      lean.lean = null;
      await lean.getLean();
    } catch (error) {
      lean.lean = null;
      console.log(error);
    }
  },
});
