import { store } from "@risingstack/react-easy-state";

import { auth } from "./auth";

import { API_ENDPOINT } from "../consts/globals";

import { authFetch } from "functions";

const abortController = new AbortController();
const signal = abortController.signal;

export const longlists = store({
  longlists: null,
  loading: false,
  error: false,
  async addToLonglist(id, name) {
    longlists.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    const values = { seller_id: id, term: name };

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/long-lists/add-to-long-list`,
        {
          signal: signal,
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        longlists.error = true;
        throw Error(response.statusText);
      }

      await response.json();

      longlists.error = false;
      longlists.loading = false;
    } catch (error) {
      longlists.error = true;
      longlists.loading = false;
      console.log(error);
    }
  },
  async removeFromLonglist(id, name) {
    longlists.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    const values = { seller_id: id, term: name };

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/long-lists/remove-from-long-list`,
        {
          signal: signal,
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        longlists.error = true;
        throw Error(response.statusText);
      }

      await response.json();

      longlists.error = false;
      longlists.loading = false;
    } catch (error) {
      longlists.error = true;
      longlists.loading = false;
      console.log(error);
    }
  },
});
