import * as Yup from "yup";
import formModel from "./formModel";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
const {
  formField: {
    name,
    industry,
    company_type,
    parent_company,
    number_of_employees,
    turnover,
    duns_number,
    tax_jurisdiction_code,
    supplier_type,
    communication_language,
    country_id,
    city_id,
    postcode,
    address,
    phone,
    email,
    website,
    short_description,
    iso9001_2015,
    iso14001,
    ohsas18001,
    iso9001_2015ExpiryDate,
    iso14001ExpiryDate,
    ohsas18001ExpiryDate,
    vat_id,
  },
} = formModel;

// const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;

const numberRegEx = /^\d+$/;
const alphanumericRegEx = /^[a-z0-9]+$/i;

export default [
  Yup.object().shape({
    [name.name]: Yup.string().required(`${name.requiredErrorMsg}`),
    [industry.name]: Yup.string()
      .nullable()
      .required(`${industry.requiredErrorMsg}`),
    [company_type.name]: Yup.string().nullable(),
    [parent_company.name]: Yup.string().nullable(),
    [number_of_employees.name]: Yup.string()
      .required(`${number_of_employees.requiredErrorMsg}`)
      .matches(numberRegEx, number_of_employees.invalidErrorMsg),
    [turnover.name]: Yup.string().matches(
      numberRegEx,
      turnover.invalidErrorMsg
    ),
    [duns_number.name]: Yup.string()
      .matches(numberRegEx, duns_number.invalidErrorMsg)
      .test(
        "len",
        `${duns_number.invalidErrorMsg}`,
        (val) => !val || val.length === 9
      )
      .nullable(),
    [tax_jurisdiction_code.name]: Yup.string()
      .test(
        "len",
        `${tax_jurisdiction_code.invalidErrorMsg}`,
        (val) => !val || val.length <= 15
      )
      .nullable(),
    [supplier_type.name]: Yup.string().nullable(),
    [communication_language.name]: Yup.string()
      .nullable()
      .required(`${communication_language.requiredErrorMsg}`),
    [country_id.name]: Yup.string()
      .nullable()
      .required(`${country_id.requiredErrorMsg}`),
    [city_id.name]: Yup.string()
      .nullable()
      .required(`${city_id.requiredErrorMsg}`),
    [postcode.name]: Yup.string()
      .required(`${postcode.requiredErrorMsg}`)
      .matches(alphanumericRegEx, postcode.invalidErrorMsg)
      .test(
        "len",
        `${postcode.invalidErrorMsg}`,
        (val) => val && val.length <= 10
      ),
    [address.name]: Yup.string().required(`${address.requiredErrorMsg}`),
    [phone.name]: Yup.string()
      .required(`${phone.requiredErrorMsg}`)
      .test("validator-isPossible", function (value) {
        if (typeof value === "undefined") {
          return;
        }
        const validation = isPossiblePhoneNumber(value.toString());
        if (!validation) {
          return this.createError({
            path: this.path,
            message: phone.invalidErrorMsg,
          });
        } else {
          return true;
        }
      })
      .test("validator-isValid", function (value) {
        if (typeof value === "undefined") {
          return;
        }
        const validation = isValidPhoneNumber(value.toString());
        if (!validation) {
          return this.createError({
            path: this.path,
            message: phone.invalidErrorMsg,
          });
        } else {
          return true;
        }
      }),
    [email.name]: Yup.string()
      .email(email.invalidErrorMsg)
      .required(`${email.requiredErrorMsg}`),
    [website.name]: Yup.string().nullable().url(website.invalidErrorMsg),
    [short_description.name]: Yup.string().required(
      `${short_description.requiredErrorMsg}`
    ),
    [vat_id.name]: Yup.string().nullable(),
    [iso9001_2015.name]: Yup.string().nullable(),
    [iso14001.name]: Yup.string().nullable(),
    [ohsas18001.name]: Yup.string().nullable(),
    [iso9001_2015ExpiryDate.name]: Yup.string().nullable(),
    [iso14001ExpiryDate.name]: Yup.string().nullable(),
    [ohsas18001ExpiryDate.name]: Yup.string().nullable(),
  }),
  // Yup.object().shape({
  //   [nameOnCard.name]: Yup.string().required(`${nameOnCard.requiredErrorMsg}`),
  //   [cardNumber.name]: Yup.string()
  //     .required(`${cardNumber.requiredErrorMsg}`)
  //     .matches(visaRegEx, cardNumber.invalidErrorMsg),
  //   [expiryDate.name]: Yup.string()
  //     .nullable()
  //     .required(`${expiryDate.requiredErrorMsg}`)
  //     .test('expDate', expiryDate.invalidErrorMsg, val => {
  //       if (val) {
  //         const startDate = new Date();
  //         const endDate = new Date(2050, 12, 31);
  //         if (moment(val, moment.ISO_8601).isValid()) {
  //           return moment(val).isBetween(startDate, endDate);
  //         }
  //         return false;
  //       }
  //       return false;
  //     }),
  //   [cvv.name]: Yup.string()
  //     .required(`${cvv.requiredErrorMsg}`)
  //     .test('len', `${cvv.invalidErrorMsg}`, val => val && val.length === 3)
  // })
];
