import styled from "styled-components";

import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import drawerButtonLeft from "assets/img/drawerButtonLeft.svg";
import drawerButtonRight from "assets/img/drawerButtonRight.svg";

const drawerWidth = 320;

export const StyledSpacer = styled.div`
  min-height: 100vh;
  width: 100%;
`;

export const StyledAppbar = styled(AppBar)`
  background-color: #000000;
  min-height: 75px;
  box-shadow: none;
  border-bottom: 1px solid #383838;
`;

export const StyledToolbar = styled(Toolbar)`
  height: 100%;
  min-height: 75px;

  .MuiIconButton-root {
    background: url(${drawerButtonRight});
    width: 78px;
    height: 75px;
    border-radius: 0;
  }

  .MuiIconButton-edgeStart {
    margin-left: 0;
  }
`;

export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    color: #000000;
    background-color: #ffffff;
    text-transform: uppercase;
  }

  .MuiDrawer-paperAnchorDockedLeft {
    // border-right: 1px solid #dddddd;
    border: none;
  }

  .MuiIconButton-root {
    background: url(${drawerButtonLeft});
    width: 78px;
    height: 75px;
    border-radius: 0;
    color: #ffffff;
  }
`;

export const StyledList = styled(List)`
  padding-bottom: 43px;
  padding-top: 43px;
  border-right: 1px solid #dddddd;
  height: 100%;

  a,
  .MuiButtonBase-root {
    padding: 0 56px;

    .MuiTypography-root {
      padding: 16px 0;
      font-size: 14px;
      line-height: 17px;
      font-family: "RobotoMedium", "Arial", sans-serif;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      border-bottom: 1px solid #dddddd;
    }

    #borderless {
      .MuiTypography-root {
        border-bottom: none;
      }
    }
  }

  .MuiListItem-button {
    &:hover {
      text-decoration: none;
      background-color: unset;
      color: #06d7f9;
    }
  }

  [type="active"] {
    span {
      color: #06d7f9;
    }
  }

  svg {
    position: absolute;
    right: 35px;
  }
`;

// export const StyledNestedList = styled(List)`
//   border-right: 1px solid #dddddd;
//   height: 100%;

//   a,
//   .MuiButtonBase-root {
//     padding: 0 56px;

//     .MuiTypography-root {
//       padding: 16px 0;
//       font-size: 14px;
//       line-height: 17px;
//       font-family: "RobotoMedium", "Arial", sans-serif;
//       display: flex;
//       align-items: center;
//       text-transform: uppercase;
//       border-bottom: 1px solid #dddddd;
//     }

//     #borderless {
//       .MuiTypography-root {
//         border-bottom: none;
//       }
//     }
//   }

//   .MuiListItem-button {
//     &:hover {
//       text-decoration: none;
//       background-color: unset;
//       color: #06d7f9;
//     }
//   }

//   [type="active"] {
//     span {
//       color: #06d7f9;
//     }
//   }
// `;

export const StyledToolbarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledToolbarButton = styled.div`
  margin-right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 12px;
  }

  span {
    color: #06d7f9;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const StyledHelpDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    color: #000000;
    background-color: #ffffff;
    text-transform: uppercase;
    // height: 1032px;
    z-index: 99999;
    width: 100%;
    max-width: ${({ expand }) => (expand ? "100vw" : `${drawerWidth}px`)};
  }

  .MuiDrawer-paperAnchorDockedLeft {
    // border-right: 1px solid #dddddd;
    border: none;
  }

  .MuiIconButton-root {
    background: url(${drawerButtonLeft});
    width: 78px;
    height: 75px;
    border-radius: 0;
    color: #ffffff;
  }
`;

export const StyledHelpBorder = styled(Box)`
  position: fixed;
  top: 0;
  right: ${(props) => (props.open ? `${drawerWidth}px` : 0)};
  height: 100%;
  width: 18px;
  background: #06d7f9;
  z-index: 9999;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  display: flex;
  align-items: center;
  z-index: 99999;
`;

export const StyledHelpButtons = styled(Box)`
  width: 52px;
  border: 3px solid #06d7f9;
  border-radius: 13px;
  background-color: #ffffff;
  position: absolute;
  right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: 18px;
    width: 26px;
  }
`;

export const StyledTopButton = styled(IconButton)`
  width: 100%;
  height: 100%;
  border-radius: 13px 13px 0 0;
  background-color: #ffffff;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #ffffff;
    opacity: 0.8;
  }

  &.active {
    border-radius: 6px 6px 0 0;
    background-color: #06d7f9;

    &:hover {
      background-color: #06d7f9;
    }
  }
`;

export const StyledMiddleButton = styled(IconButton)`
  width: 100%;
  height: 100%;
  border-radius: 0 0 0 0;
  background-color: #ffffff;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #ffffff;
    opacity: 0.8;
  }

  &.active {
    border-radius: 0 0 0 0;
    background-color: #06d7f9;

    &:hover {
      background-color: #06d7f9;
    }
  }
`;

export const StyledBottomButton = styled(IconButton)`
  width: 100%;
  height: 100%;
  border-radius: 0 0 13px 13px;
  background-color: #ffffff;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }

  &.active {
    border-radius: 0 0 6px 6px;
    background-color: #06d7f9;

    &:hover {
      background: #06d7f9;
    }
  }
`;

export const StyledExpandButton = styled(Box)`
  position: absolute;
  top: 18px;
  left: 18px;
  cursor: pointer;
  transform: ${({ expand }) => (expand ? "rotate(90deg)" : "rotate(270deg)")};
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;

  img  {
    width: 22px;
    height: 13px;
    transform: scale(1.2);
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const StyledCloseButton = styled(Box)`
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const StyledSidebarWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  max-width: 800px;
  margin: 0 auto;

  h3 {
    font-family: "NeueMachinaRegular", "Arial", sans-serif;
    font-size: 21px;
    line-height: 20px;
    text-transform: uppercase;
    color: #000000;
    margin: 0;
    margin-bottom: 30px;
  }

  p {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0;
    margin-bottom: 40px;
    text-transform: none;
  }
`;

export const StyledSidebarWrapperChat = styled(Box)`
  height: 100%;
  width: 100%;
  padding-top: 72px;
  h3 {
    font-family: "NeueMachinaRegular", "Arial", sans-serif;
    font-size: 21px;
    line-height: 20px;
    text-transform: uppercase;
    color: #000000;
    margin: 0;
    margin-bottom: 30px;
  }

  p {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0;
    margin-bottom: 40px;
    text-transform: none;
  }
`;

export const StyledFilterWrapper = styled(Box)`
  padding: 100px 30px;

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .MuiAccordion-root::before {
    display: none;
  }

  .MuiIconButton-root {
    background: unset;
    height: unset;
    width: unset;
    color: unset;
  }

  .MuiCheckbox-root {
    color: #c4c4c4;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #06d7f9;
  }

  .MuiFormControlLabel-label {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 12px;
    color: #000000;
  }

  h3 {
    font-family: "NeueMachinaRegular", "Arial", sans-serif;
    font-size: 21px;
    line-height: 20px;
    text-transform: uppercase;
    color: #000000;
    margin: 0;
    margin-bottom: 30px;
  }
`;

export const StyledButtonPrimary = styled.button`
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #06d7f9;
  height: 45px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #06d7f9;

  &:hover {
    opacity: 0.8;
  }

  svg {
    margin-right: 8px;
  }
`;

export const StyledMainWrapper = styled(Box)`
  padding: ${(props) => (props.pathname === "/" ? "24px 0" : "24px")};
  height: 100%;
  background-color: ${(props) =>
    props.pathname === "/search" ? "#000000" : "transparent"};
`;

export const StyledTopBar = styled(Box)`
  width: 100%;
  background: black;
  // height: ${(props) => (props.openTopBar ? "250px" : 0)};
  min-height: ${(props) =>
    props.openTopBar && props.matches
      ? "200px"
      : props.openTopBar && !props.matches
      ? "340px"
      : 0};
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  padding-top: ${(props) => (props.openTopBar ? "35px" : 0)};
`;

export const StyledTopBarFooter = styled(Box)`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid #474747;
  cursor: pointer;
  background-color: #474747;

  img {
    transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transform: ${(props) =>
      props.openTopBar ? "rotate(0deg)" : "rotate(180deg)"};
  }
`;

export const StyledRatingWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 50px;
  margin-bottom: 35px;

  h3 {
    font-family: "NeueMachinaUltraBold";
    font-size: 28px;
    line-height: 17px;
    color: #ffffff;

    span {
      font-size: 14px;
    }
  }
`;

export const StyledRatingInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h4 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 17px;
    color: #ffffff;
    margin: 0;
    // margin-bottom: 24px;
  }

  p {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 12px;
    line-height: 17px;
    color: #c7c7c7;
    margin: 0;
  }
`;

export const StyledAccordion = styled(Accordion)`
  border-bottom: 1px solid #c4c4c4;
  border-radius: 0 !important;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0;

  h2 {
    font-family: "NeueMachinaRegular";
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    color: #000000;
    margin: 0;
  }
`;
export const StyledAccrdionDetails = styled(AccordionDetails)`
  padding: 0;
  margin: 0;
  margin-bottom: 35px;

  p {
    margin: 0;
  }
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    // padding: theme.spacing(0, 1),
    padding: 0,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    minHeight: "75px !important",
  },
  toolbarClosed: {
    paddingLeft: 0,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    padding: 0,
    marginTop: "75px",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export const useStylesRating = makeStyles((props) => ({
  root: {
    position: "relative",
    marginRight: "26px",
  },
  bottom: {
    color: "#444444",
  },
  top: {
    animationDuration: "550ms",
    position: "absolute",
    left: -4,
    top: -4,
  },
  red: { color: "#FF490F" },
  orange: { color: "#FF7B51" },
  yellow: { color: "#FFC527" },
  blue: { color: "#06D7F9" },
  green: { color: "#01E7AA" },
  circle: {
    // strokeLinecap: "round",
  },
}));
