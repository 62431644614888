import { store } from "@risingstack/react-easy-state";

import { auth } from "./auth";

import { API_ENDPOINT, API_MSG_ENDPOINT } from "../consts/globals";

import { authFetch } from "functions";

const abortController = new AbortController();
const signal = abortController.signal;

export const favorites = store({
  favorites: null,
  aiFavorites: null,
  loading: false,
  error: false,
  userFavorites: null,
  async addFavorite(id) {
    favorites.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    const values = { company_id: id };

    try {
      const response = await authFetch(`${API_ENDPOINT}/system/favorite`, {
        signal: signal,
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        favorites.error = true;
        throw Error(response.statusText);
      }

      await response.json();

      favorites.error = false;
      favorites.loading = false;
    } catch (error) {
      favorites.error = true;
      favorites.loading = false;
      console.log(error);
    }
  },
  async removeFavorite(id) {
    favorites.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/favorite/${id}`,
        {
          signal: signal,
          method: "DELETE",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        favorites.error = true;
        throw Error(response.statusText);
      }

      await response.json();

      favorites.error = false;
      favorites.loading = false;
    } catch (error) {
      favorites.error = true;
      favorites.loading = false;
      console.log(error);
    }
  },
  async getUserFavorites() {
    favorites.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/favorite?include=company`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      favorites.userFavorites = responseJson.data;
      favorites.loading = false;
    } catch (error) {
      favorites.loading = false;
      console.log(error);
    }
  },
  async getUserAIFavorites(token, userEmail) {
    favorites.loading = true;

    if (!auth.user) {
      await auth.getUser();
    }

    try {
      const response = await authFetch(
        `${API_MSG_ENDPOINT}/api/messages/${userEmail}`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }
      const responseJson = await response.json();

      favorites.aiFavorites = responseJson.Items;
      favorites.loading = false;
    } catch (error) {
      favorites.loading = false;
      console.log(error);
    }
  },
});
